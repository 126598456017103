/**
 * Created by erikccoder on 17/4/2018.
 */
import React from 'react';
import PropTypes from 'prop-types';


import { Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux'

import {
	Icon,
	Button,
	Dropdown,
	Input,
	Divider,
	Grid
} from 'semantic-ui-react'


import API from '../../api'

const ButlerToolbar = (props) => {

	return (
		<Grid padded verticalAlign="middle" className="bg-white border-bottom sticky-top">
			<Grid.Column width={3}>
				<Button
					size="tiny"
					color="orange"
					className="bg-red"
					as="a"
				    href="/butlers/new-butler-from"
				>
					NEW BUTLER
				</Button >
			</Grid.Column>
			<Grid.Column width={13} textAlign="right">
				<Input
					className="mx-1"
					label={<Button inverted className="bg-red" icon="search" name="search" />}
					labelPosition='right'
					onChange={ (e,d) => props.setSearchWords(d.value)}
					placeholder='Search butler'
				/>
				<Button
					size="tiny"
					className="mx-1 bg-red"
					color="orange"
				>
					EXPORT
				</Button>
			</Grid.Column>

		</Grid>
	)
};

ButlerToolbar.propTypes = {
	setSearchWords: PropTypes.func.isRequired,
}


export default ButlerToolbar;
