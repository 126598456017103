/**
 * Created by erikccoder on 17/5/2018.
 */

import API from "../../api";
import React from "react";
import _ from "lodash";
import moment from "moment";
import DateRangePicer from "../../components/DateRangePicer";
import { parseSearch } from "../../helper";
import { Button, Dropdown } from "semantic-ui-react";
import SimpleTable from "../../components/SimpleTable";

const ObjectsToCsv = require("objects-to-csv");

const STATUS_OPTIONS = [
  {
    text: "ALL",
    value: "ALL",
    key: "ALL"
  },
  {
    text: "APPROVED",
    value: "APPROVED",
    key: "APPROVED"
  },
  {
    text: "DOING",
    value: "DOING",
    key: "DOING"
  },
  {
    text: "FINISHED",
    value: "FINISHED",
    key: "FINISHED"
  },
  {
    text: "CANCELED",
    value: "CANCELED",
    key: "CANCELED"
  },
  {
    text: "PENDING",
    value: "PENDING",
    key: "PENDING"
  },
  {
    text: "HOLD",
    value: "HOLD",
    key: "HOLD"
  },
  {
    text: "APPROVED + PENDING",
    value: "APPROVED,PENDING",
    key: "APPROVED,PENDING"
  },
  {
    text: "APPROVED + DOING",
    value: "APPROVED,DOING",
    key: "APPROVED,DOING"
  },
  {
    text: "HOLD + APPROVED + PENDING",
    value: "HOLD,APPROVED,PENDING",
    key: "HOLD,APPROVED,PENDING"
  }
];

class SchedulerList extends React.Component {
  state = {
    orders: [],
    firstLoad: false,
    error: null,
    sortDirection: null,
    sortColumn: null,
    status: "ALL"
  };

  headerOnClickForSort({ text, key }) {
    const { sortDirection, sortColumn } = this.state;

    if (sortColumn == key) {
      this.setState({
        sortColumn: key,
        sortDirection:
          sortDirection === "ascending" ? "descending" : "ascending"
      });
    } else {
      this.setState({
        sortColumn: key,
        sortDirection: "ascending"
      });
    }
  }

  componentWillMount() {
    const search = _.chain(this.props.location.search);

    const { start, end, status } = parseSearch(search);

    if (status) {
      this.setState({
        status: decodeURIComponent(status)
      });
    } else {
      this.setState({
        status: "ALL"
      });
    }

    // TODO: Add status to query
    API.get("/orders", {
      params: { start, end, status: status === "ALL" ? "" : status }
    })
      .then(r => {
        console.log("data", r.data);
        return r.data;
      })
      .then(orders => this.setState({ orders, firstLoad: true }))
      .catch(error => this.setState({ firstLoad: true, error: error }));
  }

  urlQuery = new URLSearchParams(this.props.location.search);

  handleDateRange = ({ startDate, endDate }) => {
    if (!startDate || !endDate) {
      return;
    }
    this.urlQuery.set("start", startDate.format("YYYY-MM-DD"));
    this.urlQuery.set("end", endDate.format("YYYY-MM-DD"));
    window.location = `/calendar?${this.urlQuery.toString()}`;
  };

  handleStatusChange = (_, data) => {
    this.setState({ status: data.value });
    this.urlQuery.set("status", data.value);
    window.location = `/calendar?${this.urlQuery.toString()}`;
  };

  export = () => {
    var data = [];
    this.state.orders.map(function(order) {
      const id = _.get(order, "id", "N/A");
      const startTimeTS = _.get(order, "start_timestamp", "N/A");
      const endTimeTS = _.get(order, "end_timestamp", "N/A");
      const startTime = moment(startTimeTS).format("DD/MM/YYYY h:mm a");
      const clientName = _.get(order, "client.user.full_name", "N/A");
      const membership = _.get(order, "client.membership.type", "N/A");
      const hours = moment(endTimeTS).diff(moment(startTimeTS), "hours");
      const butlerName = _.get(order, "butler.user.full_name", "N/A");
      // const restTime = _.get(order, "butler_id", "N/A");
      const tasks = _.get(order, "tasks", "N/A");

      const service = tasks.map(function(task) {
        return task.type;
      });

      let wod = _.get(order, "start_timestamp", "N/A");
      let wod_sort = 0;
      if (wod != "N/A") {
        wod_sort = moment(wod).weekday();
        wod = moment(wod).format("dddd");
      }

      const startTime_sort = moment(wod);
      const object = {
        startTime: startTime,
        membership: membership,
        wod: wod,
        clientName: clientName,
        hours: hours,
        butlerName: butlerName,
        cleaning: service.includes("CLEANING") ? "Y" : "",
        cooking: service.includes("COOKING") ? "Y" : "",
        laundry: service.includes("LAUNDRY") ? "Y" : "",
        grocery: service.includes("GROCERY") ? "Y" : "",
        pet_sitting: service.includes("PET_SITTING") ? "Y" : "",
        status: order.status
      };
      data.push(object);
    });
    var csvFormat;
    (async () => {
      let csv = new ObjectsToCsv(data);
      csv = await csv.toString();
      if (!csv.match(/^data:text\/csv/i)) {
        csv = "data:text/csv;charset=utf-8," + csv;
      }
      data = encodeURI(csv);
      var link;
      link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "test.csv");
      link.click();
    })();
  };
  renderTable() {
    const headers = [
      {
        text: "Date & Time",
        key: "startTime"
      },
      {
        text: "Weekday",
        key: "wod"
      },
      {
        text: "Name",
        key: "clientName"
      },
      {
        text: "Membership",
        key: "membership"
      },
      {
        text: "Is First Order",
        key: "firstOrder"
      },
      {
        text: "Hours",
        key: "hours"
      },
      {
        text: "Butler",
        key: "butlerName"
      },
      {
        text: "Cleaning",
        key: "cleaning"
      },
      {
        text: "Cooking",
        key: "cooking"
      },
      {
        text: "Laundry",
        key: "laundry"
      },
      {
        text: "Grocery",
        key: "grocery"
      },
      {
        text: "Status",
        key: "status"
      }
    ];

    const { sortDirection, sortColumn } = this.state;

    let body = this.state.orders.map(function(order) {
      const id = _.get(order, "id", "N/A");
      const startTimeTS = _.get(order, "start_timestamp", "N/A");
      const endTimeTS = _.get(order, "end_timestamp", "N/A");
      const startTime = moment(startTimeTS).format("DD/MM/YYYY h:mm a");
      const clientName = _.get(order, "client.user.full_name", "N/A");
      const membership = _.get(order, "client.membership.type", "N/A");
      const hours = moment(endTimeTS).diff(moment(startTimeTS), "hours");
      const butlerName = _.get(order, "butler.user.full_name", "N/A");
      // const restTime = _.get(order, "butler_id", "N/A");
      const tasks = _.get(order, "tasks", "N/A");

      const service = tasks.map(function(task) {
        return task.type;
      });

      let wod = _.get(order, "start_timestamp", "N/A");
      let wod_sort = 0;
      if (wod != "N/A") {
        wod_sort = moment(wod).weekday();
        wod = moment(wod).format("dddd");
      }

      const startTime_sort = moment(wod);
      const numberOfOrders = _.get(order, "allOrders", []).length;

      return {
        id,
        startTime,
        membership,
        wod,
        wod_sort,
        clientName,
        hours,
        butlerName,
        cleaning: service.includes("CLEANING") ? "✓" : "",
        cooking: service.includes("COOKING") ? "✓" : "",
        laundry: service.includes("LAUNDRY") ? "✓" : "",
        grocery: service.includes("GROCERY") ? "✓" : "",
        pet_sitting: service.includes("PET_SITTING") ? "✓" : "",
        status: order.status,
        firstOrder: numberOfOrders === 1 ? "✓" : ""
      };
    });

    let sortColMap = null;
    switch (sortColumn) {
      case "startTime":
        sortColMap = "startTime_sort";
        break;
      case "wod":
        sortColMap = "wod_sort";
        break;
    }

    body = _.sortBy(body, [sortColMap || sortColumn]);
    if (sortDirection == "descending") {
      body = body.reverse();
    }

    return (
      <div className="mx-2 my-3">
        <SimpleTable
          theader={headers}
          tbody={body}
          baseURL="/calendar/:key"
          key_link_to="id"
          direction={sortDirection}
          column={sortColumn}
          headerOnClickForSort={this.headerOnClickForSort.bind(this)}
        />
      </div>
    );
  }

  render() {
    const { orders, firstLoad, error } = this.state;

    if (!firstLoad) {
      return (
        <div className="w-100 h-100 p-5 d-inline-block">
          <h1 className="text-center align-middle">Loading...</h1>
        </div>
      );
    } else if (error) {
      return <pre>{JSON.stringify(error, null, "\t")}</pre>;
    }

    return (
      <div className="">
        <div className="navbar justify-content-start align-items-center sticky-top shadow-sm bg-white">
          <h1 className="my-0">Orders</h1>
          <a href="/calendar/new" className="mx-3 btn btn-danger">
            NEW ORDER
          </a>
          <a href="/calendar/multiNew" className="mx-3 btn btn-danger">
            MULTIPLE NEW ORDER
          </a>
          <Button onClick={this.export} color="#ED0036">
            EXPORT
          </Button>
        </div>

        <div className="mx-2 my-5">
          <DateRangePicer onChange={this.handleDateRange} />
          <div className="d-flex align-items-center mt-3">
            <h6 className="m-0 mr-2">Status</h6>
            <Dropdown
              deburr
              fluid
              options={STATUS_OPTIONS.map(v => v)}
              value={this.state.status}
              selection
              onChange={this.handleStatusChange}
              style={{ minWidth: 120, width: "auto" }}
            />
          </div>
        </div>

        {this.renderTable()}
      </div>
    );
  }
}

export default SchedulerList;
