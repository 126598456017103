/**
 * Created by erikccoder on 31/5/2018.
 */
import React from "react";
import moment from "moment";
import _ from "lodash";
import Select from "react-select";
import SimpleTable from "../../components/SimpleTable";
import API from "../../api";
import { getLoadingOrFail, parseSearch } from "../../helper";
import { Pagination, Button } from "semantic-ui-react";

class ClientDeposit extends React.Component {
  state = {
    firstLoad: false,
    error: null
  };

  componentWillMount() {
    const { client_id } = this.props.match.params;

    const search = parseSearch(this.props.location.search);
    const { page } = search;

    API.get(`/clients/${client_id}/deposit`, { params: { page } })
      .then(r => r.data)
      .then(deposit => this.setState({ deposit, firstLoad: true }))
      .catch(error => this.setState({ error, firstLoad: true }));
  }
  handlePaginationChange = (e, { activePage }) => {
    // console.log(activePage, this.props.location);
    window.location = this.props.location.pathname + "?page=" + activePage;
  };
  handleSubmit = () => {};

  render() {
    const fail = getLoadingOrFail(this.state);
    if (fail) {
      return fail;
    }

    const headers = [
      {
        text: "ID #",
        key: "id"
      },
      {
        text: "amount",
        key: "amount"
      },
      {
        text: "Date",
        key: "created_at"
      },
      {
        text: "Remark",
        key: "remark"
      },
      {
        text: "Balance",
        key: "balance"
      }
    ];

    // let orders = this.state.orders.map( o => ({
    // 	...o,
    // 	date_and_time: (
    // 		moment(_.get(o,'order.start_timestamp')).format("DD/MM/YYYY HH:mm")
    // 		+ ' - '
    // 		+ moment(_.get(o,'order.end_timestamp')).format("HH:mm")
    // 	)
    // }));

    // orders = _.sortBy(orders, ["id"])
    const { client_id } = this.props.match.params;

    return (
      <div>
        <div className="bg-white border-bottom p-3 row">
          <Button
            size="tiny"
            href={`/clients/${client_id}`}
            color="orange"
            className="bg-red"
          >
            GO BACK
          </Button>
        </div>

        <h1 className="mx-2">Deposit Records</h1>

        <SimpleTable
          className="mx-2"
          theader={headers}
          tbody={this.state.deposit.data}
        />

        <Pagination
          className="mx-2 mt-5"
          defaultActivePage={this.state.deposit.page}
          totalPages={this.state.deposit.lastPage}
          onPageChange={this.handlePaginationChange}
        />
        {/*<pre>{JSON.stringify(this.state.deposit.data, null, '\t')}</pre>*/}
      </div>
    );
  }
}

export default ClientDeposit;
