import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import SimpleTable from "../../components/SimpleTable";
import { Button } from "semantic-ui-react";
import API from "../../api";

const headers = [
  {
    text: "Date",
    key: "ratings.created_at"
  },
  {
    text: "Order ID",
    key: "id"
  },
  {
    text: "Ratings",
    key: "ratings.ratings"
  },
  {
    text: "Badges",
    key: "badges"
  },
  {
    text: "Clients Name",
    key: "client.user.full_name"
  },
  {
    text: "Comments",
    key: "ratings.comments"
  }
];

class ButlerComments extends React.Component {
  state = {
    firstLoad: false,
    error: null,
    summary: null,
    searchKWord: "",
    sortDirection: null,
    sortColumn: null,
    butlersInfo: []
  };

  componentWillMount() {
    const { butler_id } = this.props.match.params;
    API.get(`/butler_ratings/${butler_id}`)
      .then(r => {
        this.setState({ butlersInfo: r.data });
      })
      .then(firstLoad => this.setState({ firstLoad: true }))
      .catch(error => this.setState({ firstLoad: true, error: error }));
  }

  headerOnClickForSort({ text, key }) {
    const { sortDirection, sortColumn } = this.state;
    if (sortColumn == key) {
      this.setState({
        sortColumn: key,
        sortDirection:
          sortDirection === "ascending" ? "descending" : "ascending"
      });
    } else {
      this.setState({
        sortColumn: key,
        sortDirection: "ascending"
      });
    }
  }

  render() {
    let {
      sortDirection,
      sortColumn,
      butlersInfo,
      firstLoad,
      error
    } = this.state;

    if (!firstLoad) {
      return (
        <div className="w-100 h-100 p-5 d-inline-block">
          <h1 className="text-center align-middle">Loading...</h1>
        </div>
      );
    } else if (error) {
      return <pre>{JSON.stringify(error, null, "\t")}</pre>;
    }

    const { butler_id } = this.props.match.params;
    butlersInfo = butlersInfo.map(butler => {
      const x = butler.badges.map(badge => {
        return (
          <p>
            {badge.name} : {badge.count}
          </p>
        );
      });
      butler.badges = x;
      return butler;
    });
    butlersInfo = _.sortBy(butlersInfo, [sortColumn]);
    if (sortDirection == "descending") {
      butlersInfo = butlersInfo.reverse();
    }

    return (
      <div className="container-fluid k-table py-3">
        <div className="bg-white border-bottom p-3 row">
          <Button
            as={Link}
            size="tiny"
            to="/butler_ratings"
            color="orange"
            className="bg-red"
          >
            GO BACK
          </Button>
        </div>
        {/* <h1>Butler: </h1> */}
        <SimpleTable
          className="mx-2"
          theader={headers}
          tbody={butlersInfo}
          //   baseURL="/butlers_ratings/:key"
          //   key_link_to="id"
          direction={sortDirection}
          column={sortColumn}
          headerOnClickForSort={this.headerOnClickForSort.bind(this)}
        />
      </div>
    );
  }
}

export default ButlerComments;
