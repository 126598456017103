import React from "react";
import { connect } from "react-redux";
import API from "../../api";

import { Button, Form, Checkbox, Message } from "semantic-ui-react";
import { Redirect } from "react-router";

import "./login.css";

class LoginForm extends React.Component
{
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      doSave: true,
      loading: false,
      warning: null
    };

    props.logout();

    this.doSignin = this.doSignin.bind(this);

  }

  doSignin() {
    const { email, password } = this.state;
    this.setState({ loading: true });

    API.post("/login", {
      email,
      password
    })
        .then(({ data }) => {

          const {user_info} = data;
          const { token, refreshToken } = data.token;
          this.setState({ loading: false, warning: false });

          if(user_info.admin_id)
          {
            this.props.setUser(token, refreshToken);
          }
          else{
            alert('You are not admin!');
            return (window.location = '/login');
          }

        })
        .catch(e => this.setState({ loading: false, warning: true }));
  }

  render() {
    const { loading, warning } = this.state;

    const { user } = this.props;

    return (
      <div className="d-flex align-items-center justify-content-center text-center h-100">
        {user.token && user.refreshToken ? <Redirect to="/clients" /> : null}

        <div className="login-form py-5">
          <img
            className="mb-5"
            src="http://dummyimage.com/215x102.jpg/5fa2dd/ffffff&text=Butler"
            alt="Butler"
          />
          <Form className="mx-auto">
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="E-mail address"
              onChange={(e, { value }) => this.setState({ email: value })}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              onChange={(e, { value }) => this.setState({ password: value })}
            />

            {warning ? (
              <Message negative>Seems the email & password are wrong.</Message>
            ) : null}

            <Button
              onClick={this.doSignin}
              type="submit"
              disabled={loading}
              className="bg-red"
              fluid
              size="medium"
              inverted
              loading={loading}
            >
              SIGN IN
            </Button>

            <div className="text-left mt-2">
              <Checkbox
                label="Remember me"
                inverted
                defaultChecked
                onChange={(e, { checked }) =>
                  this.setState({ doSave: checked })
                }
              />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const LoginFormConnected = connect(
  ({ user }) => ({ user }),
  dispatch => ({
    setUser: (token, refreshToken) => {
      dispatch({ type: "SET_USER", token, refreshToken });
    },
    logout: () => {
      dispatch({ type: "REMOVE_USER_TOKENS"});
    }
  })
)(LoginForm);

export default LoginFormConnected;
