import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash'

import {Table, Icon} from 'semantic-ui-react'
import { Link } from 'react-router-dom'

class MoreSimpleTable extends React.Component {

	constructor(props){
		super(props);
	}

	getCellContent = (cell, key)=>
	{
		const value = _.get(cell, key, null);
		switch (key){

			case "cleaning":
			case "cooking":
			case "laundry":
			case "gorcery":
			case "pet_sitting":
			case "Other":
				return (
					value
					? (<Icon name="checkmark" />)
					: ''
				)

			default:
				return value;
		}
	}

	render(){

		// const theader = _.get(this.props, "theader", []);
		// const tbody = _.get(this.props, "tbody", []);

		// const theader = [];
		// const tbody = [];

		const {
			theader,
			tbody,
			className,
			baseURL,
			key_link_to
		} = this.props;


		return (
			<Table
				collapsing
				className={"more-simple "+className}
				style={{width: '100%'}}
			>
				<Table.Header>
					<Table.Row>
						{
							theader.map( ({text}, i) => (
								<Table.HeaderCell
									key={i}
								>
									{text}
								</Table.HeaderCell>
							) )
						}
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{
						tbody.map( (c, y) => (
							<Table.Row key={`row-${y}`} selectable>
								{
									theader.map( ({key}, i) => (
										<Table.Cell key={y+'_'+i}>
											{
												this.getCellContent(c, key)
											}
										</Table.Cell>
									))
								}
							</Table.Row>
						))
					}
				</Table.Body>
			</Table>
		)
	}
};

MoreSimpleTable.defaultProps = {
	theader: [],
	tbody: [],
	className: ""
};

MoreSimpleTable.propTypes = {
	theader: PropTypes.array.isRequired,
	tbody: PropTypes.array.isRequired
}

export default MoreSimpleTable;
