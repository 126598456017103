/**
 * Created by erikccoder on 31/5/2018.
 */

import React from "react";
import moment from "moment";
import _ from "lodash";
import Select from "react-select";
import SimpleTable from "../../components/SimpleTable";
import API from "../../api";
import { getLoadingOrFail, parseSearch } from "../../helper";
import { Pagination, Dropdown } from "semantic-ui-react";
const OPTIONS = [
  {
    key: "APPROVED",
    text: "APPROVED",
    value: "APPROVED"
  },
  {
    key: "PENDING",
    text: "PENDING",
    value: "PENDING"
  },
  {
    key: "REJECTED",
    text: "REJECTED",
    value: "REJECTED"
  }
];
class OrderOutstandingFee extends React.Component {
  constructor() {
    super();
    this.state = {
      firstLoad: false,
      error: null,
      sortDirection: null,
      sortColumn: null,
      status: "ALL",
      activePage: "1"
    };
  }

  componentWillMount() {
    // start, end
    const search = parseSearch(this.props.location.search);
    const { page, status } = search;

    API.get("/orders-outstanding-fee", { params: { page, status } })
      // API.get('/orders', {params: {start, end}})
      .then(r => r.data)
      .then(orders => this.setState({ orders, firstLoad: true }))
      .catch(error => this.setState({ error, firstLoad: true }));
  }
  handlePaginationChange = (e, { activePage }) => {
    const search = parseSearch(this.props.location.search);
    const { status } = search;
    // this.setState({ activePage: activePage });
    window.location =
      this.props.location.pathname +
      "?page=" +
      activePage +
      "&status=" +
      status;
  };
  handleDropdownChange = (e, data) => {
    this.setState({ status: data.value });
    window.location =
      this.props.location.pathname +
      "?page=" +
      this.state.activePage +
      "&status=" +
      data.value;
  };
  handleSubmit = () => {};
  headerOnClickForSort({ text, key }) {
    const { sortDirection, sortColumn } = this.state;
    if (sortColumn == key) {
      this.setState({
        sortColumn: key,
        sortDirection:
          sortDirection === "ascending" ? "descending" : "ascending"
      });
    } else {
      this.setState({
        sortColumn: key,
        sortDirection: "ascending"
      });
    }
  }
  render() {
    let { sortDirection, sortColumn } = this.state;
    const fail = getLoadingOrFail(this.state);
    if (fail) {
      return fail;
    }

    const headers = [
      {
        text: "Order #",
        key: "order_id"
      },
      {
        text: "Butler",
        key: "order.butler.user.full_name"
      },
      {
        text: "Client",
        key: "order.client.user.full_name"
      },
      {
        text: "Client Deposit",
        key: "order.client.deposit.balance"
      },
      {
        text: "Amount",
        key: "amount"
      },
      {
        text: "Status",
        key: "status"
      }
    ];

    // let orders = this.state.orders.map( o => ({
    // 	...o,
    // 	date_and_time: (
    // 		moment(_.get(o,'order.start_timestamp')).format("DD/MM/YYYY HH:mm")
    // 		+ ' - '
    // 		+ moment(_.get(o,'order.end_timestamp')).format("HH:mm")
    // 	)
    // }));

    // orders = _.sortBy(orders, ["id"])
    let data = this.state.orders.data;
    data = _.sortBy(data, [sortColumn]);
    if (sortDirection == "descending") {
      data = data.reverse();
    }
    return (
      <div>
        <h1 className="mx-2">Outstanding fee by butlers</h1>
        <Dropdown
          placeholder="Filter by status"
          fluid
          selection
          options={OPTIONS}
          onChange={this.handleDropdownChange.bind(this)}
        />
        <SimpleTable
          className="mx-2"
          theader={headers}
          tbody={data}
          baseURL="/butler-outstanding-fee/:key/details"
          key_link_to="id"
          direction={sortDirection}
          column={sortColumn}
          headerOnClickForSort={this.headerOnClickForSort.bind(this)}
        />

        <Pagination
          className="mx-2 mt-5"
          defaultActivePage={this.state.orders.page}
          totalPages={this.state.orders.lastPage}
          onPageChange={this.handlePaginationChange}
        />
        {/*<pre>{JSON.stringify(this.state.orders.data, null, '\t')}</pre>*/}
      </div>
    );
  }
}

export default OrderOutstandingFee;
