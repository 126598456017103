import API from "../../api";
import { getLoadingOrFail } from "../../helper";
import React from "react";
import _ from "lodash";
import moment from "moment";
import LabelGroup from "../../components/LabelGroup";
import { Button } from "semantic-ui-react";

import { OrderTaskMapping } from "../../WordMapping";

class Timeslots extends React.Component {
  state = {
    firstLoad: false,
    error: null,
    timeslots: null,
    data: null,
    dataLoaded: false,
    butlers: null,
    clients: []
  };

  componentWillMount() {
    API.get(`/clients`)
      .then(r => r.data)
      .then(clients => this.setState({ clients, firstLoad: true }))
      .catch(error => this.setState({ firstLoad: true, error: error }));

    API.get(`/butlers`)
      .then(r => r.data)
      .then(butlers => this.setState({ butlers, firstLoad: true }))
      .catch(error => this.setState({ firstLoad: true, error: error }));

    //
    // .then(order => this.setState({ timeslots, firstLoad: true }))
    // .catch(error => this.setState({ firstLoad: true, error: error }));
  }

  onMinutesSelect = ({ target: { value } }) => {
    if (value <= 0) {
      this.setState({ minutes: false });
      return;
    }
    this.setState({ minutes: value, needSchedules: true });
    let dates = [moment()];
    const data = [];
    for (let i = 1; i <= 10; i++) {
      API.get(
        `/scheduler?minutes=${value}&date=${moment(dates[i])
          .add(i, "days")
          .format("YYYY-MM-DD")}&client_id=${
          this.state.selected_client_id
        }&pet_sitting=true`
      )
        .then(r => r.data)
        .then(scheduler => {
          const allSchedules = _.get(scheduler, "fitButlerSchedules");
          allSchedules.map(schedule => {
            data.push({
              date: moment(dates[i])
                .add(i, "days")
                .format("YYYY-MM-DD"),
              availableIntervals: schedule.availableIntervals,
              butler:
                schedule && schedule.schedule_butler_id
                  ? _.find(this.state.butlers, [
                      "id",
                      schedule.schedule_butler_id
                    ])
                  : ""
            });
          });
          // const selected_schedules = _.get(scheduler, "fitButlerSchedules.0");
          // const selected_butler_id = _.get(
          //   selected_schedules,
          //   "schedule_butler_id"
          // );
          // const availableIntervals = _.get(
          //   selected_schedules,
          //   "availableIntervals"
          // );

          this.setState({ data, dataLoaded: true });
          console.log(data);
        });
    }
  };

  onClientSelect = ({ target: { value } }) => {
    const selected_client_id = _.toNumber(value);
    if (selected_client_id <= 0) {
      this.setState({ selected_client_id: false, selected_client: false });
      return;
    }

    this.setState({ selected_client_id });

    API.get(`/clients/${value}`)
      .then(r => r.data)
      .then(selected_client =>
        this.setState({ selected_client, needSchedules: true })
      )
      .catch(error => this.setState({ error }));
  };

  renderClients = () => {
    let { clients, pet_sitting } = this.state;

    // if (_.isUndefined(pet_sitting)) {
    //     return null;
    // }
    clients = clients.map(c => {
      return { ...c, full_name: _.capitalize(c.full_name) };
    });
    clients = _.sortBy(clients, "full_name");
    return (
      <div className="my-3">
        <h5>Client</h5>

        <select className="form-control w-75" onChange={this.onClientSelect}>
          <option value={-1}>Select a client</option>
          {clients.map(c => (
            <option key={c.i} value={c.id}>
              {" "}
              {c.full_name} {formatMembership(c.membership)}
            </option>
          ))}
        </select>
      </div>
    );
  };

  render() {
    if (!this.state.firstLoad) return <div>LOADING......</div>;
    return (
      <div className="container-fluid k-table py-3">
        <div className="row">
          <div className="col-5 border">{this.renderClients()}</div>
          <div className="col-5 border">
            <h6>工作時間（Duration)</h6>
            <select className="form-control" onChange={this.onMinutesSelect}>
              <option value={-1}>Select minutes</option>
              {_.range(12).map(i => (
                <option key={"m-" + i} value={(i + 1) * 30}>
                  {(i + 1) * 30} minutes
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          {this.state.dataLoaded
            ? _.sortBy(this.state.data, "date").map(interval => {
                return (
                  <div className="col-5 border">
                    <div>
                      {" "}
                      <h3>
                        {interval.date} - {interval.butler.full_name}
                      </h3>{" "}
                    </div>
                    {interval &&
                      interval.availableIntervals &&
                      interval.availableIntervals.map(c => {
                        return (
                          <div>
                            {moment(c.start).format("HH:mm")} -{" "}
                            {moment(c.end).format("HH:mm")}
                          </div>
                        );
                      })}
                  </div>
                );
              })
            : null}
        </div>
      </div>
    );
  }
}

const formatMembership = membership => {
  switch (membership.type) {
    case "CLUB":
      return "( Club )";
    case "SILVER":
      return "( Silver )";
    case "GLOD":
      return "( Gold )";
    case "NON_MEMBERSHIP":
      return "";
    case "GREEN_MEMBERSHIP":
      return "( Green )";
    default:
      return "";
  }
};
export default Timeslots;
