/**
 * Created by erikccoder on 24/5/2018.
 */

import React from 'react'
import _ from 'lodash'
import API from '../../api';
import {getLoadingOrFail} from '../../helper';
import moment from 'moment';

import { Dropdown } from 'semantic-ui-react'

class GiveButlerDeposit extends React.Component
{
	state = {
		error: null,
		butlers: [],
		butler_id: null,
	}

	handleDropdownChange = (e, {value}) => {
		this.setState({butler_id: value})
	}

	componentWillMount()
	{
		API.get('/butlers')
			.then(r => r.data)
			.then(butlers => this.setState({butlers, firstLoad: true}))
			.catch(error => this.setState({error, firstLoad: true}))
		;
	}

	handleSumbit = () =>
	{
		if(this.state.submitting){return;}

		const {
			butler_id,
			amount
		} = this.state;

		if(!butler_id || !_.isNumber(amount) ){
			return alert("Please select valid butler and input valid amount of deposit");
		}

		API.post('/butler-deposits', {butler_id, amount})
			.then(r => r.data)
			.then( d => {
				const search_params = `?start=${moment().add(-15,'days').format("YYYY-MM-DD")}&end=${moment().add(1,'days').format("YYYY-MM-DD")}`
				window.location = `/butler-deposit/list${search_params}`
			})
			.catch(error => this.setState({error}))
		;

		this.setState({submitting: true})
	}

	render()
	{
		const fail = getLoadingOrFail(this.state);
		if(fail){return fail}

		const options = this.state.butlers.map( b=> ({
			key: b.id,
			value: b.id,
			text: b.full_name
		}))

		return (
			<div>
				<h1 className="mx-2">GiveButlerDeposit</h1>

				<div className="form-group m-3 pr-5 mr-5">
					<label >Butler</label>
					<Dropdown
						deburr
						fluid
						options={options}
						value={this.state.butler_id}
						search
						selection
						onChange={this.handleDropdownChange}
					/>
				</div>

				<div className="form-group m-3 pr-5 mr-5">
					<label htmlFor="title">Amount</label>
					<input
						type="number"
						id="title" className="form-control" placeholder="Amount"
						onChange={ ({target : {value}})=> this.setState({amount: _.toNumber(value)})}
					/>
				</div>

				<a href="#" className="m-3 btn btn-danger" onClick={this.handleSumbit}>
				{
					this.state.submitting ? "Submitting" : "GIVE Deposit"
				}
				</a>
				{/*<pre>{JSON.stringify(_.omit(this.state, "butlers"), null, '\t')}</pre>*/}
				{/*<pre>{JSON.stringify(this.state.butlers, null, '\t')}</pre>*/}

			</div>
		)
	}

}

export default GiveButlerDeposit