import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Table } from "react-bootstrap";
import _ from "lodash";
import { Button } from "semantic-ui-react";
import API from "../../api";
import "./feedback.css";
import moment from "moment";
class CommentFeedback extends React.Component {
  state = {
    firstLoad: false,
    error: null,
    summary: null,
    searchKWord: "",
    sortDirection: null,
    sortColumn: null,
    comments: [],
    butlerFeedback: [],
    clientFeedback: [],
    isAddNew: false,
    addNewType: "CHECKLIST",
    addNewValue: "",
    order: null
  };

  componentWillMount() {
    const { order_id } = this.props.match.params;
    this.fetchOrder();
    API.get(`/admin/order/checklist/${order_id}`)
      .then(r => {
        this.setState({ comments: r.data });
        const butlerFeedback = _.filter(r.data, [
          "feedback_by",
          "BUTLER_FEEDBACK"
        ]);
        const clientFeedback = _.filter(r.data, [
          "feedback_by",
          "CLIENT_FEEDBACK"
        ]);
        this.setState({ butlerFeedback });
        this.setState({ clientFeedback });
      })
      .then(() => this.setState({ firstLoad: true }))
      .catch(error => this.setState({ firstLoad: true, error: error }));
  }

  renderClientFeedback = () => {
    const feedback = this.state.clientFeedback.map(x => {
      return (
        <div>
          <li>
            <div>
              <div style={{ display: "inline-block", marginRight: 20 }}>
                {" "}
                {x.feedback}{" "}
              </div>{" "}
              {x.status}
            </div>
          </li>
        </div>
      );
    });
    return (
      <Table>
        <td>{feedback}</td>
      </Table>
    );
  };

  deleteButlerFeedback = id => {
    const { order_id } = this.props.match.params;

    API.post(`/admin/order/checklist/status/${order_id}`, { id })
      .then(() => {
        alert("Disapproved");
        window.location.reload();
      })
      .catch(e => {
        console.log(e);
      });
  };

  approveButlerFeedback = id => {
    const { order_id } = this.props.match.params;

    API.post(`/admin/order/checklist/status/${order_id}`, {
      id,
      status: "APPROVED"
    })
      .then(() => {
        alert("Approved");
        window.location.reload();
      })
      .catch(e => {
        console.log(e);
      });
  };

  renderButlerFeedback = () => {
    const feedback = this.state.butlerFeedback.map(x => {
      console.log("xxx", x);
      return (
        <tr>
          <td>{x.feedback}</td>
          <td>{x.status}</td>
          <td>{x.type}</td>
          <td>
            {" "}
            {x.status === "PENDING" && (
              <Button
                onClick={() => this.approveButlerFeedback(x.id)}
                className="ui negative tiny button"
              >
                APPROVE
              </Button>
            )}
            {x.status === "APPROVED" && (
              <Button
                onClick={() => this.deleteButlerFeedback(x.id)}
                className="ui negative tiny button"
              >
                DISAPPROVE
              </Button>
            )}
          </td>
        </tr>
      );
    });
    return <Table>{feedback}</Table>;
  };

  seeUploads = () => {
    const { order_id } = this.props.match.params;
    this.props.history.push(`/calendar/${order_id}/upload`);
  };

  approveButler = () => {
    const { order_id } = this.props.match.params;
    API.post(
      `/admin/order/checklist/approve/all/${order_id}`,
      JSON.stringify({ id: this.state.feedback_id })
    )
      .then(() => {
        alert("Butler Feedback has been Approved");
        window.location.reload();
      })
      .catch(error => this.setState({ error }));
  };

  addNewFeedback = () => {
    const { order_id } = this.props.match.params;
    API.post(`/admin/order/checklist/${order_id}`, {
      type: this.state.addNewType,
      value: this.state.addNewValue
    })
      .then(r => {
        window.location.reload();
        console.log(r);
      })
      .catch(e => {
        console.log(e);
        alert("Some error occurred");
      });
  };

  fetchOrder() {
    const order_id = _.get(this.props, "match.params.order_id");
    API.get(`/orders/${order_id}`)
      .then(r => r.data)
      .then(order => {
        this.setState({
          order
        });
      });
  }

  saveButlerFeedback = () => {
    const order_id = _.get(this.props, "match.params.order_id");
    const { butler_feedback } = this.state.order;
    API.put(`/orders/${order_id}/butlerFeedback`, { butler_feedback })
      .then(res => {
        return res.data;
      })
      .then(data => {
        alert("Update feedback successfully");
      });
  };
  changeButlerFeedback = event => {
    const order = { ...this.state.order };
    order.butler_feedback = event.target.value;
    this.setState({
      order
    });
  };

  changeButlerFeedbackStatus(status) {
    const order_id = _.get(this.props, "match.params.order_id");
    API.put(`/orders/${order_id}/butlerFeedbackStatus`, { status })
      .then(res => {
        return res.data;
      })
      .then(data => {
        alert("Update feedback status successfully");
        window.location.reload();
      });
  }
  renderOrderButlerFeedback() {
    const { order } = this.state;
    if (!this.state.order) {
      return;
    }
    return (
      <div style={{ marginBottom: 10 }}>
        <h1>Butler Feedback</h1>
        <div
          style={{
            flex: 1,
            flexDirection: "row",
            display: "flex",
            alignItems: "center"
          }}
        >
          <div style={{ marginRight: 10 }}>
            Status: {order.butler_feedback_status}
          </div>
          {(order.butler_feedback_status === "PENDING" ||
            order.butler_feedback_status === "CANCELLED") && (
            <Button
              onClick={() => this.changeButlerFeedbackStatus("APPROVED")}
              className="ui negative tiny button"
            >
              APPROVE
            </Button>
          )}
          {order.butler_feedback_status === "APPROVED" && (
            <Button
              onClick={() => this.changeButlerFeedbackStatus("CANCELLED")}
              className="ui negative tiny button"
            >
              DISAPPROVE
            </Button>
          )}
        </div>
        <textarea
          rows={10}
          style={{ width: "100%" }}
          value={order.butler_feedback}
          onChange={this.changeButlerFeedback}
        />
        <Button onClick={this.saveButlerFeedback}>Update Feedback</Button>
      </div>
    );
  }

  render() {
    const { firstLoad, error } = this.state;

    if (!firstLoad) {
      return (
        <div className="w-100 h-100 p-5 d-inline-block">
          <h1 className="text-center align-middle">Loading...</h1>
        </div>
      );
    } else if (error) {
      return <pre>{JSON.stringify(error, null, "\t")}</pre>;
    }

    return (
      <div className="container-fluid k-table py-3">
        <div className="bg-white border-bottom p-3 row">
          <Button
            size="tiny"
            onClick={() => window.history.back()}
            color="orange"
            className="bg-red"
          >
            GO BACK
          </Button>
        </div>
        <div className="feedback">
          <Row>
            <Col className="col-xl-4" md={10} lg={10}>
              <h1>Client Checklist</h1>
              {this.renderClientFeedback()}
              <Button onClick={() => this.seeUploads()} size="tiny">
                SEE UPLOADED PHOTOS
              </Button>
              <div className="order-details-wrapper">
                <div>
                  <span>
                    Client Name: {this.state.order?.client.user.full_name}
                  </span>
                </div>
                <div>
                  <span>
                    Start Time:{" "}
                    {moment(this.state.order?.start_timestamp).format(
                      "DD-MM-YYYY HH:mm:ss"
                    )}
                  </span>
                </div>
                <div>
                  <span>
                    End Time:{" "}
                    {moment(this.state.order?.end_timestamp).format(
                      "DD-MM-YYYY HH:mm:ss"
                    )}
                  </span>
                </div>
                <div>
                  <span>
                    Butler Name: {this.state.order?.butler.user.full_name}
                  </span>
                </div>
                <div>
                  <span>Status: {this.state.order?.status}</span>
                </div>
              </div>
            </Col>
            <Col className="col-xl-4" md={10} lg={10}>
              <h1>Butler Suggestion</h1>
              {this.renderButlerFeedback()}
              <Button
                onClick={() => this.setState({ isAddNew: true })}
                size="tiny"
                color="primary"
              >
                ADD NEW FEEDBACK/COMMENTS
              </Button>
              {this.state.isAddNew ? (
                <div>
                  <div>Comments/Checklist</div>
                  <input
                    type="text"
                    value={this.state.addNewValue}
                    onChange={e =>
                      this.setState({ addNewValue: e.target.value })
                    }
                  />
                  <select
                    value={this.state.addNewType}
                    onChange={e =>
                      this.setState({ addNewType: e.target.value })
                    }
                  >
                    <option value="CHECKLIST">Checklist</option>
                    <option value="COMMENTS">Comments</option>
                  </select>
                  <button onClick={() => this.addNewFeedback()}>SAVE</button>
                </div>
              ) : null}
            </Col>
            <Col className="buttons col-xl-4" md={10} lg={10}>
              {this.renderOrderButlerFeedback()}
              <Button
                onClick={() => this.approveButler()}
                size="tiny"
                className="ui positive button"
              >
                APPROVE ALL BUTLER FEEDBACK (except cancelled)
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default CommentFeedback;
