import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import user from './user';
import clients from './clients'
import butlers from './butlers'
import salaries from './salaries'

export default combineReducers({
  router: routerReducer,
  user,
  clients,
  butlers,
  salaries,
});
