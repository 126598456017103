import React from "react";
import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";

import SimpleTable from "../../components/SimpleTable";
import ButlerSalaryToolbar from "./ButlerSalaryToolbar";
import API from "../../api";

import YearMonthPicker from "../../components/YearMonthPicker";

const headers = [
  {
    text: "Issue Date",
    key: "issue_date"
  },
  {
    text: "Name",
    key: "user.full_name"
  },
  {
    text: "Work Hours",
    key: "total_work_hours"
  },
  {
    text: "Orders",
    key: "orders.length"
  },
  {
    text: "Hourly Rate",
    key: "hour_rate"
  },
  {
    text: "Bonus",
    key: "total_bonus"
  },
  // {
  // 	text: "Compensation",
  // 	key: "compensation"
  // },
  // {
  // 	text: "Deduction",
  // 	key: "deduction"
  // },
  {
    text: "Salary for this month",
    key: "total_salary"
  }
];

class ButlerSalaryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKWord: "",
      sortDirection: null,
      sortColumn: null,
      year: moment().year(),
      month: moment().month()
    };

    const { year, month } = props;

    API.get(`/salary/${year}/${month}`)
      .then(r => r.data)
      .then(salary_list => this.props.SetSalaryList(salary_list))
      .catch(error => {
        console.log("API ERROR:", error);
      });
  }
  handleSearch = ({ year, month }) => {
    this.setState({ year, month });
    API.get(`/salary/${year}/${month}`)
      .then(r => r.data)
      .then(salary_list => this.props.SetSalaryList(salary_list))
      .catch(error => {
        console.log("API ERROR:", error);
      });
  };

  headerOnClickForSort({ text, key }) {
    const { sortDirection, sortColumn } = this.state;
    if (sortColumn == key) {
      this.setState({
        sortColumn: key,
        sortDirection:
          sortDirection === "ascending" ? "descending" : "ascending"
      });
    } else {
      this.setState({
        sortColumn: key,
        sortDirection: "ascending"
      });
    }
  }
  componentWillMount() {
    const { year, month } = this.props;
    this.setState({ year, month });
  }

  render() {
    const { searchKWord, sortDirection, sortColumn } = this.state;
    const { year, month } = this.state;

    let salaries = this.props.salaries.list
      .map(s => ({ ...s, ...s.butler }))
      .map(s => ({
        ...s,
        issue_date: moment([year, month - 1]).format("DD/MM/YYYY")
      }))
      .map(s => ({
        ...s,
        total_bonus: _.sumBy(s.bonus, "referral_total_bonus")
      }))
      .filter(
        s =>
          searchKWord == "" ||
          _.get(s, "full_name", "")
            .toLowerCase()
            .search(searchKWord.toLowerCase()) > -1
      );

    salaries = _.sortBy(salaries, [sortColumn]);
    if (sortDirection == "descending") {
      salaries = salaries.reverse();
    }

    return (
      <div className="pb-5">
        {/*<ButlerSalaryToolbar setSearchWords={ searchKWord => this.setState({searchKWord})} />*/}

        <div className="my-5">
          <YearMonthPicker
            year={year}
            month={month}
            onChange={this.handleSearch}
          />
        </div>

        <SimpleTable
          className="mx-2"
          theader={headers}
          tbody={salaries}
          baseURL={`/butlers/:key/salary/${year}/${month}`}
          key_link_to="id"
          direction={sortDirection}
          column={sortColumn}
          headerOnClickForSort={this.headerOnClickForSort.bind(this)}
        />
      </div>
    );
  }
}

const ButlerListConnected = connect(
  ({ salaries }) => ({ salaries }),
  dispatch => ({
    SetSalaryList: salary_list => {
      dispatch({ type: "SET_SALARY_LIST", salary_list });
    }
  })
)(ButlerSalaryList);

export default ButlerListConnected;
