/**
 * Created by erikccoder on 2/6/2018.
 */

import React from "react";
import _ from "lodash";

import API from "../../api";
import { getLoadingOrFail } from "../../helper";

class SchedulerOrderButlerRemark extends React.Component {
  state = {
    butler_feedbacks: [],
    firstLoad: false,
    error: null
  };

  componentWillMount() {
    const order_id = _.get(this.props, "match.params.order_id", null);

    if (order_id == null) {
      return alert("no order id found");
    }

    API.get(`/orders/${order_id}/butler-feedbacks`)
      .then(r => r.data)
      .then(butler_feedbacks =>
        this.setState({ butler_feedbacks, firstLoad: true })
      )
      .catch(error => this.setState({ firstLoad: true, error: error }));
  }
  printFeedback = f => {
    try {
      return JSON.parse(
        _.get(f, "feedback", "[]")
          .replace("\\", "")
          .replace("{", "[")
          .replace("}", "]")
      ).map(feedback => {
        return <h5>->{feedback}</h5>;
      });
    } catch (e) {
      return <h5>-> {_.get(f, "feedback", "")}</h5>;
    }
  };
  render() {
    const fail = getLoadingOrFail(this.state);
    if (fail) {
      return fail;
    }

    const order_id = _.get(this.props, "match.params.order_id", null);

    return (
      <div className="m-3">
        <h2>Butler's Feedbacks on Order #{order_id}</h2>

        {_.get(this.state.butler_feedbacks, "feedbacks", []).length ? null : (
          <h6>No Butler feedback</h6>
        )}
        {_.get(this.state.butler_feedbacks, "feedbacks", []).map(f => (
          <div className="mt-3 mb-2">
            <h1>{_.get(f, "section")}</h1>
            {this.printFeedback(f)}
            <p>{_.get(f, "remark") ? "Remark: " + _.get(f, "remark") : ""}</p>
          </div>
        ))}

        <div className="mt-3">
          {_.get(this.state.butler_feedbacks, "attachments", []).map(a => (
            <a
              href={_.get(a, "image_url")}
              target="_blank"
              style={{ width: 100 }}
            >
              <img
                style={{ width: "100%" }}
                src={_.get(a, "image_url")}
                alt=""
              />
            </a>
          ))}
        </div>
        {/*<pre>{JSON.stringify(this.state.butler_feedbacks, null, '\t')}</pre>*/}
      </div>
    );
  }
}

export default SchedulerOrderButlerRemark;
