/**
 * Created by erikccoder on 16/6/2018.
 */

export const OrderTaskMapping = function( key_word)
{
	switch (key_word)
	{
		case "CLEANING":
			return 'Housekeeping （清潔及整理）';

		case "CLOSET_MANAGEMENT":
			return 'Closet Management';

		case "COOKING":
			return 'Food Preparation';

		case "GROCERY":
			return 'Grocery';

		case "type":
			return '';

		case "priority":
			return 'Priority';

		case "remark":
			return 'Remark';

		case "ingredients":
			return 'Provide ingredients';

		case "preferences":
			return 'Dietary preferences';

		case false:
			return 'No';

		case true:
			return 'Yes';

		default:
			return key_word;
	}
}