/**
 * Created by erikccoder on 21/5/2018.
 */

import React from 'react'

const Rules = () =>(
	<div className="mx-2">
		<h1>Rules</h1>

		<h4>BUTLERS</h4>
		<ol>
			<li>Butlers will have their own gap times (rest time) each order. The gap time can be set (30min, 1 hour).</li>
			<li>Butlers can set their working start time.</li>
			<li>Butlers can set their maximum working hours</li>
			<li>Butlers can set if they are okay with pets.</li>
			<li>Butlers can set if they can cook or not.</li>
		</ol>

		<h4>CLIENTS</h4>
		<ol>
			<li>Non members can only book whatever slot is available in the calendar.</li>
			<li>Butler Club members can only book whatever slot is available in the calendar. They also have $30 off each order.</li>
			<li>Silver members can book up to 2 hours per session.</li>
			<li>Silver members must book 72 hours before the order.</li>
			<li>Silver members are allowed to have 1 outstanding order only.</li>
			<li>Gold members can book up to 4 hours per session.</li>
			<li>Gold members must book 48 hours before the order.</li>
			<li>Gold members are allowed to have 2 outstanding order only.</li>
			<li>Gold member orders will automatically be confirmed as they authority over others members.</li>
			<li>All others members will need to wait until working day to end to confirm their order (orders will be confirmed automatically).</li>
			<li>Ad Hoc orders’ price will be the same as Butler Club members.</li>
		</ol>

	</div>
)

export default Rules;