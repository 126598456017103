import env from "dotenv";
import moment from "moment-timezone";

import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
import store, { history } from "./store";
import App from "./containers/app";

import "sanitize.css/sanitize.css";
import "./index.css";

if (!process.env.REACT_APP_TZ) {
  throw new Error("cannot access process.env.REACT_APP_TZ");
}
console.log('-----');
console.log(process.env.REACT_APP_TZ);

//moment.tz.setDefault(process.env.REACT_APP_TZ);
 moment.tz.setDefault('Asia/Hong_Kong');

moment.fn.toJSON = function() {
  return this.format();
};

const target = document.querySelector("#root");

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  target
);
