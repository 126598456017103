import API from "../../api";
import React from "react";
import _ from "lodash";
import moment from "moment";
import "react-select/dist/react-select.css";

const formatMembership = membership => {
  switch (membership.type) {
    case "CLUB":
      return "( Club )";
    case "SILVER":
      return "( Silver )";
    case "GLOD":
      return "( Gold )";
    case "NON_MEMBERSHIP":
      return "";
    case "GREEN_MEMBERSHIP":
      return "( Green )";
    default:
      return "";
  }
};

class SchedulerNewOrder extends React.PureComponent {
  state = {
    order: null,
    firstLoad: false,
    error: null,
    clients: [],
    client_credits: [],
    selected_client: null,
    selected_client_id: null,
    hold_order: false,
    hour_info: null
  };

  componentWillMount() {
    API.get(`/clients`)
      .then(r => r.data)
      .then(clients => this.setState({ clients, firstLoad: true }))
      .catch(error => this.setState({ firstLoad: true, error: error }));
    API.get("/client-credits")
      .then(r => r.data)
      .then(client_credits => this.setState({ client_credits }))
      .catch(error => this.setState({ error: error }));
  }

  onClientSelect = ({ target: { value } }) => {
    const selected_client_id = _.toNumber(value);
    if (selected_client_id <= 0) {
      this.setState({ selected_client_id: false, selected_client: false });
      return;
    }

    this.setState({ selected_client_id });

    API.get(`/clients/${value}`)
      .then(r => r.data)
      .then(selected_client =>
        this.setState({ selected_client, needSchedules: true })
      )
      .catch(error => this.setState({ error }));

    API.get(`/orders/hours-left/${selected_client_id}`)
      .then(r => r.data)
      .then(hour_info => {
        this.setState({ hour_info });
      })
      .catch(error => this.setState({ error }));
  };

  onDateSelect = ({ target: { value } }) => {
    this.setState({ date: value, needSchedules: true });
  };

  onMinutesSelect = ({ target: { value } }) => {
    if (value <= 0) {
      this.setState({ minutes: false });
      return;
    }
    this.setState({ minutes: value, needSchedules: true });
  };

  onIntervalSelect = ({ target: { value } }) => {
    const selected_schedules = _.get(this.state, "selected_schedules");
    const availableIntervals = _.get(
      selected_schedules,
      "availableIntervals",
      []
    );

    const interval = _.get(availableIntervals, value);
    if (!interval) {
      return;
    }

    console.log("interval", interval);

    this.setState({
      start_timestamp: interval.start,
      end_timestamp: interval.end
    });
  };

  onButlerSelect = ({ target: { value } }) => {
    const schedule_id = _.toNumber(value);
    const selected_schedules = _.find(
      _.get(this.state, "scheduler.fitButlerSchedules"),
      { schedule_id }
    );
    const selected_butler_id = _.get(selected_schedules, "schedule_butler_id");
    const availableIntervals = _.get(selected_schedules, "availableIntervals");
    this.setState({ selected_schedules, selected_butler_id });
    this.setState({
      start_timestamp: _.get(availableIntervals, "0.start"),
      end_timestamp: _.get(availableIntervals, "0.end")
    });
  };

  getCleaningTasks = () => {
    const tasks = [];
    let priority = 1;

    const otherRemark = _.get(this.state, "cleaningTasks.otherRemark");
    if (otherRemark) {
      tasks.push({
        type: "CLEANING",
        remark: otherRemark,
        priority: priority
      });
    }

    const firstRemark = _.get(this.state, "cleaningTasks.firstRemark");
    if (firstRemark) {
      tasks.push({
        type: "CLEANING",
        remark: firstRemark,
        priority: priority++
      });
    }
    const secondRemark = _.get(this.state, "cleaningTasks.secondRemark");
    if (secondRemark) {
      tasks.push({
        type: "CLEANING",
        remark: secondRemark,
        priority: priority++
      });
    }
    const thirdRemark = _.get(this.state, "cleaningTasks.thirdRemark");
    if (thirdRemark) {
      tasks.push({
        type: "CLEANING",
        remark: thirdRemark,
        priority: priority++
      });
    }
    const fourRemark = _.get(this.state, "cleaningTasks.fourRemark");
    if (fourRemark) {
      tasks.push({
        type: "CLEANING",
        remark: fourRemark,
        priority: priority++
      });
    }
    const fifthRemark = _.get(this.state, "cleaningTasks.fifthRemark");
    if (fifthRemark) {
      tasks.push({
        type: "CLEANING",
        remark: fifthRemark,
        priority: priority++
      });
    }

    return tasks;
  };

  handleSubmit = e => {
    e.preventDefault();
    const client_id = _.get(this.state, "selected_client_id");
    const butler_id = _.get(this.state, "selected_butler_id");
    const start_timestamp = _.get(this.state, "start_timestamp");
    const end_timestamp = _.get(this.state, "end_timestamp");
    const is_flexiable = _.get(this.state, "is_flexiable");
    const pet_sitting = _.get(this.state, "pet_sitting");

    let tasks = [];

    const cleaningTasks = this.getCleaningTasks();
    if (cleaningTasks.length && this.state.need_cleaning) {
      tasks = _.concat(tasks, cleaningTasks);
    }

    const cookingTask = _.get(this.state, "cookingTask");
    if (cookingTask && this.state.need_food) {
      tasks.push({
        type: "COOKING",
        ...cookingTask
      });
    }

    const closetTasks = _.get(this.state, "closetTasks", []);

    if (closetTasks.length && this.state.need_closet) {
      closetTasks.forEach(t => tasks.push({ type: "LAUNDRY", ...t })); //App cant read CLOSET_MANAGEMENT key
    }
    const groceryTask_remark = _.get(this.state, "groceryTask.remark");
    if (groceryTask_remark && this.state.need_grocery) {
      tasks.push({
        type: "GROCERY",
        remark: groceryTask_remark
      });
    }

    if (pet_sitting) {
      tasks.push({
        type: "PET_SITTING"
      });
    }

    if (
      !client_id ||
      !butler_id ||
      !start_timestamp ||
      !end_timestamp ||
      !tasks.length
    ) {
      return alert("Input all necessary data");
    }
    const now = moment();
    const search_params = `?start=${now.format("YYYY-MM-DD")}&end=${now
      .add(30, "days")
      .format("YYYY-MM-DD")}`;
    API.post("/orders", {
      client_id,
      butler_id,
      start_timestamp,
      end_timestamp,
      tasks,
      is_flexiable,
      is_hold: this.state.hold_order
    })
      .then(response => {
        window.location = `/calendar${search_params}`;
      })
      .catch(error => {
        this.setState({ error });
        alert(error.response.data);
      });
  };

  fetchSchedule() {
    const {
      date,
      minutes,
      selected_client_id,
      pet_sitting,
      needSchedules,
      fav_only
    } = this.state;
    const client_id = selected_client_id;

    const canFetchSchedules = ![
      date,
      minutes,
      selected_client_id,
      pet_sitting
    ].includes(undefined);

    if (canFetchSchedules && needSchedules && date !== "") {
      this.setState({
        needSchedules: false,
        fitSchedules: [],
        scheduler: null,
        selected_schedules: null,
        selected_butler_id: null,
        start_timestamp: null,
        end_timestamp: null
      });

      API.get(`/scheduler`, {
        params: { date, minutes, client_id, pet_sitting, fav_only }
      })
        .then(r => r.data)
        .then(scheduler => {
          const selected_schedules = _.get(scheduler, "fitButlerSchedules.0");
          const selected_butler_id = _.get(
            selected_schedules,
            "schedule_butler_id"
          );
          const availableIntervals = _.get(
            selected_schedules,
            "availableIntervals"
          );
          this.setState({
            scheduler,
            selected_schedules,
            selected_butler_id,
            start_timestamp: _.get(availableIntervals, "0.start"),
            end_timestamp: _.get(availableIntervals, "0.end")
          });
        })
        .catch(error => this.setState({ error }));
    }
  }

  renderScheduleResults() {
    const { date, minutes } = this.state;
    const fitButlerSchedules = _.get(
      this.state,
      "scheduler.fitButlerSchedules"
    );

    if (!fitButlerSchedules || !date || !minutes) {
      return;
    }

    const scheduler = _.get(this.state, "scheduler");
    const selected_schedules = _.get(this.state, "selected_schedules");
    const availableIntervals = _.get(
      selected_schedules,
      "availableIntervals",
      []
    );

    return (
      <div className="row">
        <select
          className="form-control col-3 mx-3"
          onChange={this.onIntervalSelect}
        >
          {availableIntervals.map((c, i) => (
            <option key={i} value={i}>
              {moment(c.start).format("HH:mm")} -{" "}
              {moment(c.end).format("HH:mm")}
            </option>
          ))}
        </select>

        <select className="form-control col-3" onChange={this.onButlerSelect}>
          {fitButlerSchedules.map(c => (
            <option key={c.i} value={c.schedule_id}>
              {" "}
              {c.full_name}{" "}
            </option>
          ))}
        </select>
      </div>
    );
  }

  renderClients() {
    let { clients, pet_sitting } = this.state;

    if (_.isUndefined(pet_sitting)) {
      return null;
    }
    clients = clients.map(c => {
      return { ...c, full_name: _.capitalize(c.full_name) };
    });
    clients = _.sortBy(clients, "full_name");
    return (
      <div className="my-3">
        <h5>Client</h5>

        <select className="form-control w-75" onChange={this.onClientSelect}>
          <option value={-1}>Select a client</option>
          {clients.map(c => (
            <option key={c.i} value={c.id}>
              {" "}
              {c.full_name} {formatMembership(c.membership)}
            </option>
          ))}
        </select>
      </div>
    );
  }

  renderLabelValue(key, value) {
    return (
      <div key={key} className="mt-3">
        <h6>{key}</h6>
        <p>{value}</p>
      </div>
    );
  }

  renderClient() {
    let { selected_client, selected_client_id } = this.state;

    if (!selected_client_id) {
      return null;
    }

    if (!selected_client) {
      return (
        <div className="w-100 h-100 p-5 d-inline-block">
          <h1 className="text-center align-middle">Loading...</h1>
        </div>
      );
    }
    console.log(selected_client);
    const email = _.get(selected_client, "email", "N/A");
    const membership = _.get(selected_client, "membership.type", "N/A");
    const contact_no = _.get(selected_client, "contact_no", "N/A");
    const address = _.get(selected_client, "address", "N/A");
    const district = _.get(selected_client, "district", "N/A");
    const entry_method = _.get(selected_client, "entry_method", "N/A");
    const remarks = _.get(selected_client, "remarks", []);
    const reference_photos = _.get(selected_client, "reference_photos", []);
    const client_id = _.get(selected_client, "id", "");
    var credit = "0";
    this.state.client_credits.forEach(element => {
      if (element.client.id == client_id) {
        credit = _.get(element, "total", "0");
      }
    });
    return (
      <div>
        {this.renderLabelValue("Email", email)}
        {this.renderLabelValue("Membership", membership)}
        {this.renderLabelValue("Credit", credit)}
        {this.renderLabelValue("Contact No", contact_no)}
        {this.renderLabelValue("Address", address)}
        {this.renderLabelValue("District", district)}
        {this.renderLabelValue("入屋方法（Entry Method）", entry_method)}

        <h5 className="mt-5">Remarks</h5>
        {remarks.map((r, i) =>
          this.renderLabelValue(`Remark ${i} - ${r.type}`, r.remark)
        )}
        <div className="mt-5">
          <h6>參考相片（Reference Photo）</h6>
          {reference_photos.map((r, i) => (
            <img
              style={{ width: "100%" }}
              key={"img-" + i}
              src={_.get(r, "image_url", "N/A")}
            />
          ))}
        </div>

        {this.renderHourInfo()}
      </div>
    );
  }

  renderInputs() {
    const { selected_client, date, minutes } = this.state;

    if (!selected_client) {
      return null;
    }

    return (
      <div className="mt-3">
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="checkbox"
            id="is_flexiable"
            onChange={({ target: { value } }) =>
              this.setState({
                is_flexiable: !_.get(this.state, "is_flexiable", false)
              })
            }
          />
          <label className="form-check-label" htmlFor="is_flexiable">
            Flexible Time
          </label>
        </div>

        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="checkbox"
            id="is_fav_only"
            onChange={({ target: { checked } }) =>
              this.setState({
                fav_only: checked, //!_.get(this.state, 'fav_only', false),
                needSchedules: true
              })
            }
          />
          <label className="form-check-label" htmlFor="is_fav_only">
            only search with top 3 favourite butlers
          </label>
        </div>

        <div className="mt-3 row">
          <div className="py-3 mr-3 col">
            <h6>日期（Date）</h6>
            <input
              className="form-control"
              type="date"
              min={moment().format("YYYY-MM-DD")}
              onChange={this.onDateSelect}
            />
          </div>
          <div className="py-3 col">
            <h6>工作時間（Duration)</h6>
            <select className="form-control" onChange={this.onMinutesSelect}>
              <option value={-1}>Select minutes</option>
              {_.range(12).map(i => (
                <option key={"m-" + i} value={(i + 1) * 30}>
                  {(i + 1) * 30} minutes
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }

  renderCleaningOptions() {
    return (
      <React.Fragment>
        <option value="" />
        <option value="Kitchen (廚房)">Kitchen (廚房)</option>
        <option value="Floor (地板)">Floor (地板)</option>
        <option value="Bedroom (睡房)">Bed room (睡房)</option>
        <option value="Bathroom (浴室)">Bathroom (浴室)</option>
        <option value="Living Room (客廳)">Living Room (客廳)</option>
        <option value="Other (其他)">Other (其他)</option>
      </React.Fragment>
    );
  }

  renderCleaningTasks() {
    const {
      selected_schedules,
      selected_client,
      date,
      minutes,
      pet_sitting
    } = this.state;

    if (!selected_schedules || !selected_client || !date || !minutes) {
      return;
    }

    return (
      <div className="border-bottom">
        <label className="h5">
          <input
            type="checkbox"
            className="mr-2"
            onChange={({ target: { checked } }) =>
              this.setState({ need_cleaning: checked })
            }
          />
          Housekeeping （清潔及整理）
        </label>

        {this.state.need_cleaning ? (
          <div>
            <div className="row my-3">
              <div className="col-5">
                <h6>1st priority</h6>
                <select
                  className="form-control"
                  onChange={({ target: { value } }) => {
                    const cleaningTasks = _.get(
                      this.state,
                      "cleaningTasks",
                      {}
                    );
                    cleaningTasks.firstRemark = value;
                    this.setState({ cleaningTasks });
                  }}
                >
                  {this.renderCleaningOptions()}
                </select>
              </div>
              <div className="col-5">
                <h6>2nd priority</h6>
                <select
                  className="form-control"
                  onChange={({ target: { value } }) => {
                    const cleaningTasks = _.get(
                      this.state,
                      "cleaningTasks",
                      {}
                    );
                    cleaningTasks.secondRemark = value;
                    this.setState({ cleaningTasks });
                  }}
                >
                  {this.renderCleaningOptions()}
                </select>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-5">
                <h6>3rd priority</h6>
                <select
                  className="form-control"
                  onChange={({ target: { value } }) => {
                    const cleaningTasks = _.get(
                      this.state,
                      "cleaningTasks",
                      {}
                    );
                    cleaningTasks.thirdRemark = value;
                    this.setState({ cleaningTasks });
                  }}
                >
                  {this.renderCleaningOptions()}
                </select>
              </div>
              <div className="col-5">
                <h6>4th priority</h6>
                <select
                  className="form-control"
                  onChange={({ target: { value } }) => {
                    const cleaningTasks = _.get(
                      this.state,
                      "cleaningTasks",
                      {}
                    );
                    cleaningTasks.fourRemark = value;
                    this.setState({ cleaningTasks });
                  }}
                >
                  {this.renderCleaningOptions()}
                </select>
              </div>
              <div className="col-5 my-3">
                <h6>5th priority</h6>
                <select
                  className="form-control"
                  onChange={({ target: { value } }) => {
                    const cleaningTasks = _.get(
                      this.state,
                      "cleaningTasks",
                      {}
                    );
                    cleaningTasks.fifthRemark = value;
                    this.setState({ cleaningTasks });
                  }}
                >
                  {this.renderCleaningOptions()}
                </select>
              </div>
            </div>
            <div className="my-3">
              <h6>其他（Special instructions:）</h6>
              <input
                className="form-control"
                type="text"
                onChange={({ target: { value } }) => {
                  const cleaningTasks = _.get(this.state, "cleaningTasks", {});
                  cleaningTasks.otherRemark = "Special Instructions: " + value;
                  this.setState({ cleaningTasks });
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  closetManagementTasksChecked = ({ target: { checked, value } }) => {
    let closetTasks = _.get(this.state, "closetTasks", []);
    checked
      ? closetTasks.push({ remark: value })
      : (closetTasks = _.pullAllBy(closetTasks, [{ remark: value }], "remark"));
    this.setState({ closetTasks });
  };

  renderClosetManagementTasks() {
    const {
      selected_schedules,
      selected_client,
      date,
      minutes,
      pet_sitting
    } = this.state;

    if (!selected_schedules || !selected_client || !date || !minutes) {
      return;
    }

    return (
      <div className="my-3 border-bottom">
        <label className="h5">
          <input
            type="checkbox"
            className="mr-2"
            onChange={({ target: { checked } }) =>
              this.setState({ need_closet: checked })
            }
          />
          Closet Management (洗衣／衣服整理)
        </label>

        <div className="sr-only">
          <input
            className="form-control"
            type="text"
            onChange={({ target: { value } }) => {
              const closetTask = _.get(this.state, "closetTask", {});
              closetTask.remark = value;
              this.setState({ closetTask });
            }}
          />
        </div>

        {this.state.need_closet ? (
          <div>
            <div class="form-group">
              <label>
                <input
                  type="checkbox"
                  className="mr-2"
                  value="Wash (洗)"
                  onChange={this.closetManagementTasksChecked}
                />
                Wash (洗)
              </label>

              <label>
                <input
                  type="checkbox"
                  className="mr-2"
                  value="Dry (乾衣機)"
                  onChange={this.closetManagementTasksChecked}
                />
                Dry (乾衣機)
              </label>

              <label>
                <input
                  type="checkbox"
                  className="mr-2"
                  value="Iron (燙衣)"
                  onChange={this.closetManagementTasksChecked}
                />
                Iron (燙衣)
              </label>
            </div>
            <div className="my-3">
              <h6>其他（Special instructions:）</h6>
              <input
                className="form-control"
                type="text"
                onChange={({ target: { value } }) => {
                  let closetTasks = _.get(this.state, "closetTasks", []);
                  closetTasks = _.pullAllBy(
                    closetTasks,
                    [{ priority: 1 }],
                    "priority"
                  );
                  if (value) {
                    closetTasks.push({
                      remark: "Special Instructions: " + value,
                      priority: 1
                    });
                  }
                  this.setState({ closetTasks });
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  renderCookingTasks() {
    return (
      <div className="my-3 py-3 border-bottom">
        <label className="h5">
          <input
            type="checkbox"
            className="mr-2"
            onChange={({ target: { checked } }) =>
              this.setState({ need_food: checked })
            }
          />
          Food Preparation (準備食材)
        </label>

        {this.state.need_food ? (
          <div>
            <h6>Will you provide your own ingredients?</h6>

            <div className="row">
              <label className="col-3">
                <input
                  className="mr-2"
                  value={true}
                  type="radio"
                  name="ingredients"
                  onChange={({ target: { value } }) => {
                    const cookingTask = _.get(this.state, "cookingTask", {});
                    cookingTask.meta = {
                      ...cookingTask.meta,
                      ingredients: true
                    };
                    this.setState({ cookingTask });
                  }}
                />
                Yes
              </label>
              <label className="col-3">
                <input
                  className="mr-2"
                  value={false}
                  type="radio"
                  name="ingredients"
                  onChange={({ target: { value } }) => {
                    const cookingTask = _.get(this.state, "cookingTask", {});
                    cookingTask.meta = {
                      ...cookingTask.meta,
                      ingredients: false
                    };
                    this.setState({ cookingTask });
                  }}
                />
                No
              </label>
            </div>

            <div className="row my-3">
              <div className="col">
                <h6>Portion</h6>
                <select
                  className="form-control"
                  onChange={({ target: { value } }) => {
                    const cookingTask = _.get(this.state, "cookingTask", {});
                    cookingTask.meta = {
                      ...cookingTask.meta,
                      portion: _.toNumber(value)
                    };
                    this.setState({ cookingTask });
                  }}
                >
                  <option> </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                </select>
              </div>
              <div className="col">
                <h6>Target Budget</h6>
                <input
                  type="Number"
                  className="form-control"
                  step="10"
                  onChange={({ target: { value } }) => {
                    const cookingTask = _.get(this.state, "cookingTask", {});
                    cookingTask.meta = {
                      ...cookingTask.meta,
                      budget: _.toNumber(value)
                    };
                    this.setState({ cookingTask });
                  }}
                />
              </div>
            </div>

            <h6>Dietary preferences</h6>
            <input
              className="form-control"
              type="text"
              onChange={({ target: { value } }) => {
                const cookingTask = _.get(this.state, "cookingTask", {});
                cookingTask.meta = {
                  ...cookingTask.meta,
                  preferences: value
                };
                this.setState({ cookingTask });
              }}
            />
          </div>
        ) : null}
      </div>
    );
  }

  renderTaskForm() {
    const {
      selected_schedules,
      selected_client,
      date,
      minutes,
      pet_sitting
    } = this.state;

    if (!selected_schedules || !selected_client || !date || !minutes) {
      return;
    }

    return (
      <div>
        {pet_sitting || (
          <div className="my-3 py-3">
            {this.renderCleaningTasks()}
            {this.renderClosetManagementTasks()}
            {this.renderCookingTasks()}

            <div className="my-3 border-bottom">
              <label className="h5">
                <input
                  type="checkbox"
                  className="mr-2"
                  onChange={({ target: { checked } }) =>
                    this.setState({ need_grocery: checked })
                  }
                />
                Grocery (雜貨)
              </label>

              {this.state.need_grocery ? (
                <textarea
                  rows="4"
                  className="form-control"
                  type="text"
                  onChange={({ target: { value } }) => {
                    const groceryTask = _.get(this.state, "groceryTask", {});
                    groceryTask.remark = value;
                    this.setState({ groceryTask });
                  }}
                />
              ) : null}
            </div>
          </div>
        )}

        <a
          className=" my-5 btn btn-success btn-block"
          href="#"
          onClick={this.handleSubmit}
        >
          Submit
        </a>
      </div>
    );
  }
  handleFail = () => {
    const fail = this.state.error;
    this.setState({ error: null });
    console.log(fail);
    window.alert(fail);
  };

  renderHourInfo = () => {
    const { hour_info } = this.state;

    return (
      <>
        <div className="mt-3">
          <h6>Monthly Plan Allowed Hours:</h6>
          <p> {hour_info?.hoursAllowed} </p>
        </div>

        <div className="mt-3">
          <h6>Hours Booked This Cycle:</h6>
          <p> {hour_info?.hoursBookedThisMonth} </p>
        </div>

        <div className="mt-3">
          <h6>Hold Hours For Current Cycle:</h6>
          <p>{hour_info?.holdHoursThisMonth}</p>
        </div>

        <div className="mt-3">
          <h6>Hold Hours For The Next Cycle:</h6>
          <p>{hour_info?.holdHoursNextMonth}</p>
        </div>
      </>
    );
  };

  render() {
    const { order, firstLoad, error } = this.state;

    if (this.state.error) {
      this.handleFail();
    } else if (this.state.firstLoad) {
      // <div className="w-100 h-100 p-5 d-inline-block">
      //   <h1 className="text-center align-middle">Loading...</h1>
      // </div>;
    }

    this.fetchSchedule();
    // console.log("from main render", this.state);
    return (
      <div className="container-fluid k-table py-3">
        <div className="form-group">
          <h5 htmlFor="inMemberType">NEW Order Type</h5>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="inMemberType"
              id="inOrderTypeRadioNo"
              onChange={() =>
                this.setState({
                  pet_sitting: false,
                  hold_order: false,
                  needSchedules: true
                })
              }
            />
            <label className="form-check-label" htmlFor="inOrderTypeRadioNo">
              Normal
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="inMemberType"
              id="holdOrderTypeRadioNo"
              onChange={() =>
                this.setState({
                  pet_sitting: false,
                  hold_order: true,
                  needSchedules: true
                })
              }
            />
            <label className="form-check-label" htmlFor="holdOrderTypeRadioNo">
              Hold Order
            </label>
          </div>
          {/* <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="inMemberType"
              id="multiOrderTypeRadioNo"
              onChange={() =>
                this.setState({
                  pet_sitting: false,
                  hold_order: true,
                  needSchedules: true
                })
              }
            />
            <label className="form-check-label" htmlFor="multiOrderTypeRadioNo">
              Multiple Order
            </label>
          </div> */}
        </div>

        <div className="row">
          <div className="col-5 border">
            {this.renderClients()}
            {this.renderClient()}
          </div>
          <div className="col-7 border">
            {this.renderInputs()}

            {this.renderScheduleResults()}

            {this.renderTaskForm()}
          </div>
        </div>
      </div>
    );
  }
}

export default SchedulerNewOrder;
