/**
 * Created by erikccoder on 24/5/2018.
 */

import React from 'react'
import moment from 'moment'
import _ from 'lodash'
import SimpleTable from '../../components/SimpleTable';
import API from '../../api';
import {getLoadingOrFail, parseSearch} from '../../helper';

import DateRangePicer from '../../components/DateRangePicer'

class ButlerDepositList extends React.Component
{
	state = {
		deposit_list: [],
		firstLoad: false,
	}
	componentWillMount()
	{
		const search = parseSearch(this.props.location.search);
		const {start, end}= search;

		API.get('/butler-deposits', {params: {start, end}})
			.then(r => r.data)
			.then(deposit_list => this.setState({deposit_list, firstLoad: true}))
			.catch(error => this.setState({error, firstLoad: true}))
		;
	}

	handleDateRange = ({startDate, endDate})=>
	{
		if(!startDate || !endDate){return;}
		const search_params = `?start=${startDate.format("YYYY-MM-DD")}&end=${endDate.format("YYYY-MM-DD")}`
		window.location = `/butler-deposit/list${search_params}`
	}

	render(){

		const fail = getLoadingOrFail(this.state);
		if(fail){return fail}

		const headers =[
			{
				text: 'Date & Time',
				key: 'created_at'
			},
			{
				text: 'Butler',
				key: 'butler.user.full_name'
			},
			{
				text: 'Amount',
				key: 'amount'
			},
		]

		return (
			<div className="pb-5">
				<h1 className="mx-2">ButlerDepositList</h1>
				<div className="d-flex">
					<a href="/butler-deposit/give" className="mx-2 btn btn-danger"> GIVE Deposit</a>
					<DateRangePicer onChange={this.handleDateRange} />
				</div>
				<SimpleTable
					className="mx-2"
					theader={headers}
					tbody={this.state.deposit_list}
				/>
				{/*<pre>{JSON.stringify(this.state.deposit_list, null, '\t')}</pre>*/}
			</div>
		)
	}

}

export default ButlerDepositList
