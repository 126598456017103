import React, { Component } from "react";
import moment from "moment";
import _ from "lodash";

import { connect } from "react-redux";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";

import Clients from "../clients";
import Butlers from "../butlers";
import ButlerBasicEdit from "../butlers/ButlerBasicEdit";
import ButlerReferral from "../butlers/ButlerReferral";
import ButlerDistricts from "../butlers/ButlerDistricts";
import ButlerSchedule from "../butlers/ButlerSchedule";
import ButlerLeaves from "../butlers/ButlerLeaves";
import SchedulerList from "../scheduler/SchedulerList";
import SchedulerDetails from "../scheduler/SchedulerDetails";
import SchedulerOrderAdminRemark from "../scheduler/SchedulerOrderAdminRemark";
import SchedulerOrderClientFeedback from "../scheduler/SchedulerOrderClientFeedback";
import SchedulerOrderButlerFeedback from "../scheduler/SchedulerOrderButlerFeedback";
import SchedulerUploadImage from "../scheduler/SchedulerUploadImage";
import ClientDetails from "../clients/ClientDetails";
import ClientReferencePhotos from "../clients/ClientReferencePhotos";
import EditClientDetails from "../clients/EditClientDetails";
import NewClientForm from "../clients/NewClientForm";
import ClientAddRemark from "../clients/ClientAddRemark";
import ClientEditRemark from "../clients/ClientEditRemark";
import ClientDeposit from "../clients/ClientDeposit";
import NewOrder from "../scheduler/SchedulerNewOrder";
import MultiNewOrder from "../scheduler/SchedulerMultiNewOrder";
import OrderList from "../orders/OrderList";
import OrderExtraCharge from "../orders/OrderExtraCharge";
import OrderOutstandingFee from "../orders/OrderOutstandingFee";
import OrderOutstandingFeeDetails from "../orders/OrderOutstandingFeeDetails";
import OrderFeedback from "../feedback/feedback";
import CommentsFeedback from "../feedback/comments";
import InvoiceDetails from "../invoices/InvoiceDetails";
import CreditList from "../credits/CreditList";
import CreditGive from "../credits/CreditGive";
import NotificationList from "../push_notification/NotificationList";
import NotificationNewForm from "../push_notification/NotificationNewForm";
import Rules from "../rules/Rules";
import NewButlerForm from "../butlers/NewButlerForm";
import ButlerDepositList from "../butler_deposit/ButlerDepositList";
import GiveButlerDeposit from "../butler_deposit/GiveButlerDeposit";
import AdminNotifications from "../admin/AdminNotifications";
import WhatsNew from "../whatsnew/WhatsNewDashboard";
import WhatsNewDetails from "../whatsnew/WhatsNewDetails";
import AddWhatsNew from "../whatsnew/AddWhatsNew";
import Discount from "../discounts/DiscountDashboard";
import DiscountDetails from "../discounts/DiscountDetails";
import AddDiscount from "../discounts/AddDiscount";
import ButlerAllFeeNew from "../butlers/ButlerAllFeeNew";
import ButlerComments from "../butlers/ButlerComments";
import { Menu, Icon, Button } from "semantic-ui-react";
import API from "../../api";
import ClientUpgradePayment from "../clients/ClientUpgradePayment";
import ButlerAllFeeDetails from "../butlers/ButlerAllFeeDetails";
import ButlerRatings from "../ratings/ratings";
import Timeslots from "../timeslots/Timeslots";

class Home extends Component {
  constructor(props) {
    super(props);

    // this.state = {  }
    // console.log("Home", props);
    if (window.intervalId) {
      clearInterval(window.intervalId);
    }
    this.timer();
    window.intervalId = setInterval(this.timer, 10 * 60 * 1000);
  }
  timer = () => {
    API.get("/admin-notifications")
      .then(r => r.data)
      .then(({ latest_updated_order_tasks }) =>
        this.setState({ latest_updated_order_tasks })
      );
    // .catch(error => alert('Error occurs whil geting notification. Please make it down and call the APP IT'))
  };

  handleItemClick = (e, { name }) => {
    e.preventDefault();
    const { history, match } = this.props;
    history.push(`/${name}`);
    return;
  };

  handleSignOut = () => {
    this.props.removeUserTokens();
    this.props.history.push("/login");
  };

  render() {
    const { user, match } = this.props;
    const activeItem = match.url.substring(1, match.url.length);
    const search_params = `?start=${moment()
      .add(-15, "days")
      .format("YYYY-MM-DD")}&end=${moment()
      .add(1, "days")
      .format("YYYY-MM-DD")}`;
    const calendar_params = `?start=${moment().format(
      "YYYY-MM-DD"
    )}&end=${moment()
      .add(30, "days")
      .format("YYYY-MM-DD")}`;
    const latest_updated_order_tasks = _.get(
      this.state,
      "latest_updated_order_tasks",
      []
    );
    const feedback_params = `?start=${moment().format(
      "YYYY-MM-DD"
    )}&end=${moment().format("YYYY-MM-DD")}`;
    return (
      <div>
        {!user.token || !user.refreshToken ? <Redirect to="/login" /> : null}
        <Menu
          vertical
          pointing
          secondary
          inverted
          fixed="left"
          className="bg-li-dark main-menu-left"
          style={{
            paddingBottom: 200
          }}
          id="left-nav"
        >
          <Menu.Item header>Hi Admin</Menu.Item>
          <Menu.Item
            link
            href={`/calendar${calendar_params}`}
            name="calendar"
            active={activeItem === "calendar"}
          >
            <Icon name="calendar" className="left" />
            Calendar
          </Menu.Item>
          <Menu.Item
            link
            href="/clients"
            name="clients"
            active={activeItem === "clients"}
            onClick={this.handleItemClick}
          >
            <Icon name="user" className="left" />
            Clients
          </Menu.Item>
          <Menu.Item
            link
            href="/butlers"
            name="butlers"
            active={activeItem === "butlers"}
            onClick={this.handleItemClick}
          >
            <Icon name="home" className="left" />
            Butlers
          </Menu.Item>
          <Menu.Item
            link
            href={`/butler-deposit/list${search_params}`}
            name="butler-deposit"
            active={activeItem === "butler-deposit"}
          >
            <Icon name="home" className="left" /> Butler Deposit
          </Menu.Item>
          <Menu.Item
            as="a"
            href="/butler_ratings"
            name="butler_ratings"
            active={activeItem === "butler_ratings"}
            onClick={this.handleItemClick}
          >
            <Icon name="star" className="left" />
            Butler Ratings
          </Menu.Item>
          <Menu.Item
            as="a"
            href={`/order-extra-charges${search_params}`}
            name="orders"
            active={activeItem === "order-extra-charges"}
          >
            <Icon name="history" className="left" />
            Order Payments
          </Menu.Item>
          <Menu.Item
            link
            href="/butler-outstanding-fee"
            name="butlers"
            active={activeItem === "butler-outstanding-fee"}
          >
            <Icon name="home" className="left" />
            Order Outstanding Fee
          </Menu.Item>
          <Menu.Item
            as="a"
            href={`/orders${search_params}`}
            name="orders"
            active={activeItem === "orders"}
            onClick-x={this.handleItemClick}
          >
            <Icon name="history" className="left" />
            Order History
          </Menu.Item>
          <Menu.Item
            as="a"
            href={`/order_feedback${feedback_params}`}
            name="order_feedback"
            active={activeItem === "order_feedback"}
            onClick-x={this.handleItemClick}
          >
            <Icon name="list" className="left" />
            Order Feedback
          </Menu.Item>
          <Menu.Item
            as="a"
            href={`/credits${search_params}`}
            name="credits"
            active={activeItem === "credits"}
            onClick-x={this.handleItemClick}
          >
            <Icon name="money" className="left" />
            Give Client Value
          </Menu.Item>
          <Menu.Item
            as="a"
            href="/notifications?page=1"
            name="notifications"
            active={activeItem === "notifications"}
            onClick-x={this.handleItemClick}
          >
            <Icon name="comments" className="left" />
            Push Notification
          </Menu.Item>

          <Menu.Item
            as="a"
            href="/whatsnew"
            name="whatsnew"
            active={activeItem === "whatsnew"}
            onClick={this.handleItemClick}
          >
            <Icon name="list" className="left" />
            What's New
          </Menu.Item>
          <Menu.Item
            as="a"
            href="/discount"
            name="discount"
            active={activeItem === "discount"}
            onClick={this.handleItemClick}
          >
            <Icon name="list" className="left" />
            Discount
          </Menu.Item>
          <Menu.Item
            as="a"
            href="/rules"
            name="rules"
            active={activeItem === "rules"}
            onClick={this.handleItemClick}
          >
            <Icon name="list" className="left" />
            Rules
          </Menu.Item>
          <Menu.Item
            as="a"
            href="/timeslots"
            name="timeslots"
            active={activeItem === "timeslots"}
            onClick={this.handleItemClick}
          >
            <Icon name="list" className="left" />
            Available Timeslots
          </Menu.Item>
          <div
            className="text-center pb-3 fixed-bottom bg-li-dark"
            style={{ width: 240 }}
          >
            <div className="my-5">
              <img src="/images/butlerlogo.png" alt="Butler" width="116" />
            </div>
            <Button
              color="grey"
              size="medium"
              secondary
              onClick={this.handleSignOut}
            >
              SIGN OUT
            </Button>
          </div>
        </Menu>

        <main id="cms-main">
          <Switch>
            <Route exact path="/calendar" component={SchedulerList} />
            <Route exact path="/calendar/new" component={NewOrder} />
            <Route exact path="/calendar/multiNew" component={MultiNewOrder} />
            <Route
              exact
              path="/calendar/:order_id"
              component={SchedulerDetails}
            />
            <Route
              exact
              path="/calendar/:order_id/add-remark"
              component={SchedulerOrderAdminRemark}
            />
            <Route
              exact
              path="/calendar/:order_id/client-feedback"
              component={SchedulerOrderClientFeedback}
            />
            <Route
              exact
              path="/calendar/:order_id/butler-feedback"
              component={SchedulerOrderButlerFeedback}
            />
            <Route
              exact
              path="/calendar/:order_id/upload"
              component={SchedulerUploadImage}
            />
            <Route
              exact
              path="/clients/new-butler-from"
              component={NewClientForm}
            />
            <Route exact path="/clients/:client_id" component={ClientDetails} />
            <Route
              exact
              path="/clients/:client_id/edit"
              component={EditClientDetails}
            />
            <Route
              exact
              path="/clients/:client_id/upgrade"
              component={ClientUpgradePayment}
            />
            <Route exact path="/clients" component={Clients} />
            <Route
              exact
              path="/clients/:client_id/add-remarks"
              component={ClientAddRemark}
            />
            <Route
              exact
              path="/clients/:client_id/edit-remarks/:remark_id"
              component={ClientEditRemark}
            />
            <Route
              exact
              path="/clients/:client_id/deposit"
              component={ClientDeposit}
            />
            <Route
              exact
              path="/clients/:client_id/referencePhotos"
              component={ClientReferencePhotos}
            />
            <Route
              exact
              path="/butlers/:butler_id/districts"
              component={ButlerDistricts}
            />
            <Route
              exact
              path="/butlers/:butler_id/schedule"
              component={ButlerSchedule}
            />
            <Route
              exact
              path="/butlers/:butler_id/leaves"
              component={ButlerLeaves}
            />
            <Route
              exact
              path="/butlers/:butler_id/referred_butlers"
              component={ButlerReferral}
            />
            <Route
              exact
              path="/butlers/:butler_id/edit-basic"
              component={ButlerBasicEdit}
            />
            <Route
              exact
              path="/butlers/new-butler-from"
              component={NewButlerForm}
            />
            <Route path="/butlers" component={Butlers} />
            <Route
              exact
              path="/butler-deposit/list"
              component={ButlerDepositList}
            />
            <Route
              exact
              path="/butler-deposit/give"
              component={GiveButlerDeposit}
            />
            <Route exact path="/butler_ratings" component={ButlerRatings} />
            <Route
              exact
              path="/orders/:order_id"
              component={SchedulerDetails}
            />
            <Route
              exact
              path="/order-extra-charges"
              component={OrderExtraCharge}
            />
            <Route
              exact
              path="/butler-outstanding-fee"
              component={OrderOutstandingFee}
            />
            <Route exact path="/timeslots" component={Timeslots} />
            <Route
              exact
              path="/butler-outstanding-fee/:butler_outstanding_fee_id/details"
              component={OrderOutstandingFeeDetails}
            />
            <Route exact path="/orders" component={OrderList} />
            <Route exact path="/order_feedback" component={OrderFeedback} />
            <Route
              exact
              path="/order_feedback/:order_id"
              component={CommentsFeedback}
            />
            <Route
              exact
              path="/invoices/:client_id/details"
              component={InvoiceDetails}
            />
            <Route exact path="/credits" component={CreditList} />
            <Route exact path="/credits/give" component={CreditGive} />
            <Route exact path="/whatsnew/" component={WhatsNew} />
            <Route exact path="/whatsnew/add" component={AddWhatsNew} />
            <Route path="/whatsnew/:id" component={WhatsNewDetails} />
            <Route exact path="/discount/" component={Discount} />
            <Route exact path="/discount/add" component={AddDiscount} />
            <Route path="/discount/:id" component={DiscountDetails} />
            <Route exact path="/notifications" component={NotificationList} />
            <Route
              exact
              path="/notifications/new-form"
              component={NotificationNewForm}
            />
            <Route exact path="/admin-notify" component={AdminNotifications} />
            <Route
              exact
              path="/butler/allfee/new"
              component={ButlerAllFeeNew}
            />
            <Route exact path="/rules" component={Rules} />
            <Route
              path="/allfees/butlers/edit/:id"
              component={ButlerAllFeeDetails}
            />
            <Route
              path="/butler_ratings/:butler_id"
              component={ButlerComments}
            />
          </Switch>
        </main>
      </div>
    );
  }
}

const HomeConnected = connect(
  ({ user }) => ({ user }),
  dispatch => ({
    removeUserTokens: (token, refreshToken) => {
      dispatch({ type: "REMOVE_USER_TOKENS" });
    }
  })
)(Home);

export default withRouter(HomeConnected);
