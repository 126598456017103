/**
 * Created by erikccoder on 18/5/2018.
 */
import React from "react";
import _ from "lodash";
import API from "../../api";
import moment from "moment/moment";
import { getLoadingOrFail } from "../../helper";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./ClientUpgrade.css";
import { Dropdown } from "semantic-ui-react";
import {
  CardElement,
  injectStripe,
  StripeProvider,
  Elements
} from "react-stripe-elements";
import { Col, Row, Button, Modal } from "react-bootstrap";
import env from "dotenv";

const apiKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

const membershipOptions = [
  {
    key: "CLUB_MONTHLY_MEMBERSHIP",
    value: "CLUB_MONTHLY_MEMBERSHIP",
    text: "Club Monthly"
  },
  { key: "EKF_MEMBERSHIP", value: "EKF_MEMBERSHIP", text: "EKF" },

  {
    key: "GOLD_MONTHLY_MEMBERSHIP",
    value: "GOLD_MONTHLY_MEMBERSHIP",
    text: "Gold 2021"
  },
  {
    key: "SILVER_MONTHLY_MEMBERSHIP",
    value: "SILVER_MONTHLY_MEMBERSHIP",
    text: "Silver 2021"
  },
  {
    key: "SILVER_PLUS_MONTHLY_MEMBERSHIP",
    value: "SILVER_PLUS_MONTHLY_MEMBERSHIP",
    text: "Silver Plus 2021"
  },
  {
    key: "CLUB_MONTHLY_MEMBERSHIP",
    value: "CLUB_MONTHLY_MEMBERSHIP",
    text: "Club 2021"
  },
  {
    key: "GREEN_MONTHLY_MEMBERSHIP",
    value: "GREEN_MONTHLY_MEMBERSHIP",
    text: "Green 2021"
  },
  {
    key: "EIGHT_HOUR_EKF_MEMBERSHIP",
    value: "EIGHT_HOUR_EKF_MEMBERSHIP",
    text: "EKF Plan 8hr"
  }
];

class ClientUpgradePayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstLoad: false,
      summary: null,
      error: null,
      hidden: true
    };
  }

  componentDidMount() {
    const { client_id } = this.props.match.params;

    API.get(`/clients/${client_id}`)
      .then(r => r.data)
      .then(client_details => {
        console.log(client_details);
        this.setState({ client_details, firstLoad: true });
      })
      .catch(error => this.setState({ firstLoad: true, error: error }));

    API.get(`/clients/${client_id}/summary`)
      .then(r => r.data)
      .then(summary => this.setState({ summary }))
      .catch(error => this.setState({ error: error }));
  }

  render() {
    const client = _.get(this.state, `client_details`);
    const client_id = _.get(this.state, `client_details.id`);
    const fail = getLoadingOrFail(this.state);
    // console.log(this.state.user);
    if (fail) {
      console.log(this.state.error);
      //return fail;
    }

    const clientName = _.get(client, "full_name", "N/A");

    const attachments = _.get(client, "reference_photos", []);

    const aurl = attachments.map(function(attachment) {
      return _.get(attachment, "image_url", null);
    });

    if (!apiKey) {
      throw new Error("process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY is falsy");
    }

    return (
      <React.Fragment>
        <Col md="1" />
        <Col md="11">
          <Row>
            <Col md="4">
              <h1>Upgrade {clientName}</h1>
              <Dropdown
                className="mx-1"
                closeOnBlur
                placeholder="Membership"
                onChange={(event, data) => {
                  this.setState({ membership: data.value, hidden: false });
                }}
                selection
                options={membershipOptions}
              />
            </Col>
          </Row>
          <Row>
            {!this.state.hidden ? (
              <Col md="6">
                {" "}
                <StripeProvider apiKey={apiKey}>
                  <div className="allmargin">
                    <p>Would you like to complete the purchase?</p>
                    <Elements>
                      <CardForm
                        submit={this.submit}
                        client_id={client_id}
                        membership={this.state.membership}
                        user={this.state.client_details.user}
                      />
                    </Elements>
                  </div>
                </StripeProvider>
              </Col>
            ) : null}
          </Row>
        </Col>
      </React.Fragment>
    );
  }
}

class _CardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      message: ""
    };
    this.sendToken = this.sendToken.bind(this);
  }
  async sendToken(ev) {
    const { token, error } = await this.props.stripe.createToken({
      name: "Name"
    });
    if (!token) {
      this.setState({ message: error.type, error, show: true });
      console.log(error);
      return;
    }

    const client_id = this.props.client_id;
    const membership = this.props.membership;
    const user = this.props.user;
    const body = {
      source: token.id,
      client_id,
      cancel_at_period_end: false,
      type: membership,
      user: user
    };
    console.log(body);
    API.post(`/payments/stripeSubscriptions/${client_id}`, body)
      .then(r => r.data)
      .then(x => {
        console.log("Purchase Complete!");
        console.log(x);
        window.location = `/clients/${client_id}`;
      })
      .catch(e => {
        this.setState({ message: e.toString(), error: e, show: true });
        console.log(e);
      });
  }
  handleHide = () => {
    this.setState({ show: false });
  };
  render() {
    return (
      <React.Fragment>
        <CardElement />
        <Row>
          <Button
            className="allmargin"
            bsStyle="success"
            onClick={this.sendToken}
          >
            Send
          </Button>
        </Row>
        <div className="static-modal">
          {this.state.show ? (
            <Modal.Dialog onHide={this.handleHide}>
              <Modal.Header>
                <Modal.Title>Error Message</Modal.Title>
              </Modal.Header>

              <Modal.Body
                style={{
                  "max-height": "calc(100vh - 210px)",
                  "overflow-y": "auto"
                }}
              >
                <pre>{this.state.message}</pre>
                <pre>
                  {JSON.stringify(
                    this.state.error.response?.data ?? this.state.error,
                    null,
                    2
                  )}
                </pre>
              </Modal.Body>

              <Modal.Footer>
                <Button onClick={this.handleHide}>Close</Button>
              </Modal.Footer>
            </Modal.Dialog>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}
const CardForm = injectStripe(_CardForm);

export default ClientUpgradePayment;
