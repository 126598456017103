import React from "react";
import _ from "lodash";
import SimpleTable from "../../components/SimpleTable";
import API from "../../api";
import moment from "moment";
import DateRangePicer from "../../components/DateRangePicer";
import { parseSearch } from "../../helper";

class OrderFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKWord: "",
      firstLoad: false,
      error: null,
      sortDirection: null,
      sortColumn: null,
      butlers: []
    };
  }

  componentWillMount() {
    const search = _.chain(this.props.location.search);
    const { start, end } = parseSearch(search);
    API.get("/admin/order/checklist", { params: { start, end } })
      .then(r => {
        console.log("data", r.data);
        return r.data;
      })
      .then(orders => this.setState({ orders, firstLoad: true }))
      .catch(error => this.setState({ firstLoad: true, error: error }));
  }
  headerOnClickForSort({ text, key }) {
    const { sortDirection, sortColumn } = this.state;

    if (sortColumn == key) {
      this.setState({
        sortColumn: key,
        sortDirection:
          sortDirection === "ascending" ? "descending" : "ascending"
      });
    } else {
      this.setState({
        sortColumn: key,
        sortDirection: "ascending"
      });
    }
  }
  handleDateRange = ({ startDate, endDate }) => {
    if (!startDate || !endDate) {
      return;
    }
    const search_params = `?start=${startDate.format(
      "YYYY-MM-DD"
    )}&end=${endDate.format("YYYY-MM-DD")}`;
    window.location = `/order_feedback${search_params}`;
  };

  renderTable() {
    const headers = [
      {
        text: "Order Id",
        key: "id"
      },
      {
        text: "Order Date",
        key: "startTime"
      },
      {
        text: "Client Name",
        key: "clientName"
      },
      {
        text: "Butler Name",
        key: "butlerName"
      },
      {
        text: "status",
        key: "butlerStatusFeedback"
      }
    ];

    let { sortDirection, sortColumn } = this.state;

    let body = this.state.orders.map(function(order) {
      const id = _.get(order, "id", "N/A");
      const startTimeTS = _.get(order, "start_timestamp", "N/A");
      const endTimeTS = _.get(order, "end_timestamp", "N/A");
      const startTime = moment(startTimeTS).format("DD/MM/YYYY h:mm a");
      const clientName = _.get(order, "client.user.full_name", "N/A");
      const hours = moment(endTimeTS).diff(moment(startTimeTS), "hours");
      const butlerName = _.get(order, "butler.user.full_name", "N/A");
      const isRed =
        _.filter(_.get(order, "checklist"), {
          status: "PENDING",
          feedback_by: "BUTLER_FEEDBACK"
        }).length > 0;
      let wod = _.get(order, "start_timestamp", "N/A");
      let wod_sort = 0;
      if (wod != "N/A") {
        wod_sort = moment(wod).weekday();
        wod = moment(wod).format("dddd");
      }
      let butlerStatusFeedback = "";
      if (order.butler_feedback_status == "APPROVED") {
        butlerStatusFeedback = "APPROVED";
      } else if (order.butler_feedback == null) {
        butlerStatusFeedback = "N/A";
      } else if (
        order.butler_feedback != "" &&
        order.butler_feedback_status == "PENDING"
      ) {
        butlerStatusFeedback = "PENDING";
      }

      const startTime_sort = moment(wod);

      return {
        id,
        startTime,
        wod,
        wod_sort,
        clientName,
        hours,
        butlerName,
        unseen: order.unseen,
        isRed: isRed,
        butlerStatusFeedback
      };
    });

    let sortColMap = null;
    switch (sortColumn) {
      case "startTime":
        sortColMap = "startTime_sort";
        break;
      case "wod":
        sortColMap = "wod_sort";
        break;
    }

    body = _.sortBy(body, [sortColMap || sortColumn]);
    if (sortDirection == "descending") {
      body = body.reverse();
    }

    return (
      <div className="mx-2 my-3">
        <SimpleTable
          theader={headers}
          tbody={body}
          baseURL="/order_feedback/:key"
          key_link_to="id"
          direction={sortDirection}
          column={sortColumn}
          headerOnClickForSort={this.headerOnClickForSort.bind(this)}
        />
      </div>
    );
  }

  render() {
    const { searchKWord, firstLoad, error, sortColumn } = this.state;

    if (!firstLoad) {
      return (
        <div className="w-100 h-100 p-5 d-inline-block">
          <h1 className="text-center align-middle">Loading...</h1>
        </div>
      );
    } else if (error) {
      return <pre>{JSON.stringify(error, null, "\t")}</pre>;
    }

    let butlers = this.state.butlers.filter(
      c =>
        searchKWord == "" ||
        _.get(c, "full_name", "")
          .toLowerCase()
          .search(searchKWord.toLowerCase()) > -1
    );

    butlers = _.sortBy(butlers, [sortColumn]);
    butlers = butlers.map(butler => {
      const x = butler.badges.map(badge => {
        return (
          <p>
            {badge.name} : {badge.count}
          </p>
        );
      });
      butler.badges = x;
      return butler;
    });
    return (
      <div>
        <div className="my-5 ml-2">
          <div className="mb-3">
            <DateRangePicer onChange={this.handleDateRange} />
          </div>
          <div>
            <b>
              *<span className="color-red">RED COLOR</span> means that the
              butler feedback has not been approved yet
            </b>
          </div>
        </div>
        {this.renderTable()}
      </div>
    );
  }
}

export default OrderFeedback;
