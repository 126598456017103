/**
 * Created by erikccoder on 27/5/2018.
 */
import React, { Component } from "react";
import _ from "lodash";
import API from "../../api";
import { getLoadingOrFail } from "../../helper";
import Select from "react-select";
import "react-select/dist/react-select.css";
import { Button } from "semantic-ui-react";
import Input from "semantic-ui-react/dist/es/elements/Input/Input";

class ClientAddRemark extends Component {
  state = {
    firstLoad: false,
    error: null,
    remarks: [],
    new_remark_sp: "NORMAL",
    customHeading: "Enter the custom heading"
  };

  addRemark = e => {
    e.preventDefault();

    const { new_remark, new_remark_sp } = this.state;
    if (!new_remark || new_remark.length < 3) {
      return alert("Enter a valid remark");
    }
    const { client_id } = this.props.match.params;
    let body = {
      remarks: [
        {
          remark: new_remark,
          type: _.get(this.state, "new_remark_sp", "NORMAL")
        }
      ]
    };
    if (this.state.customHeading !== "Enter the custom heading") {
      body = {
        remarks: [
          {
            remark: new_remark,
            type: _.get(this.state, "customHeading", "NORMAL")
          }
        ]
      };
    }

    API.put(`/clients/${client_id}`, body)
      .then(_ => window.location.reload())
      .catch(error => this.setState({ firstLoad: true, error: error }));

    return;
  };

  deleteRemark = (id, e) => {
    e.preventDefault();

    const { client_id } = this.props.match.params;
    API.put(`/clients/${client_id}`, {
      remarks: [
        {
          id: id,
          status: "REMOVED"
        }
      ]
    })
      .then(_ => window.location.reload())
      .catch(error => this.setState({ firstLoad: true, error: error }));

    return;
  };

  componentWillMount() {
    const { client_id } = this.props.match.params;

    API.get(`/clients/${client_id}`)
      .then(r => r.data)
      .then(client => {
        this.setState({
          firstLoad: true,
          client,
          remarks: _.get(client, "remarks", [])
        });
      })
      .catch(error => this.setState({ firstLoad: true, error: error }));
  }
  renderCustomHeadingInput = () => {
    return (
      <div className="form-group">
        <Input
          type="text"
          placeholder="Enter custom heading"
          value={this.state.customHeading}
          onChange={({ target: { value } }) =>
            this.setState({ customHeading: value })
          }
        />
      </div>
    );
  };
  render() {
    const fail = getLoadingOrFail(this.state);
    if (fail) {
      return fail;
    }

    const options = [
      { label: "入屋方法（Entry_Method）", value: "ENTRY_METHOD" },
      { label: "需要注意事項 (Remarks)", value: "NORMAL" },
      {
        label: "工具及用品位置 （Equipments and Products Location)",
        value: "EQUIPMENTS_AND_PRODUCTS_LOCATION"
      },
      {
        label: "客人一般要求重點 (General Priorities)",
        value: "GENERAL_PRIORITIES"
      },
      { label: "客人特別事項 （Special Touch)", value: "SPECIAL_TOUCH" },
      { label: "Custom", value: "CUSTOM" }
    ];

    const { client_id } = this.props.match.params;

    return (
      <div className="m-2">
        <div className="bg-white border-bottom p-3 row">
          <Button
            size="tiny"
            href={`/clients/${client_id}`}
            color="orange"
            className="bg-red"
          >
            GO BACK
          </Button>
        </div>

        <h1>ClientAddRemark</h1>

        <h4>Remarks</h4>
        <div className="row">
          {this.state.remarks.map(r => (
            <div className="border p-3 col-6">
              <strong>{r.type}</strong>
              <p>{r.remark}</p>
              <a
                className="btn btn-success mr-2"
                href={`/clients/${client_id}/edit-remarks/${r.id}`}
              >
                Edit
              </a>
              <a
                className="btn btn-danger "
                href="#"
                onClick={e => this.deleteRemark(r.id, e)}
              >
                Remove
              </a>
            </div>
          ))}
        </div>
        <h5 className="mt-5">New Remark</h5>
        <div className="form-check form-check-inline sr-only">
          <input
            className="form-check-input"
            type="checkbox"
            name="inMemberType"
            id="inPetNone"
            onChange={({ target: { value } }) =>
              this.setState({
                new_remark_sp: value ? "SPECIAL_TOUCH" : "NORMAL"
              })
            }
          />
          <label className="form-check-label" htmlFor="inPetNone">
            Special Touch
          </label>
        </div>
        <Select
          options={options}
          simpleValue
          value={this.state.new_remark_sp}
          onChange={new_remark_sp => {
            this.setState({ new_remark_sp });
          }}
        />
        {this.state.new_remark_sp === "CUSTOM" &&
          this.renderCustomHeadingInput()}
        <div class="form-group">
          <textarea
            onChange={({ target: { value } }) =>
              this.setState({ new_remark: value })
            }
            className="form-control"
            id="exampleInputEmail1"
            placeholder="Enter Remark"
            rows={8}
          />
        </div>
        <a className="btn btn-primary mb-5" href="#" onClick={this.addRemark}>
          Add Remark
        </a>

        {/*<pre>{JSON.stringify(this.state.client, null, '\t')}</pre>*/}
      </div>
    );
  }
}

export default ClientAddRemark;
