/**
 * Created by erikccoder on 24/5/2018.
 */

import React from 'react'
import moment from 'moment'
import _ from 'lodash'
import { Dropdown } from 'semantic-ui-react'

import {parseSearch} from '../helper';

class DateRangePicer extends React.Component{

	state={
		year: null,
		month: null
	}

	componentWillMount()
	{
		const search = parseSearch(window.location.search);
		let {year, month}= search;

		year = _.toNumber(year || this.props.year);
		month = _.toNumber(month || this.props.month);



		this.setState({year, month})
	}

	handleChange = () =>
	{
		const { year, month } = this.state;
		this.props.onChange && this.props.onChange({ year, month })

	}

	render()
	{
		const options = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map(
			(m, i) => ({
				key: m,
				value: i+1,
				text: m
			})
		)
		return (
			<div className="d-flex align-items-center form-inline">
				<div className="form-group mx-2">
					<label htmlFor="year">Year</label>
					<input
						type="number"
						id="year" className="form-control ml-1" placeholder="Year"
						defaultValue={this.state.year}
						onChange={ ({target : {value}})=> this.setState({year: value})}
					/>
				</div>
				<div className="mx-2 d-flex">
					<label className="mx-2" htmlFor="month">Month</label>
					<Dropdown
						deburr
						fluid
						options={options}
						value={this.state.month}
						search
						selection
						onChange={(e, {value}) => {this.setState({month: value })}}
						style={{minWidth: 120}}
					/>
				</div>
				<a className="nav-link btn-danger "
				   href="#sumbit"
				   onClick={this.handleChange}
				>
					Find
				</a>
			</div>
		)
	}
}

export default DateRangePicer