const initialState = {
	list: [],
	details: {}
}

export default (state = initialState, action = {}) =>
{
	switch (action.type)
	{
		case "SET_SALARY_LIST":
			return {
				...state,
				list: action.salary_list,
			}

		case "SET_SALARY_DETAILS":
			return {
				...state,
				details: {
					...state.details,
					[action.butler_id]: action.salary_details
				}
			}
		default:
			return state
	}
}
