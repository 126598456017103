/**
 * Created by erikccoder on 18/5/2018.
 */

import React from 'react'
import _ from 'lodash'


export function getLoadingOrFail({firstLoad, error}){

	if (!firstLoad) {
		return (
			<div className="w-100 h-100 p-5 d-inline-block">
				<h1 className="text-center align-middle">Loading...</h1>
			</div>
		);
	}
	else if(error)
	{
		return (<pre className="alert alert-danger">{JSON.stringify(error, null, '\t')}</pre>);
	}
	return false;
}

export function parseSearch(search)
{
	return _.chain(search)
		.replace('?', '') // a=b454&c=dhjjh&f=g6hksdfjlksd
		.split('&') // ["a=b454","c=dhjjh","f=g6hksdfjlksd"]
		.map(_.partial(_.split, _, '=', 2)) // [["a","b454"],["c","dhjjh"],["f","g6hksdfjlksd"]]
		.fromPairs() // {"a":"b454","c":"dhjjh","f":"g6hksdfjlksd"}
		.value()
}