/**
 * Created by erikccoder on 18/6/2018.
 */

import React from "react";
import _ from "lodash";
import moment from "moment";
import API from "../../api";
import { getLoadingOrFail } from "../../helper";
import DatePicker from "react-datepicker";
import TimePicker from "react-bootstrap-time-picker";

import { Dropdown } from "semantic-ui-react";

class CreditGive extends React.Component {
  state = {
    clients: [],
    selected_client: null,
    amount: 0,
    remarks: ""
  };

  handleClientDropdownChange = (e, { value }) => {
    if (value < 1) {
      return;
    }

    this.setState({ selected_client: value });
  };

  handleSumbit = () => {
    if (this.state.submitting) {
      return;
    }

    const { selected_client, amount, remarks } = this.state;

    if (!selected_client) {
      return alert("Select valid client");
    }
    if (!amount || !_.isNumber(amount)) {
      return alert("input valid amount of credit");
    }

    const data = {
      amount: amount,
      remark_user: remarks
    };

    API.post("/give/credit/" + selected_client, data)
      .then(r => r.data)
      .then(_ => {
        const search_params = `?start=${moment()
          .add(-7, "days")
          .format("YYYY-MM-DD")}&end=${moment()
          .add(7, "days")
          .format("YYYY-MM-DD")}`;
        window.location = "/credits" + search_params;
      })
      .catch(error => this.setState({ submitting: null, error }));
  };

  componentWillMount() {
    API.get("/clients")
      .then(r => r.data)
      .then(clients => this.setState({ clients, firstLoad: true }))
      .catch(error => this.setState({ error, firstLoad: true }));
  }

  render() {
    const fail = getLoadingOrFail(this.state);
    if (fail) {
      return fail;
    }

    const client_options = this.state.clients.map(c => ({
      key: _.get(c, "id"),
      value: _.get(c, "id"),
      text:
        _.get(c, "full_name", "N/A") +
        " - " +
        _.get(c, "membership.type", "N/A")
    }));

    return (
      <div className="p-1">
        <h1>Give Client Value Form</h1>

        <div className="form-group col-6">
          <label>Client</label>
          <Dropdown
            deburr
            fluid
            options={client_options}
            value={this.state.selected_client}
            placeholder="Select a client"
            search
            selection
            onChange={this.handleClientDropdownChange}
          />
        </div>

        <div className="form-group col-6">
          <label htmlFor="title">Amount</label>
          <input
            className="form-control"
            placeholder="Amount"
            type="number"
            onChange={({ target: { value } }) =>
              this.setState({ amount: _.toNumber(value) })
            }
          />
        </div>

        <div className="form-group col-6">
          <label htmlFor="title">Remark</label>
          <input
            className="form-control"
            placeholder="Remark"
            onChange={({ target: { value } }) =>
              this.setState({ remarks: value })
            }
          />
        </div>

        <div className="my-3 mx-2">
          <a href="#" className="btn btn-danger" onClick={this.handleSumbit}>
            Give / Deduct Value
          </a>
        </div>

        <pre>{JSON.stringify(_.omit(this.state, "clients"), null, "\t")}</pre>

        {/*<pre>{JSON.stringify( this.state.clients, null, '\t')}</pre>*/}
      </div>
    );
  }
}

export default CreditGive;
