import React from "react";
import moment from "moment";
import _ from "lodash";
import SimpleTable from "../../components/SimpleTable";
import API from "../../api";
import { getLoadingOrFail } from "../../helper";
import DateRangePicer from "../../components/DateRangePicer";
import ClientToolbar from "../clients";
import { Button, Input } from "semantic-ui-react";
const headers = [
  {
    text: "Order #",
    key: "id"
  },
  {
    text: "Date & Time",
    key: "date_and_time"
  },
  {
    text: "Name",
    key: "client.user.full_name"
  },
  {
    text: "Membership",
    key: "membership"
  },
  {
    text: "Session",
    key: "hours"
  },
  {
    text: "Cleaning",
    key: "cleaning"
  },
  {
    text: "Cooking",
    key: "cooking"
  },
  {
    text: "Laundry",
    key: "laundry"
  },
  {
    text: "Grocery",
    key: "grocery"
  },
  {
    text: "Other",
    key: "other"
  },
  {
    text: "Total Payments",
    key: "total_cost"
  },
  {
    text: "Status",
    key: "status"
  }
];

class OrderList extends React.Component {
  state = {
    orders: [],
    firstLoad: false,
    error: null,
    sortDirection: null,
    sortColumn: null,
    searchKWord: ""
  };

  componentWillMount() {
    // start, end
    const search = _.chain(this.props.location.search)
      .replace("?", "") // a=b454&c=dhjjh&f=g6hksdfjlksd
      .split("&") // ["a=b454","c=dhjjh","f=g6hksdfjlksd"]
      .map(_.partial(_.split, _, "=", 2)) // [["a","b454"],["c","dhjjh"],["f","g6hksdfjlksd"]]
      .fromPairs() // {"a":"b454","c":"dhjjh","f":"g6hksdfjlksd"}
      .value();

    const { start, end } = search;

    API.get("/orders", { params: { start, end } })
      .then(r => r.data)
      .then(orders => this.setState({ orders, firstLoad: true }))
      .catch(error => this.setState({ error, firstLoad: true }));
  }

  handleDateRange = ({ startDate, endDate }) => {
    if (!startDate || !endDate) {
      return;
    }
    const search_params = `?start=${startDate.format(
      "YYYY-MM-DD"
    )}&end=${endDate.format("YYYY-MM-DD")}`;
    window.location = `/orders${search_params}`;
  };

  headerOnClickForSort({ text, key }) {
    const { sortDirection, sortColumn } = this.state;
    if (sortColumn == key) {
      this.setState({
        sortColumn: key,
        sortDirection:
          sortDirection === "ascending" ? "descending" : "ascending"
      });
    } else {
      this.setState({
        sortColumn: key,
        sortDirection: "ascending"
      });
    }
  }

  render() {
    const fail = getLoadingOrFail(this.state);
    if (fail) {
      return fail;
    }

    const { sortDirection, sortColumn, searchKWord } = this.state;
    const newSearchKWord = searchKWord.replace(/[^a-z0-9]/g, "");
    let orders = this.state.orders.map(o => ({
      ...o,
      date_and_time: moment(o.start_timestamp).format("DD/MM/YYYY HH:mm"),
      hours: moment(o.end_timestamp).diff(o.start_timestamp, "hours", true),
      cleaning: _.find(o.tasks, { type: "CLEANING" }) ? "✓" : "",
      cooking: _.find(o.tasks, { type: "COOKING" }) ? "✓" : "",
      laundry: _.find(o.tasks, { type: "LAUNDRY" }) ? "✓" : "",
      grocery: _.find(o.tasks, { type: "GROCERY" }) ? "✓" : "",
      pet_sitting: _.find(o.tasks, { type: "PET_SITTING" }) ? "✓" : "",
      other: _.find(o.tasks, { type: "OTHER" }) ? "✓" : "",
      total_cost: _.sumBy(o.payment, "amount"),
      status: o.status,
      membership: o.client.membership.type
    }));

    orders = _.sortBy(orders, [sortColumn]);
    if (sortDirection == "descending") {
      orders = orders.reverse();
    }
    orders = orders.filter(c => {
      return (
        newSearchKWord == "" ||
        _.get(c, "client.user.full_name", "")
          .toLowerCase()
          .search(newSearchKWord.toLowerCase()) > -1
      );
    });

    return (
      <div className="pb-5">
        <h1>OrderList</h1>

        <div className="mx-2 display-inline-flex">
          <DateRangePicer onChange={this.handleDateRange} />
          <Input
            className="mx-1"
            label={
              <Button inverted className="bg-red" icon="search" name="search" />
            }
            labelPosition="right"
            onChange={(e, d) => this.setState({ searchKWord: d.value })}
            placeholder="Search Client"
          />
        </div>

        <SimpleTable
          className="mx-2"
          theader={headers}
          tbody={orders}
          baseURL="/orders/:key"
          key_link_to="id"
          direction={sortDirection}
          column={sortColumn}
          headerOnClickForSort={this.headerOnClickForSort.bind(this)}
        />
      </div>
    );
  }
}

export default OrderList;
