/**
 * Created by erikccoder on 30/5/2018.
 */

import React from "react";
import moment from "moment";
import _ from "lodash";
import Select from "react-select";
import SimpleTable from "../../components/SimpleTable";
import API from "../../api";
import { getLoadingOrFail, parseSearch } from "../../helper";
import DateRangePicer from "../../components/DateRangePicer";

class OrderExtraCharge extends React.Component {
  state = {
    orders: [],
    clients: [],
    firstLoad: false,
    error: null,
    selected_extra_id: null
  };

  componentWillMount() {
    // start, end
    const search = parseSearch(this.props.location.search);
    const { start, end } = search;

    API.get("/admin/orders/extra-charges", { params: { start, end } })
      // API.get('/orders', {params: {start, end}})
      .then(r => r.data)
      .then(orders => this.setState({ orders, firstLoad: true }))
      .catch(error => this.setState({ error, firstLoad: true }));
  }
  handleDateRange = ({ startDate, endDate }) => {
    if (!startDate || !endDate) {
      return;
    }
    const search_params = `?start=${startDate.format(
      "YYYY-MM-DD"
    )}&end=${endDate.format("YYYY-MM-DD")}`;
    window.location = `/order-extra-charges${search_params}`;
  };
  handleSubmit = () => {
    const { selected_extra_id, orders } = this.state;

    const extra_charge = _.find(orders, { id: selected_extra_id });
    const deposit = _.get(extra_charge, "order.client.deposit.balance");
    const client_id = _.get(extra_charge, "order.client_id");
    const amount = _.get(extra_charge, "amount");

    console.log({
      selected_extra_id,
      extra_charge,
      deposit,
      client_id,
      amount
    });

    if (!client_id) {
      return alert("No client id found");
    }
    if (!deposit || !amount || deposit < amount) {
      return alert("Client deposit is not enough for the payment");
    }

    // /payments/order-extra-charge/:client_id
    // type: 'required|in:ORDER_EXTRA_CHARGE',
    // order_extra_id: 'required_when:type,ORDER_EXTRA_CHARGE',
    API.post(`/payments/order-extra-charge/${client_id}`, {
      type: "ORDER_EXTRA_CHARGE",
      order_extra_id: selected_extra_id
    })
      .then(_ => window.location.reload())
      .catch(error => this.setState({ error }));
  };

  render() {
    const fail = getLoadingOrFail(this.state);
    if (fail) {
      return fail;
    }

    const headers = [
      {
        text: "Order #",
        key: "order_id"
      },
      {
        text: "Date & Time",
        key: "date_and_time"
      },
      {
        text: "Client",
        key: "order.client.user.full_name"
      },
      {
        text: "Client Deposit",
        key: "order.client.deposit.balance"
      },
      {
        text: "Amount",
        key: "amount"
      },
      {
        text: "Paid by",
        key: "extraChargePayment.payment.paid_by"
      }
    ];

    let orders = this.state.orders.map(o => ({
      ...o,
      date_and_time:
        moment(_.get(o, "order.start_timestamp")).format("DD/MM/YYYY HH:mm") +
        " - " +
        moment(_.get(o, "order.end_timestamp")).format("HH:mm")
    }));

    // orders = _.sortBy(orders, ["id"])

    return (
      <div>
        <h1 className="mx-2">Order With Extra Charges</h1>

        <div className="mx-2">
          <DateRangePicer onChange={this.handleDateRange} />
          <div className="my-3 row">
            <div className="col-3 mx-2 pr-0">
              <Select
                value={this.state.selected_extra_id}
                options={orders
                  .filter(o => !_.get(o, "extraChargePayment.payment.paid_by"))
                  .map(o => ({
                    label: `Order #${o.order_id} | ${o.id}`,
                    value: o.id
                  }))}
                onChange={({ value }) =>
                  this.setState({ selected_extra_id: value })
                }
              />
            </div>
            <div className="col-3 pl-0">
              <a
                onClick={this.handleSubmit}
                className="btn btn-danger"
                href="#"
              >
                {" "}
                Pay by client deposit
              </a>
            </div>
          </div>
        </div>

        <SimpleTable
          className="mx-2"
          theader={headers}
          tbody={orders}
          // baseURL="/calendar/:key/details"
          // key_link_to="order_id"
        />

        {/*<pre>{JSON.stringify(this.state.orders, null, '\t')}</pre>*/}
      </div>
    );
  }
}

export default OrderExtraCharge;
