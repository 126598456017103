import React from "react";
import PropTypes from "prop-types";

import { Button, Input, Grid } from "semantic-ui-react";

const RatingsToolbar = props => {
  return (
    <Grid
      padded
      verticalAlign="middle"
      className="bg-white border-bottom sticky-top"
    >
      <Grid.Column width={14} textAlign="right">
        <Input
          className="mx-1"
          label={
            <Button inverted className="bg-red" icon="search" name="search" />
          }
          labelPosition="right"
          onChange={(e, d) => props.setSearchWords(d.value)}
          placeholder="Search Butler"
        />
      </Grid.Column>
    </Grid>
  );
};

RatingsToolbar.propTypes = {
  setSearchWords: PropTypes.func.isRequired
};

export default RatingsToolbar;
