/**
 * Created by erikccoder on 17/4/2018.
 */
import React from "react";
import PropTypes from "prop-types";

import { Button, Dropdown, Input, Grid } from "semantic-ui-react";

const membershipOptions = [
  { key: "all", value: "all", text: "All Membership" },
  { key: "gold", value: "gold", text: "Gold" },
  { key: "silver", value: "silver", text: "Silver" },
  { key: "club", value: "club", text: "Club" }
];

const districtOptions = [
  { key: -1, text: "All District", value: "All District" },
  {
    key: 0,
    text: "---Hong Kong Island",
    value: "---Hong Kong Island",
    disabled: true
  },
  { key: 1, text: "Wan Chai", value: "Wan Chai" },
  { key: 2, text: "Southern", value: "Southern" },
  { key: 3, text: "Eastern", value: "Eastern" },
  { key: 4, text: "Central & Western", value: "Central & Western" },
  { key: 0, text: "---Kowloon", value: "---Kowloon", disabled: true },
  { key: 5, text: "Yau Tsim Mong", value: "Yau Tsim Mong" },
  { key: 6, text: "Wong Tai Sin", value: "Wong Tai Sin" },
  { key: 7, text: "Sham Shui Po", value: "Sham Shui Po" },
  { key: 8, text: "Kwun Tong", value: "Kwun Tong" },
  { key: 9, text: "Kowloon City", value: "Kowloon City" },
  { key: 10, text: "Yuen Long", value: "Yuen Long" },
  { key: 11, text: "Tuen Mun", value: "Tuen Mun" },
  { key: 12, text: "Tsuen Wan", value: "Tsuen Wan" },
  { key: 13, text: "Tai Po", value: "Tai Po" },
  { key: 14, text: "Sha Tin", value: "Sha Tin" },
  { key: 15, text: "Sai Kung", value: "Sai Kung" },
  { key: 16, text: "North", value: "North" },
  { key: 17, text: "Kwai Tsing", value: "Kwai Tsing" },
  { key: 18, text: "Islands", value: "Islands" }
];
const ClientToolbar = props => {
  return (
    <Grid
      padded
      verticalAlign="middle"
      className="bg-white border-bottom sticky-top"
    >
      <Grid.Column width={2}>
        <Button
          as="a"
          size="tiny"
          color="orange"
          className="bg-red"
          href="/clients/new-butler-from"
        >
          NEW CLIENT
        </Button>
      </Grid.Column>
      <Grid.Column width={14} textAlign="right">
        <Dropdown
          className="mx-1"
          closeOnBlur
          defaultValue={membershipOptions[0].value}
          placeholder="Membership"
          selection
          options={membershipOptions}
        />
        <Dropdown
          className="mx-1"
          closeOnBlur
          defaultValue={districtOptions[0].value}
          placeholder="District"
          selection
          options={districtOptions}
        />

        <Input
          className="mx-1"
          label={
            <Button inverted className="bg-red" icon="search" name="search" />
          }
          labelPosition="right"
          onChange={(e, d) => props.setSearchWords(d.value)}
          placeholder="Search Client"
        />

        <Button size="tiny" className="mx-1">
          RESET
        </Button>
        <Button
          size="tiny"
          className="mx-1 bg-red"
          color="orange"
          onClick={props.export}
        >
          EXPORT
        </Button>
      </Grid.Column>
    </Grid>
  );
  /*
Boostrap grid
	return (
		<div className="row pb-2 px-3 border-bottom">
			<Button
				size="tiny"
				color="orange"
				className="bg-red col-1 "
			>
				NEW CLIENT
			</Button >
			<div className="offset-1 col-10 row">
				<Dropdown
					className="col-3 ml-2"
					closeOnBlur
					defaultValue={membershipOptions[0].value}
					placeholder='Membership' selection options={membershipOptions} />
				<Dropdown
					className="col-3 ml-2"
					closeOnBlur
					defaultValue={districtOptions[0].value}
					placeholder='District' selection options={districtOptions} />

				<Input
					label={<Button inverted className="bg-red" icon="search" name="search" />}
					labelPosition='right'
					className="col-3 ml-2"
					placeholder='Search Client'
				/>

				<Button
					size="tiny"
					className="col ml-2"
				>
					RESET
				</Button >
				<Button
					size="tiny"
					inverted
					className="bg-red col ml-2"
				>
					EXPORT
				</Button >
			</div>

		</div>
	)
*/
};

ClientToolbar.propTypes = {
  setSearchWords: PropTypes.func.isRequired
};

export default ClientToolbar;
