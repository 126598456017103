import React from "react";
import _ from "lodash";
import { connect } from "react-redux";

import SimpleTable from "../../components/SimpleTable";
import API from "../../api";
import {
  Icon,
  Button,
  Dropdown,
  Input,
  Divider,
  Grid
} from "semantic-ui-react";
const headers = [
  {
    text: "Title",
    key: "title"
  },
  {
    text: "Text",
    key: "text"
  }
];

class WhatsNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKWord: "",
      firstLoad: false,
      error: null,
      sortDirection: null,
      sortColumn: null,
      whatsnew: undefined
    };
  }
  componentWillMount() {
    API.get("/whatsnew").then(res => {
      console.log(res.data);
      if (res.data) {
        this.setState({ whatsnew: res.data, firstLoad: true });
      } else {
        this.setState({ firstLoad: true });
      }
    });
  }
  headerOnClickForSort({ text, key }) {
    const { sortDirection, sortColumn } = this.state;
    if (sortColumn == key) {
      this.setState({
        sortColumn: key,
        sortDirection:
          sortDirection === "ascending" ? "descending" : "ascending"
      });
    } else {
      this.setState({
        sortColumn: key,
        sortDirection: "ascending"
      });
    }
  }

  render() {
    const {
      searchKWord,
      firstLoad,
      error,
      sortDirection,
      sortColumn
    } = this.state;

    if (!firstLoad) {
      return (
        <div className="w-50 h-100 p-5 d-inline-block">
          <h1 className="text-center align-middle">Loading...</h1>
        </div>
      );
    } else if (error) {
      return <pre>{JSON.stringify(error, null, "\t")}</pre>;
    }
    const newSearchKWord = searchKWord.replace(/[^a-z0-9]/g, "");

    // let clients = this.props.clients.list.filter(c => {
    //   if (
    //     newSearchKWord == "" ||
    //     _.get(c, "full_name", "")
    //       .toLowerCase()
    //       .search(newSearchKWord.toLowerCase()) > -1
    //   ) {
    //     return true;
    //   }
    //   if (_.get(c, "contact_no", "") != null)
    //     if (
    //       newSearchKWord == "" ||
    //       _.get(c, "contact_no", "").search(newSearchKWord.toLowerCase()) > -1
    //     ) {
    //       return true;
    //     }
    //   return false;
    // });

    // clients = clients.map(function(client) {
    //   client.membership = _.get(client, "membership.type", "MEMBER");
    //   return client;
    // });

    let whatsnew = this.state.whatsnew;
    whatsnew = _.sortBy(whatsnew, [sortColumn]);

    if (sortDirection == "descending") {
      whatsnew = whatsnew.reverse();
    }

    return (
      <div>
        <Grid
          padded
          verticalAlign="middle"
          className="bg-white border-bottom sticky-top"
        >
          <Grid.Column width={2}>
            <Button
              as="a"
              size="tiny"
              color="orange"
              className="bg-red"
              href="/whatsnew/add"
            >
              NEW
            </Button>
          </Grid.Column>
        </Grid>
        <div className="mx-2 my-3">
          <SimpleTable
            theader={headers}
            tbody={this.state.whatsnew}
            baseURL="/whatsnew/:key"
            key_link_to="id"
            direction={sortDirection}
            column={sortColumn}
            headerOnClickForSort={this.headerOnClickForSort.bind(this)}
          />
        </div>
      </div>
    );
  }
}

// const ClientsConnected = connect(
//   ({ clients }) => ({ clients }),
//   dispatch => ({
//     SetClientList: clients_list => {
//       dispatch({ type: "SET_CLIENTS_LIST", clients_list });
//     }
//   })
// )(Clients);

export default WhatsNew;
