import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import LabelGroup from "../../components/LabelGroup";

import API from "../../api";
import { getLoadingOrFail } from "../../helper";
import moment from "moment/moment";
import {
  Button,
  Checkbox,
  Label,
  Form,
  Input,
  Radio,
  Select,
  TextArea,
  Dropdown,
  Loader
} from "semantic-ui-react";

import DatePicker from "react-datepicker";

const MEMBERSHIP_OPTIONS = [
  {
    key: 1,
    text: "Gold",
    value: "GOLD"
  },
  {
    key: 2,
    text: "Club",
    value: "CLUB"
  },
  {
    key: 3,
    text: "Sliver",
    value: "SLIVER"
  }
];

const DISTRICT_OPTIONS = [
  "---Hong Kong Island",
  "Wan Chai",
  "Southern",
  "Eastern",
  "Central & Western",
  "---Kowloon",
  "Yau Tsim Mong",
  "Wong Tai Sin",
  // "Yuen Long",
  "Sham Shui Po",
  "Kwun Tong",
  "Kowloon City",
  "Tuen Mun",
  "Tsuen Wan",
  "Tai Po",
  "Islands",
  "Sai Kung",
  "Kwai Tsing",
  "North",
  "Sha Tin"
];

const PET_OPTIONS = [
  {
    label: "None",
    value: "NONE"
  },
  {
    label: "Dog",
    value: "DOG"
  },
  {
    label: "Cat",
    value: "CAT"
  },
  {
    label: "Other",
    value: "OTHER"
  }
];

class ClientForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        email: null,
        password: null,
        full_name: null,

        contact_no: null,
        address: null,
        district: null,
        entry_method: null,
        pet: {},
        birthday: null,
        remarks: []
      },
      districts: [],
      mapping: null,
      loading: true
    };
  }

  componentWillMount() {
    API.get("/districts")
      .then(r => r.data)
      .then(({ districts, mapping }) => {
        this.setState({ districts, mapping });
        console.log({ districts, mapping });
      })
      .finally(() => this.setState({ loading: false }));
  }

  reset() {
    this.setState({
      data: {
        email: null,
        password: null,
        full_name: null,

        contact_no: null,
        address: null,
        district: null,
        entry_method: null,
        pet: {},

        remarks: []
      }
    });
  }

  setFormData(data) {
    this.setState({ ...this.state.data, ...data });
  }

  getFormData() {
    return this.state.data;
  }

  setFormDataByApiFormat(apiData) {
    this.setState({ data: apiData });
  }
  getFormDataByApiEditFormat() {
    const {
      email,
      full_name,

      contact_no,
      address,
      district,
      entry_method,
      pet,
      birthday,
      remarks
    } = this.state.data;

    let output = {
      email,
      full_name,
      birthday,
      contact_no,
      address,
      district,
      entry_method,
      remarks
    };
    if (pet && pet.type && pet.type != "NONE") {
      output["pet"] = pet;
    }
    if (birthday) {
      output["birthday"] = moment(birthday).format("DD-MM-YYYY");
    }
    return output;
  }

  getRemarkObject(remark, isSpecialTouch) {
    return { remark, type: isSpecialTouch ? "SPECIAL_TOUCH" : "NORMAL" };
  }
  getFormDataByApiCreateFormat() {
    const {
      email,
      password,
      full_name,
      birthday,
      contact_no,
      address,
      district,
      entry_method,
      pet,

      remarks
    } = this.state.data;

    let output = {
      email,
      password,
      full_name,

      contact_no,
      address,
      district,
      entry_method,
      remarks,
      birthday
    };
    if (pet && pet.type && pet.type != "NONE") {
      output["pet"] = pet;
    }
    return output;
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    const { data, districts, mapping } = this.state;
    const { isEdit } = this.props;

    const new_districts = _.filter(districts, { is_new: true });

    return (
      <div className="">
        <div className="s_row" style={{ marginTop: 0, paddingTop: 24 }}>
          <div className="s_profilepic sr-only">
            <img src="" />
          </div>
          <input
            placeholder="Name"
            value={_.get(data, "full_name")}
            onChange={event => {
              this.setData("full_name", event.target.value);
            }}
          />
        </div>

        {/*
                <div className="s_row s_flex">
		    		<label>Membership</label>
                	<Dropdown
		                placeholder="Membership"
		                fluid
		                selection
		                options={MEMBERSHIP_OPTIONS}
		                value={data.membership}
		                onChange={(event,data)=>{this.setData('membership',data.value)}}
		              />
                </div>

			    <div className="s_row s_flex">
			    	<div className="col-sm-6">
			    		<label>Membership start date</label>
					    <DatePicker dateFormat="DD/MM/YYYY" placeholder='Membership start date' selected={data.start} onChange={(start)=>{this.setData('start',start)}} />
			    	</div>
			    	<div className="col-sm-6">
			    		<label>Membership end date</label>
					    <DatePicker dateFormat="DD/MM/YYYY" placeholder='Membership end date' selected={data.end} onChange={(end)=>{this.setData('end',end)}} />
			    	</div>
			    </div>
			    <div className="s_row s_flex">
			    	<div className="col-sm-6">
			    		<label>Pause membership</label>
			    	</div>
			    	<div className="col-sm-6">
			    		<label>Lockup period</label>
			    	</div>
			    </div>
			*/}

        <div className="s_row s_flex">
          <label>Email</label>
          <input
            placeholder="Email"
            value={_.get(data, "email")}
            onChange={event => {
              this.setData("email", event.target.value);
            }}
          />
        </div>

        {!isEdit ? (
          <div className="s_row s_flex">
            <label>Password</label>
            <input
              type="password"
              placeholder="Password"
              value={_.get(data, "password")}
              onChange={event => {
                this.setData("password", event.target.value);
              }}
            />
          </div>
        ) : null}

        <div className="s_row s_flex">
          <label>Contact no.</label>
          <input
            placeholder="Contact no."
            value={_.get(data, "contact_no")}
            onChange={event => {
              this.setData("contact_no", event.target.value);
            }}
          />
        </div>

        <div className="s_row s_flex">
          <label>Address</label>
          <input
            placeholder="Address"
            value={_.get(data, "address")}
            onChange={event => {
              this.setData("address", event.target.value);
            }}
          />
        </div>

        <div className="s_row s_flex">
          <label>Birthday</label>
          <input
            placeholder="DD-MM-YYYY"
            value={
              _.get(data, "birthday", null)
                ? moment(_.get(data, "birthday")).format("DD-MM-YYYY")
                : null
            }
            onChange={event => {
              this.setData("birthday", event.target.value);
            }}
          />
        </div>

        <div className="s_row s_flex">
          <label>District</label>
          <Dropdown
            placeholder="District"
            fluid
            selection
            options={_.concat(
              DISTRICT_OPTIONS.map(val => {
                if (val.charAt(0) == "-") {
                  return {
                    text: val,
                    value: val,
                    disabled: true
                  };
                } else {
                  return {
                    text: _.compact([val, mapping[val]]).join(" -> "),
                    value: val
                  };
                }
              }),
              [
                {
                  text: "----- New Districts --------",
                  value: "new",
                  disabled: true
                }
              ],
              new_districts.map(({ district }) => ({
                text: district,
                value: district
              }))
            )}
            value={data.district}
            onChange={(event, data) => {
              this.setData("district", data.value);
            }}
          />
        </div>

        <div className="s_row s_flex">
          <label className="full">Pets</label>
          {PET_OPTIONS.map((item, key) => {
            return (
              <label className="s_container">
                {item.label}
                <input
                  type="radio"
                  value={item.value}
                  checked={
                    data.pet.type === item.value ||
                    (!data.pet.type && item.value === "NONE")
                  }
                  onChange={event => {
                    this.setPetData("type", event.target.value);
                  }}
                />
                <span className="s_checkmark" />
              </label>
            );
          })}
          <input
            placeholder="Please specify"
            style={{ width: 150 }}
            value={_.get(data.pet, "remark")}
            onChange={event => {
              this.setPetData("remark", event.target.value);
            }}
          />
        </div>

        <div className="s_row s_flex">
          <label className="full">Pet name</label>
          <input
            placeholder="Pet name"
            style={{ width: 150 }}
            value={_.get(data.pet, "name")}
            onChange={event => {
              this.setPetData("name", event.target.value);
            }}
          />
        </div>

        {isEdit ? (
          // <div className="s_row s_flex">
          // 	<label>Butler exclusion</label>
          //          	<input placeholder='Butler exclusion' />
          // </div>
          <div />
        ) : null}
        <div className="s_row s_flex">
          <label>入屋方法（Entry Method）</label>
          <input
            placeholder="入屋方法（Entry Method）"
            value={_.get(data, "entry_method")}
            onChange={event => {
              this.setData("entry_method", event.target.value);
            }}
          />
        </div>

        {
          // data.remarks.map((item,key)=>{
          // 	const number = key+1
          // 	return (
          // 		<div className="s_row s_flex">
          //   	<label className="full">Remark {number}</label>
          //            	<textarea
          //            		placeholder={'Remark '+number}
          //            		value={_.get(item,'remark')}
          //            		onChange={(event)=>{this.setData('remark',event.target.value)}}
          //            		style={{width: 'calc(100% - 72px)'}}
          //            	/>
          //            	<a className="btn btn-danger xs" style={{color:'#fff'}} onClick={this.removeRemark(key)}>Delete</a>
          //   </div>
          // 	)
          // })
        }

        {/*<div className="s_row s_flex">
			    	<label>Special Touch</label>
                	<textarea placeholder='Special Touch' value={_.get(data,'special_touch')} onChange={(event)=>{this.setData('special_touch',event.target.value)}}/>
			    </div>*/}

        <div className="s_row s_flex sr-only">
          <p
            className="s_textbutton"
            id="attach_ref_photo"
            onClick={this.handleAttachRefPhoto}
          >
            Attach reference photos
          </p>
          <div className="s_photos_list">
            <div className="rectangle-19" />
          </div>
        </div>
      </div>
    );
  }

  // private function
  setData(key, val) {
    let data = { ...this.state.data };
    data[key] = val;
    this.setState({ data });
  }
  setPetData(key, val) {
    let data = { ...this.state.data };
    data["pet"][key] = val;
    this.setState({ data });
  }
  removeRemark(key) {
    let data = { ...this.state.data };
    data["remarks"].splice(key, 1);
    this.setState({ data });
  }
}

export default ClientForm;
