/**
 * Created by erikccoder on 2/6/2018.
 */

import React from "react";
import _ from "lodash";

import API from "../../api";
import { getLoadingOrFail } from "../../helper";

class SchedulerUploadImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: undefined,
      numUploaded: 0,
      loading: false,
      description: undefined,
      images: [] //already uploaded images,
    };
  }
  _handleImageChange(e) {
    e.preventDefault();
    this.setState({ file: e.target.files[0] });
  }

  renderUploadField = () => {
    return (
      <div>
        <input
          className="fileInput"
          type="file"
          onChange={e => this._handleImageChange(e)}
        />
        <input
          type="text"
          name="description"
          style={{ height: 100, width: 300, backgroundColor: "white" }}
          onChange={e => {
            this.setState({ description: e.target.value });
          }}
          placeholder="Enter the description of the photo (if any)"
        />
        <button
          onClick={e => {
            this.uploadFile(e);
          }}
        >
          {" "}
          Upload the file{" "}
        </button>
      </div>
    );
  };

  uploadFile = e => {
    const order_id = _.get(this.props, "match.params.order_id", null);
    this.setState({ loading: true });

    console.log(this.state.description);

    var formData = new FormData();
    formData.append("type", "IMAGE");
    formData.append("description", this.state.description);
    formData.append("fileUpload", this.state.file);
    formData.append("orderId", order_id);
    API.post(`/order/remarks`, formData).then(response => {
      console.log(response);
      this.setState({ loading: false });
      if (response.status == 200) {
        this.setState({ loading: false });
        window.location = `/calendar/${order_id}/upload`;
      }
    });
  };
  renderUploadedImages() {
    if (this.state.images.length != 0) {
      return (
        <div className="mx-3">
          <h6>Reference photos 參考圖片</h6>
          {this.state.images.map(a => (
            <div style={{ display: "inline-block" }}>
              <a href={_.get(a, "image")} target="_blank">
                <img
                  className="mr-1"
                  src={_.get(a, "image")}
                  style={{ height: 120 }}
                />
              </a>
              <div style={{ display: "inline-block" }}>
                Remark - {_.get(a, "remarks", "No remark")}
              </div>
              <button
                id={_.get(a, "id")}
                name={_.get(a, "order_id")}
                onClick={e => {
                  this.deletePhoto(e.target.name, e.target.id);
                }}
                className="mx-3 btn btn-danger"
              >
                DELETE
              </button>
            </div>
          ))}
        </div>
      );
    }
  }
  deletePhoto = (orderId, id) => {
    API.delete(`/order/images/${id}/${orderId}/`).then(r => {
      if (r.status == 204) window.location = `/calendar/${orderId}/upload`;
      else alert("Some error occurred. Please try again later");
    });
  };
  componentDidMount() {
    this.setState({ loading: true });
    const order_id = _.get(this.props, "match.params.order_id", null);
    API.get(`/admin/order/images/${order_id}/`).then(r => {
      //fetching the already uploaded images
      if (r.status == 200)
        this.setState({ images: r.data.success, loading: false });
      else this.setState({ loading: false });
    });
  }
  render() {
    if (this.state.loading) return <div>Loading... </div>;
    return (
      <div className="m-3">
        {this.renderUploadedImages()}
        {this.renderUploadField()}
      </div>
    );
  }
}

export default SchedulerUploadImage;
