/**
 * Created by erikccoder on 18/5/2018.
 */
import React from "react";
import Comment from "react-html-comment";
import _ from "lodash";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import LabelGroup from "../../components/LabelGroup";

import { Button } from "semantic-ui-react";

import API from "../../api";
import { getLoadingOrFail } from "../../helper";
import moment from "moment/moment";

import "./ClientDetails.css";

class ClientDetails extends React.Component {
  state = {
    firstLoad: false,
    summary: null,
    error: null,
    hoursResponse: null
  };

  componentDidMount() {
    const { client_id } = this.props.match.params;

    API.get(`/clients/${client_id}`)
      .then(r => r.data)
      .then(client_details => {
        this.setState({ firstLoad: true });
        this.props.SetClientDetails({ client_id, client_details });
      })
      .catch(error => this.setState({ firstLoad: true, error: error }));

    API.get(`/clients/${client_id}/summary`)
      .then(r => r.data)
      .then(summary => this.setState({ summary }))
      .catch(error => this.setState({ error: error }));

    API.get(`/orders/hours-left/${client_id}`)
      .then(r => r.data)
      .then(response => this.setState({ hoursResponse: response }))
      .catch(error => this.setState({ error: error }));
  }
  renderMonthlyBookingHours = () => {
    return (
      <div>
        <div>
          <label style={{ display: "inline-block" }}>
            {" "}
            Monthly Plan Allowed Hours{" "}
          </label>
          : {_.get(this.state, "hoursResponse.hoursAllowed", "N/A")}
        </div>
        <div>
          <label style={{ display: "inline-block" }}>
            {" "}
            Hours Booked This Cycle{" "}
          </label>
          : {_.get(this.state, "hoursResponse.hoursBookedThisMonth", "N/A")}
        </div>
        <div>
          <label style={{ display: "inline-block" }}>
            {" "}
            Hold Hours For Current Cycle (should be 0){" "}
          </label>
          : {_.get(this.state, "hoursResponse.holdHoursThisMonth", "N/A")}
        </div>
        <div>
          <label style={{ display: "inline-block" }}>
            {" "}
            Future Hold Hours For The Next Cycle{" "}
          </label>
          : {_.get(this.state, "hoursResponse.allFutureHoldHours", "N/A")}
        </div>
        <div>
          <label style={{ display: "inline-block" }}>
            {" "}
            Start of month of subscription{" "}
          </label>
          : {_.get(this.state, "hoursResponse.startOfMonthForClient", "N/A")}
        </div>
      </div>
    );
  };
  deleteClient = () => {
    const answer = window.confirm(
      "Are you sure you want to delete the Client?"
    );
    if (answer == true) {
      const { client_id } = this.props.match.params;
      API.delete(`/clients/${client_id}`)
        .then(r => (window.location = `/clients`))
        .catch(error => this.setState({ error }));
    }
  };

  syncStripeSubscription = () => {
    if (
      window.confirm("Are you sure you want to sync stripe subscription time?")
    ) {
      const { client_id } = this.props.match.params;
      API.post(`/clients/${client_id}/subscription/sync`)
        .then(r => {
          window.location.reload(false);
        })
        .catch(error => this.setState({ error }));
    }
  };

  renderButlerExclusions() {
    const { client_id } = this.props.match.params;
    const client = _.get(this.props, `clients.details.${client_id}`);
    const butex = _.get(client, "butler_exclusions");

    if (!butex || butex.length == 0) {
      return <LabelGroup label="Butler Exclusions" text="N/A" />;
    }
    return (
      <div>
        <LabelGroup titleOnly="true" label="Butler Exclusions" />
        <ul className="list-group">
          {butex.map(x => (
            <li className="list-group-item">{x.full_name}</li>
          ))}
        </ul>
      </div>
    );
  }

  renderFavButler() {
    const { client_id } = this.props.match.params;
    const client = _.get(this.props, `clients.details.${client_id}`);
    const butex = _.get(client, "liked_butlers");

    if (!butex || butex.length == 0) {
      return <LabelGroup label="Favourite Butler" text="N/A" />;
    }
    return (
      <div>
        <LabelGroup label="Favourite Butler" titleOnly="true" />
        <ul className="list-group">
          {butex.map(x => (
            <li className="list-group-item">{x.full_name}</li>
          ))}
        </ul>
      </div>
    );
  }

  renderRemarks() {
    const { client_id } = this.props.match.params;
    const client = _.get(this.props, `clients.details.${client_id}`);
    const remarks = _.get(client, "remarks");

    if (!remarks || remarks.length == 0) {
      return <LabelGroup label="Remarks" titleOnly="true" text="N/A" />;
    }
    console.log(remarks);
    return (
      <div>
        {remarks.map((x, i) => {
          if (x.type === "SPECIAL_TOUCH") {
            return (
              <div>
                SPECIAL_TOUCH :{" "}
                {x.remark.split("\n").map(t => {
                  return <div>{t}</div>;
                })}
              </div>
            );
          } else if (x.type === "ENTRY_METHOD") {
            return (
              <div>
                ENTRY METHOD :{" "}
                {x.remark.split("\n").map(t => {
                  return <div>{t}</div>;
                })}
              </div>
            );
          } else if (x.type === "NORMAL") {
            return (
              <div>
                NORMAL :{" "}
                {x.remark.split("\n").map(t => {
                  return <div>{t}</div>;
                })}
              </div>
            );
          } else if (x.type === "EQUIPMENTS_AND_PRODUCTS_LOCATION") {
            return (
              <div>
                EQUIPMENTS_AND_PRODUCTS_LOCATION :{" "}
                {x.remark.split("\n").map(t => {
                  return <div>{t}</div>;
                })}
              </div>
            );
          } else {
            return (
              <div>
                {x.type} :{" "}
                {x.remark.split("\n").map(t => {
                  return <div>{t}</div>;
                })}
              </div>
            );
          }
        })}
      </div>
      // <div>
      //   {remarks.map(
      //     (x, i) =>
      //       x.type === "SPECIAL_TOUCH"
      //       ?
      //        <div><div>ENTRY_METHOD</div> <p> {x.remark.split("\n").map(t => { return <p>{t}</p>; })} </p> </div>
      //       : ( x.type ==="ENTRY_METHOD" ? <p> {x.remark.split("\n").map(t => { return <p>{t}</p>; }) } </p> :
      //         <p>
      //           {x.remark.split("\n").map(t => {
      //             return <p>{t}</p>;
      //           })}
      //         </p>
      //       )
      //   )}
      // </div>
    );
  }

  renderSummary() {
    const { summary } = this.state;
    if (summary == null) return null;

    const deposit = _.get(summary, "deposit", "N/A");
    const totalRevenue = _.get(summary, "total_revenue", "N/A");
    const credit = _.get(summary, "credit", "0");
    const outstanding = _.get(summary, "outstanding", "N/A");
    const totalHours = _.get(summary, "total_hours.hours", "N/A");
    const taskCounts = _.get(summary, "taskSummary", []);
    console.log(this.state);
    return (
      <div className="py-3">
        <div className="sr-only">
          <h5>Deposit</h5>
          <p>{!deposit && 0}</p>
        </div>
        <div>
          <h5>Total Revenue</h5>
          <p>{!totalRevenue && 0}</p>
        </div>
        <div>
          <h5>Total Credits</h5>
          <p>{credit}</p>
        </div>
        <div>
          <h5>Deposit</h5>
          <p>{this.state.summary.deposit}</p>
        </div>
        <div>
          <h5>Outstanding</h5>
          <p>{outstanding}</p>
        </div>
        <div>
          <h5>Total Hours</h5>
          <p>{totalHours}</p>
        </div>
        <div>
          <h5>Task Summary</h5>
          {taskCounts.map((taskCount, i) => (
            <p key={`taskCount_${i}`}>
              {taskCount.type + ":" + taskCount.count}
            </p>
          ))}
        </div>
      </div>
    );
  }

  renderOrders() {
    const { summary } = this.state;
    if (!summary) {
      return null;
    }

    const { comingOrders } = summary;

    return (
      <div>
        <LabelGroup label="Orders" titleOnly="true" />
        {comingOrders
          ?.sort((a, b) => a.start_timestamp - b.start_timestamp)
          .map((order, i) => (
            <div key={`comingOrders_${i}`} className="card mb-3">
              <Comment
                text={`Order: #${order.id}, url: ${window.location.href}/orders/${order.id}`}
              />
              <div className="card-header debug">
                <a href={`/orders/${order.id}`}>{`#${order.id}`}</a>
              </div>
              <div className="card-body">
                <h6 className="card-title">{order.status}</h6>
                <p className="card-text">
                  {moment(order.end_timestamp).diff(
                    moment(order.start_timestamp),
                    "hours"
                  ) + " hours sessions"}{" "}
                </p>
                <p className="card-text">
                  {moment(order.start_timestamp).format("DD/MM/YYYY ha")} -{" "}
                  {moment(order.end_timestamp).format("ha")}{" "}
                </p>
                <p className="card-text">{order.butler.user.full_name} </p>

                {order.tasks.map((t, i) => {
                  return (
                    <p className="card-text" key={`task_${i}`}>
                      {t.type}
                    </p>
                  );
                })}
              </div>
            </div>
          )) ?? "N/A"}
      </div>
    );
  }

  render() {
    const { client_id } = this.props.match.params;

    const client = _.get(this.props, `clients.details.${client_id}`);

    const fail = getLoadingOrFail(this.state);

    if (fail) {
      return fail;
    }

    const clientName = _.get(client, "full_name", "N/A");
    const clientEmail = _.get(client, "email", "N/A");
    const clientContactNo = _.get(client, "contact_no", "N/A");
    const clientAddress = _.get(client, "address", "N/A");
    const clientDistrict = _.get(client, "district", "N/A");
    const clientBirthday = _.get(client, "birthday", "N/A");
    const butlerHKID = _.get(client, "hkid", "N/A");
    const butlerAccountNo = _.get(client, "bank_account", "N/A");

    const profileImageUrl = _.get(client, "user.profileImageUrl", null);

    const clientMembership = _.get(client, "membership.type", "N/A");
    const clientMembershipStart = _.get(client, "", "N/A");
    const clientMembershipEnd = _.get(client, "", "N/A");
    const clientMembershipPause = _.get(client, "", "N/A");
    const clientMembershipLockup = _.get(client, "", "N/A");

    const entryMethod = _.get(client, "entry_method", "N/A");

    const attachments = _.get(client, "reference_photos", []);

    const aurl = attachments.map(function(attachment) {
      return _.get(attachment, "image_url", null);
    });

    return (
      <div>
        <div className="ui padded middle aligned grid bg-white border-bottom">
          <div className="two wide column">
            <Button
              as={Link}
              size="tiny"
              to="/clients"
              color="orange"
              className="bg-red"
            >
              GO BACK
            </Button>
          </div>
        </div>
        <div className="container-fluid k-table py-3">
          <div className="row py-3">
            <div className="col">
              <LabelGroup
                label="客人（Client）"
                src={
                  profileImageUrl
                    ? profileImageUrl
                    : "https://dummyimage.com/50x50/c9743e/dd9c3c.jpg"
                }
                text={clientName}
              />

              <LabelGroup label="Membership" text={clientMembership} />

              <div className="my-2">
                <LabelGroup
                  className="d-inline-block mr-4"
                  label="Membership start date"
                  text={clientMembershipStart}
                />

                <LabelGroup
                  className="d-inline-block"
                  label="Membership end date"
                  text={clientMembershipEnd}
                />
              </div>
              <div className="my-2">
                <LabelGroup
                  className="d-inline-block mr-4"
                  label="Pause Membership"
                  text={clientMembershipPause}
                />

                <LabelGroup
                  className="d-inline-block"
                  label="Lockup period"
                  text={clientMembershipLockup}
                />
              </div>

              <LabelGroup label="Email" text={clientEmail} />

              <LabelGroup label="Contact number" text={clientContactNo} />
              <LabelGroup
                label="Birthday"
                text={moment(clientBirthday).format("DD-MM-YYYY")}
              />
              <LabelGroup label="Address" text={clientAddress} />
              <LabelGroup label="District" text={clientDistrict} />

              <div>{this.renderButlerExclusions()}</div>

              <LabelGroup label="入屋方法（Entry Method）" text={entryMethod} />

              <div>{this.renderRemarks()}</div>
              <div>{this.renderMonthlyBookingHours()}</div>

              <div className="my-3 mx-2">
                <a
                  className="btn btn-primary btn-block"
                  href={`/clients/${client_id}/add-remarks`}
                >
                  Add / Edit Remarks
                </a>
              </div>

              <div>
                <LabelGroup
                  label="參考相片（Reference Photo）"
                  titleOnly="true"
                />

                {aurl.map(function(url) {
                  if (url == null) return null;
                  else
                    return (
                      <a href={url} target="_blank">
                        <img style={{ width: "100%" }} alt="demo" src={url} />
                      </a>
                    );
                })}

                <a
                  className="btn btn-success"
                  href={`/clients/${client_id}/referencePhotos`}
                >
                  Add/Edit Reference Photos
                </a>
              </div>
            </div>

            <div className="col">
              {this.renderSummary()}

              {this.renderFavButler()}

              {this.renderOrders()}

              <a
                className="btn btn-success"
                href={`/clients/${client_id}/deposit`}
              >
                View Client Deposit Records
              </a>
              <br />
              <br />
              <a
                className="btn btn-primary"
                href={`/clients/${client_id}/edit`}
              >
                EDIT Client Information
              </a>
              <br />
              <br />
              <a className="btn btn-danger white" onClick={this.deleteClient}>
                Delete Client Information
              </a>
              <br />
              <br />
              <a
                className="btn btn-primary white"
                href={`/clients/${client_id}/upgrade`}
              >
                Upgrade Client
              </a>
              <br />
              <br />
              <a
                className="btn btn-warning white invisible"
                onClick={this.syncStripeSubscription}
              >
                Sync Stripe Subscription
              </a>
            </div>
          </div>

          {/*<pre>{JSON.stringify(client, null, '\t')}</pre>*/}
        </div>
      </div>
    );
  }
}

const ClientDetailsConnected = connect(
  ({ clients }) => ({ clients }),
  dispatch => ({
    SetClientDetails: ({ client_id, client_details }) => {
      dispatch({ type: "SET_CLIENTS_DETAILS", client_id, client_details });
    }
  })
)(ClientDetails);

export default ClientDetailsConnected;
