import React from "react";
import moment from "moment";
import { Grid } from "semantic-ui-react";
import LabelGroup from "../../components/LabelGroup";
import API from "../../api";

class ButlerAllFeeDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: undefined,
      loading: true,
      type: undefined,
      month: undefined,
      submitting: false
    };
  }
  componentWillMount() {
    API.get(`/admin/butler/allfees/${this.props.match.params.id}`)
      .then(r => {
        this.setState({
          amount: r.data.amount,
          type: r.data.type,
          month: r.data.month,
          loading: false
        });
      })
      .catch(err => {
        this.setState({ loading: false });
        alert(err);
      });
  }
  handleSubmit = () => {
    this.setState({ submitting: true });
    const year = moment().year();
    const month = moment().month() + 1;
    API.delete(`/admin/butler/allfees/${this.props.match.params.id}`)
      .then(r => {
        this.setState({ submitting: false });
        window.location = `/butlers/allfees/${year}/${month}`;
      })
      .catch(err => {
        this.setState({ submitting: false });
        alert(err);
      });
  };
  render() {
    if (this.state.loading) return <div> Loading </div>;
    return (
      <div>
        <Grid columns={4}>
          <Grid.Column
            as={LabelGroup}
            label="Amount"
            text={this.state.amount}
          />

          <Grid.Column as={LabelGroup} label="Type" text={this.state.type} />
          <Grid.Column as={LabelGroup} label="Month" text={this.state.month} />
        </Grid>
        <a href="#" className="m-3 btn btn-danger" onClick={this.handleSubmit}>
          {this.state.submitting ? "Deleting" : "Delete"}
        </a>
      </div>
    );
  }
}
export default ButlerAllFeeDetails;
