import React from 'react'
import ClientForm from './ClientForm'
import { Button, Form, Label, Input, TextArea } from "semantic-ui-react";
import API from "../../api";
import ClientSummary from './ClientSummary'
import ClientSummaryOrder from './ClientSummaryOrder'

class EditClientDetails extends React.Component
{ 
  constructor(props){
    super(props)
    this.state = {
      client:null,
      summary:null
    }
  }

  componentDidMount(){
    const { client_id } = this.props.match.params;
    console.log('client_id',client_id)
    this.client_id = client_id

    API.get('/clients/'+this.client_id)
      .then((response)=>{
        let data = response.data
        console.log('resp',data)
        this.form.setFormDataByApiFormat(data)
        this.setState({client:data})
      }).catch((error)=>{

      })

    API.get('/clients/'+this.client_id+'/summary')
      .then((response)=>{
        let data = response.data
        console.log('summary',data)
        this.setState({summary:data})
      }).catch((error)=>{

      })
  }

  saveBtnOnClick(e){
    e.preventDefault()
    const data = this.form.getFormDataByApiEditFormat()
    API.put('/clients/'+this.client_id,data)
      .then((response)=>{
        console.log('succ',response)
        window.location.href = "/clients";
      }).catch((error)=>{
        console.log('err',error)
      })
    console.log('edit btn',data, JSON.stringify(data))
  }

  render(){
    const {summary,client} = this.state
    return (
      <form className="custom_form">
        <div className="container-fluid k-table py-3">
          <div className="bg-white border-bottom p-3 row">
            <Button
              size="tiny"
              href="/clients"
              color="orange"
              className="bg-red"
            >
              GO BACK
            </Button>
          </div>
          <div className="s_cardbox">
            <div className="row">
              <div className="col-sm-6" style={{borderRightWidth:1,borderRightColor:'#dadada',borderRightStyle:'solid'}}>
                <div style={{height:'100%'}}>
                  <ClientForm 
                    isEdit
                    ref={(form)=>{this.form=form}}
                  />
                </div>
              </div>
              <div className="col-sm-6" style={{paddingLeft:0}}>
                <div style={{paddingLeft:15}}>
                  <ClientSummary summary={summary} client_id={this.client_id} client={client}/>
                </div>
                <div className="s_x_divider">&nbsp;</div>
                <div style={{paddingLeft:15}}>
                  <ClientSummaryOrder summary={summary}/>
                </div>
              </div>
            </div>
            
            <div className="s_x_divider">&nbsp;</div>
            
            <div className="s_row s_flex">
              <Button
                onClick={this.saveBtnOnClick.bind(this)}
                size="tiny"
                color="orange"
                className="bg-red"
              >
                Update
              </Button>
              <Button
                href="/clients"
                size="tiny"
                color="black"
                className="bg-md-grey"
                style={{color:'black'}}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </form>
    )
  }

}

export default EditClientDetails
