import React from "react";
import _ from "lodash";
import { connect } from "react-redux";

import ClientToolbar from "./ClientToolbar";
import SimpleTable from "../../components/SimpleTable";
import API from "../../api";
const ObjectsToCsv = require("objects-to-csv");
// const HeaderCells = ["Name", "Membership", "Membership Start",
// 	"Membership End", "Contact No.", "Orders", "District", "Total Revenue", "Outstanding"];

const headers = [
  {
    text: "Name",
    key: "full_name"
  },
  {
    text: "Membership",
    key: "membership"
  },
  {
    text: "Membership Start",
    key: "memberhip_start"
  },
  {
    text: "Membership End",
    key: "memberhip_end"
  },
  {
    text: "Contact No.",
    key: "contact_no"
  },
  {
    text: "Orders",
    key: "orders.length"
  },
  {
    text: "District",
    key: "district"
  },
  {
    text: "Total Revenue",
    key: "total_revenue"
  },
  {
    text: "Outstanding",
    key: "outstanding"
  },
  {
    text: "Join Date",
    key: "created_at"
  }
];

class Clients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKWord: "",
      firstLoad: false,
      error: null,

      sortDirection: null,
      sortColumn: null
    };

    API.get("/clients")
      .then(r => r.data)
      // .then( butlers => this.setState({butlers}))
      .then(clients => this.props.SetClientList(clients))
      .then(firstLoad => this.setState({ firstLoad: true }))
      .catch(error => this.setState({ firstLoad: true, error: error }));
  }

  headerOnClickForSort({ text, key }) {
    console.log(text, key, "text and key");
    const { sortDirection, sortColumn } = this.state;
    if (sortColumn == key) {
      this.setState({
        sortColumn: key,
        sortDirection:
          sortDirection === "ascending" ? "descending" : "ascending"
      });
    } else {
      this.setState({
        sortColumn: key,
        sortDirection: "ascending"
      });
    }
  }
  export = () => {
    let clients = this.props.clients.list;
    if (
      clients &&
      clients[0] &&
      clients[0].membership &&
      clients[0].membership.type
    ) {
      clients = clients.map(function(client) {
        client.membership = _.get(client, "membership.type", "MEMBER");
        return client;
      });
    } else {
      clients = clients.map(function(client) {
        client.membership = _.get(client, "membership", "MEMBER");
        return client;
      });
    }
    (async () => {
      let csv = new ObjectsToCsv(clients);
      csv = await csv.toString();
      const url = window.URL.createObjectURL(new Blob([csv]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "clients" + ".csv");
      document.body.appendChild(link);
      link.click();
    })();
  };

  render() {
    // const {
    // 	butlers
    // } = this.state;

    const {
      searchKWord,
      firstLoad,
      error,
      sortDirection,
      sortColumn
    } = this.state;

    if (!firstLoad) {
      return (
        <div className="w-100 h-100 p-5 d-inline-block">
          <h1 className="text-center align-middle">Loading...</h1>
        </div>
      );
    } else if (error) {
      return <pre>{JSON.stringify(error, null, "\t")}</pre>;
    }
    const newSearchKWord = searchKWord.replace(/[^a-z0-9]/g, "");

    let clients = this.props.clients.list.filter(c => {
      if (
        newSearchKWord == "" ||
        _.get(c, "full_name", "")
          .toLowerCase()
          .search(newSearchKWord.toLowerCase()) > -1
      ) {
        return true;
      }
      if (_.get(c, "contact_no", "") != null)
        if (
          newSearchKWord == "" ||
          _.get(c, "contact_no", "").search(newSearchKWord.toLowerCase()) > -1
        ) {
          return true;
        }
      return false;
    });

    // return (<pre>{JSON.stringify(clients, null, '\t')}</pre>)
    // console.log(clients, 'client')
    if (
      clients &&
      clients[0] &&
      clients[0].membership &&
      clients[0].membership.type
    ) {
      clients = clients.map(function(client) {
        client.membership = _.get(client, "membership.type", "MEMBER");
        return client;
      });
    } else {
      clients = clients.map(function(client) {
        client.membership = _.get(client, "membership", "MEMBER");
        return client;
      });
    }
    console.log({ clients });

    clients = _.sortBy(clients, [sortColumn]);
    if (sortDirection == "descending") {
      clients = clients.reverse();
    }

    return (
      <div>
        <ClientToolbar
          setSearchWords={searchKWord => this.setState({ searchKWord })}
          export={this.export}
        />

        <div className="mx-2 my-3" style={{ overflow: "scroll" }}>
          <SimpleTable
            theader={headers}
            tbody={clients}
            baseURL="/clients/:key"
            key_link_to="id"
            direction={sortDirection}
            column={sortColumn}
            headerOnClickForSort={this.headerOnClickForSort.bind(this)}
          />
        </div>

        {/*<pre>{JSON.stringify(this.props.clients, null, '\t')}</pre>*/}
      </div>
    );
  }
}

const ClientsConnected = connect(
  ({ clients }) => ({ clients }),
  dispatch => ({
    SetClientList: clients_list => {
      dispatch({ type: "SET_CLIENTS_LIST", clients_list });
    }
  })
)(Clients);

export default ClientsConnected;
