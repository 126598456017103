/**
 * Created by erikccoder on 21/5/2018.
 */
import React from "react";
import API from "../../api";
import { getLoadingOrFail } from "../../helper";

class DiscountDetails extends React.Component {
  state = {
    discount_details: null,
    firstLoad: false,
    error: null
  };

  componentWillMount() {
    console.log(this.props.match.params);
    const { id } = this.props.match.params;

    API.get(`/discount/getById/${id}`)
      .then(res => {
        console.log(res.data);
        this.setState({ discount_details: res.data, firstLoad: true });
      })
      .catch(error => this.setState({ error, firstLoad: true }));
  }

  delete = () => {
    const { id } = this.props.match.params;
    API.post(`/discount/invalidate/${id}`)
      .then(res => {
        if (res.data) {
          window.location = "/discount";
        }
      })
      .catch(error => this.setState({ error, firstLoad: true }));
  };
  renderDiscountRedemptions = () => {
    return this.state.discount_details.map(discountDetails => {
      return discountDetails && discountDetails.order_id ? (
        <div>
          {" "}
          Used in order number {discountDetails.order_id} by client{" "}
          {discountDetails.client.user.full_name} (
          {discountDetails.client.user.email}){" "}
        </div>
      ) : (
        <div>
          <div>Not redeemed yet</div>
        </div>
      );
    });
  };
  render() {
    const fail = getLoadingOrFail(this.state);
    if (fail) {
      return fail;
    }
    if (!this.state.firstLoad) return <div>Loading....</div>;
    return (
      <div>
        <h1>Discount Code Details</h1>
        <div>
          <h3> Redemption Details </h3>

          {this.renderDiscountRedemptions()}
          <a
            href="#"
            className="btn btn-danger btn-block"
            onClick={this.delete}
          >
            Invalidate this discount code
          </a>
        </div>
      </div>
    );
  }
}

export default DiscountDetails;
