/**
 * Created by erikccoder on 21/5/2018.
 */

import React from "react";
import moment from "moment";
import _ from "lodash";
import SimpleTable from "../../components/SimpleTable";
import API from "../../api";
import { getLoadingOrFail } from "../../helper";
import DateRangePicer from "../../components/DateRangePicer";

class InvoiceList extends React.Component {
  state = {
    credits: [],
    firstLoad: false,
    error: null,
    sortDirection: null,
    sortColumn: null
  };

  componentWillMount() {
    const search = _.chain(this.props.location.search)
      .replace("?", "") // a=b454&c=dhjjh&f=g6hksdfjlksd
      .split("&") // ["a=b454","c=dhjjh","f=g6hksdfjlksd"]
      .map(_.partial(_.split, _, "=", 2)) // [["a","b454"],["c","dhjjh"],["f","g6hksdfjlksd"]]
      .fromPairs() // {"a":"b454","c":"dhjjh","f":"g6hksdfjlksd"}
      .value();

    const { start, end } = search;

    API.get(`/client-credits`, { params: { start, end } })
      .then(r => r.data)
      .then(credits => this.setState({ credits, firstLoad: true }))
      .catch(error => this.setState({ error, firstLoad: true }));
  }

  handleDateRange = ({ startDate, endDate }) => {
    if (!startDate || !endDate) {
      return;
    }
    const search_params = `?start=${startDate.format(
      "YYYY-MM-DD"
    )}&end=${endDate.format("YYYY-MM-DD")}`;
    window.location = `/credits${search_params}`;
  };

  headerOnClickForSort({ text, key }) {
    if (key !== "remark") return;
    console.log(text, key, "text and key");

    const { sortDirection, sortColumn } = this.state;
    if (sortColumn == "remark") {
      this.setState({
        sortColumn: key,
        sortDirection:
          sortDirection === "ascending" ? "descending" : "ascending"
      });
    } else {
      this.setState({
        sortColumn: key,
        sortDirection: "ascending"
      });
    }
  }

  render() {
    const { sortDirection, sortColumn } = this.state;
    const fail = getLoadingOrFail(this.state);
    if (fail) {
      return fail;
    }

    // For the key value, remark is representing the status of the record, and remark_user is the free text remark in the CreditGive page
    // Reason for confusing naming is because of previously the "remark" key name has been taken by "status", so the real remark has to use other name
    const headers = [
      {
        text: "Date Given",
        key: "created_at"
      },
      {
        text: "Client Name",
        key: "client.full_name"
      },
      {
        text: "Credits Given",
        key: "amount"
      },
      {
        text: "Status",
        key: "remark"
      },
      {
        text: "Total Credits",
        key: "balance"
      },
      {
        text: "Remarks",
        key: "remark_user"
      }
    ];

    let credits = this.state.credits;

    // Only sort by status
    credits = _.sortBy(credits, ["remark"]);
    if (sortDirection == "descending") {
      credits = credits.reverse();
    }

    return (
      <div>
        <h1>Credits List</h1>

        <div className="mx-2">
          <DateRangePicer onChange={this.handleDateRange} />
        </div>

        <div className="my-3 mx-2">
          <a href="/credits/give" className="btn btn-danger">
            Give client value
          </a>
        </div>

        <SimpleTable
          className="mx-2"
          theader={headers}
          // Show sorted result if status is sorted else show original result
          tbody={sortDirection ? credits : this.state.credits}
          direction={sortDirection}
          column={sortColumn}
          headerOnClickForSort={this.headerOnClickForSort.bind(this)}
        />
        {/*<pre>{JSON.stringify(this.state.credits, null, '\t')}</pre>*/}
      </div>
    );
  }
}

export default InvoiceList;
