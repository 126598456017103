import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import LabelGroup from "../../components/LabelGroup";

import { Button, Image } from "semantic-ui-react";

import API from "../../api";
import { getLoadingOrFail } from "../../helper";

class ButlerEditor extends React.Component {
  state = {
    firstLoad: false,
    error: null,
    summary: null
  };

  componentWillMount() {
    const { butler_id } = this.props.match.params;

    API.get(`/butlers/${butler_id}`)
      .then(r => r.data)
      .then(butler_details => {
        this.setState({ firstLoad: true });
        this.props.SetButlerDetails({ butler_id, butler_details });
      })
      .catch(error => this.setState({ firstLoad: true, error: error }));

    API.get(`/butlers/${butler_id}/summary`)
      .then(r => r.data)
      .then(summary => this.setState({ summary }))
      .catch(error => this.setState({ error: error }));
  }

  deleteButler = () => {
    const answer = window.confirm(
      "Are you sure you want to delete the Butler?"
    );
    if (answer == true) {
      const { butler_id } = this.props.match.params;

      API.delete(`/butlers/${butler_id}`)
        .then(r => (window.location = `/butlers`))
        .catch(error => this.setState({ error: error }));
    }
  };

  renderSummary() {
    const { summary } = this.state;
    if (summary == null) return null;
    const { butler_id } = this.props.match.params;
    const butler = _.get(this.props, `butlers.details.${butler_id}`);
    const summaryRating = _.get(butler, "butlerRatings", "0");
    console.log(summaryRating);
    const badges = _.get(butler, "badges", "N/A");
    const totalRevenue = _.get(summary, "total_revenue", "N/A");
    const totalHours = _.get(summary, "total_hours.hours", "N/A");
    const taskCounts = _.get(summary, "taskSummary", []);
    const deposit = _.get(summary, "deposit", "0");
    return (
      <div className="py-3">
        <div>
          <h5>Average Rating</h5>
          <p>{summaryRating}</p>
        </div>
        <br />
        <div>
          <h5>Badges</h5>
          {Object.values(badges).map(badge => {
            return (
              <div className="badges">
                {badge.name} : {badge.count}
                {/* <Image height='50px' src={badge.image}/> */}
              </div>
            );
          })}
        </div>
        <br />
        <div>
          <h5>Remaining Deposit</h5>
          <p>{deposit}</p>
        </div>

        <div>
          <h5>Total Revenue</h5>
          <p>{totalRevenue}</p>
        </div>
        <div>
          <h5>Total Hours</h5>
          <p>{totalHours}</p>
        </div>
        <div>
          <h5>Task Summary</h5>
          {taskCounts.map(function(taskCount) {
            return <p>{taskCount.type + ":" + taskCount.count}</p>;
          })}
        </div>
      </div>
    );
  }

  render() {
    const { butler_id } = this.props.match.params;
    const butler = _.get(this.props, `butlers.details.${butler_id}`);
    const fail = getLoadingOrFail(this.state);
    if (fail) {
      return fail;
    }
    const butlerName = _.get(butler, "full_name", "N/A");
    const butlerEmail = _.get(butler, "email", "N/A");
    const butlerContactNo = _.get(butler, "contact_no", "N/A");
    const butlerAddress = _.get(butler, "address", "N/A");
    const butlerHKID = _.get(butler, "hkid", "N/A");
    const butlerAccountNo = _.get(butler, "bank_account", "N/A");
    const comfort_with = _.get(butler, "comfort_with", []);
    const comfort_with_item = comfort_with.map(function(comfortItem) {
      return comfortItem.comfort;
    });
    const is_petSitter =
      comfort_with_item.includes("PET_SITTING") == true ? true : false;

    const joinDate = _.get(butler, "join_date", "N/A");
    const referrals = _.get(butler, "referred_butlers", []);
    const is_referral = referrals.length > 0 ? true : false;
    const gapTime = _.get(butler, "gap_time", "N/A");
    const guaranteedHours = _.get(butler, "guaranteed_hours", "N/A");
    const maxWorkingHours = _.get(butler, "max_working_hours", "N/A");
    const octopusDeposit = "N/A";
    const hour_rate = _.get(butler, "hour_rate", "N/A");
    // const butlerDistrict = _.get(butler, 'client.district', 'N/A');
    // const butlerEntryMethod = _.get(butler, 'client.entry_method', 'N/A');

    return (
      <div className="container-fluid k-table py-3">
        <div className="bg-white border-bottom p-3 row">
          <Button
            as={Link}
            size="tiny"
            to="/butlers"
            color="orange"
            className="bg-red"
          >
            GO BACK
          </Button>
        </div>

        <div className="row">
          <div className="col">
            <LabelGroup label="Butler" text={butlerName} />
            <LabelGroup label="Join Date" text={joinDate} />

            <div className="form-group sr-only">
              <label htmlFor="inReferral">Referral</label>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inReferral"
                  id="inReferralYes"
                  value="true"
                  checked={is_referral}
                />
                <label className="form-check-label" htmlFor="inReferralYes">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inReferral"
                  id="inReferralNo"
                  value="false"
                  checked={!is_referral}
                />
                <label className="form-check-label" htmlFor="inReferralNo">
                  No
                </label>
              </div>
            </div>

            {false &&
              referrals.map(function(referredButler) {
                return (
                  <LabelGroup
                    label="Referred Butlers"
                    text={referredButler.referred_butler_name}
                  />
                );
              })}

            <LabelGroup label="HKID" text={butlerHKID} />

            <LabelGroup label="Bank account" text={butlerAccountNo} />

            <LabelGroup label="Email" text={butlerEmail} />

            <LabelGroup label="Contact number" text={butlerContactNo} />
            <LabelGroup label="Address" text={butlerAddress} />
            {/*<LabelGroup label="Rating" text={avRatings}/>*/}
            <div className="my-2">
              <LabelGroup
                className="d-inline-block mr-4"
                label="Dogs"
                text={
                  comfort_with_item.includes("DOGS") == true
                    ? "Okay"
                    : "Not Okay"
                }
              />
              <LabelGroup
                className="d-inline-block mr-4"
                label="Cats"
                text={
                  comfort_with_item.includes("CATS") == true
                    ? "Okay"
                    : "Not Okay"
                }
              />
              <LabelGroup
                className="d-inline-block"
                label="Others"
                text={
                  comfort_with_item.includes("OTHERS") == true
                    ? "Okay"
                    : "Not Okay"
                }
              />
            </div>

            <div className="my-2">
              <LabelGroup
                className="d-inline-block"
                label="Cooking"
                text={
                  comfort_with_item.includes("COOKING") == true
                    ? "Okay"
                    : "Not Okay"
                }
              />
            </div>

            <div className="my-2">
              <LabelGroup
                className="d-inline-block mr-4"
                label="Gap Time"
                text={gapTime + " mins"}
              />

              <LabelGroup
                className="d-inline-block"
                label="Guaranteed Hours"
                text={guaranteedHours + " hours"}
              />
            </div>

            <div className="my-2">
              <LabelGroup
                className="d-inline-block mr-4"
                label="Max Working Hours"
                text={maxWorkingHours + " hours"}
              />
            </div>

            <div className="my-2">
              <LabelGroup
                className="d-inline-block mr-4"
                label="Per Hour Rate"
                text={"$ " + hour_rate}
              />
            </div>

            <div className="sr-only">
              <LabelGroup
                label="參考相片（Reference Photo）"
                titleOnly="true"
              />
              <img
                alt="demo"
                src="https://dummyimage.com/50x50/c9743e/dd9c3c.jpg"
              />
            </div>
          </div>

          <div className="col">
            {this.renderSummary()}

            <div className="mt-5">
              <a
                className="btn btn-primary"
                href={`/butlers/${butler_id}/edit-basic`}
              >
                {" "}
                EDIT BUTLER INFO
              </a>
            </div>

            <div className="mt-3">
              <a
                className="btn btn-primary"
                href={`/butlers/${butler_id}/districts`}
              >
                {" "}
                EDIT/VIEW DISTRICT{" "}
              </a>
            </div>

            <div className="my-3">
              <a
                className="btn btn-primary"
                href={`/butlers/${butler_id}/leaves`}
              >
                {" "}
                EDIT/VIEW LEAVES{" "}
              </a>
            </div>

            <div className="my-3">
              <a
                className="btn btn-primary"
                href={`/butlers/${butler_id}/referred_butlers`}
              >
                EDIT/VIEW REFERRED BUTLERS
              </a>
            </div>

            <div className="my-3">
              <a
                className="btn btn-primary"
                href={`/butlers/${butler_id}/schedule`}
              >
                {" "}
                EDIT/VIEW SCHEDULE{" "}
              </a>
            </div>
            <div className="my-3">
              <a className="btn btn-danger white" onClick={this.deleteButler}>
                {" "}
                DELETE BUTLER{" "}
              </a>
            </div>
          </div>
        </div>

        {/*<pre>{JSON.stringify(butler, null, '\t')}</pre>*/}
      </div>
    );
  }
}

const ButlerEditorConnected = connect(
  ({ butlers }) => ({ butlers }),
  dispatch => ({
    SetButlerDetails: ({ butler_id, butler_details }) => {
      dispatch({ type: "SET_BUTLER_DETAILS", butler_id, butler_details });
    }
  })
)(ButlerEditor);

export default ButlerEditorConnected;
