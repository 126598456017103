import _ from "lodash";
import moment from "moment";

import React from "react";
import { connect } from "react-redux";

import { Switch, Route, Link, Redirect, withRouter } from "react-router-dom";

import { Tab, Menu } from "semantic-ui-react";

import API from "../../api";

import ButlerList from "./ButlerList";
import ButlerEditor from "./ButlerEditor";
import ButlerSalaryList from "./ButlerSalaryList";
import ButlerSalaryDetails from "./ButlerSalaryDetails";
import ButlerAllFeeList from "./ButlerAllFeeList";
import ButlerComments from "./ButlerComments";

class Butlers extends React.Component {
  state = {
    activeIndex: 0
  };

  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });

  render() {
    console.log(this.state);
    const { match } = this.props;
    const { params } = match;

    const now = moment();

    let activeIndex = 1;
    switch (match.url) {
      case "/butlers":
        activeIndex = 0;
        break;
      case "/butlers/roaster":
        activeIndex = 2;
        break;
      case "/butler_ratings":
        activeIndex = 3;
        break;
    }

    const panes = [
      {
        menuItem: {
          key: "list",
          content: (
            <Link className="color-black" to="/butlers">
              BUTLER INFO
            </Link>
          )
        },
        render: () => <ButlerList />
      },
      {
        menuItem: {
          key: "salary",
          content: (
            <Link
              className="color-black"
              to={`/butlers/salary/${now.year()}/${now.month() + 1}`}
            >
              BUTLER SALARY
            </Link>
          )
        },
        render: () => <ButlerSalaryList {...params} />
      },
      {
        menuItem: {
          key: "allFees",
          content: (
            <Link
              className="color-black"
              to={`/butlers/allfees/${now.year()}/${now.month() + 1}`}
            >
              BUTLER ALL FEES
            </Link>
          )
        },
        render: () => <ButlerAllFeeList {...params} />
      }
    ];
    return (
      <Tab id="BUTLERS_TABS" panes={panes} onTabChange={this.handleTabChange} />
    );
  }
}

const ButlersConnected = connect(({ butlers }) => ({ butlers }))(Butlers);

const ButlerRoutes = props => (
  <Switch>
    <Route exact path="/butlers" component={ButlersConnected} />
    <Route exact path="/butlers/roaster" component={ButlersConnected} />
    <Route exact path="/butlers/:butler_id" component={ButlerEditor} />
    <Route path="/butlers/salary/:year/:month" component={ButlersConnected} />
    <Route
      exact
      path="/butlers/:butler_id/salary/:year/:month"
      component={ButlerSalaryDetails}
    />
    <Route
      exact
      path="/butlers/allfees/:year/:month"
      component={ButlersConnected}
    />
  </Switch>
);

export default ButlerRoutes;
