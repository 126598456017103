import React from "react";
import _ from "lodash";
import API from "../../api";
import { getLoadingOrFail } from "../../helper";
import DatePicker from "react-datepicker";
import TimePicker from "react-bootstrap-time-picker";

import { Dropdown } from "semantic-ui-react";

class AddWhatsNewForm extends React.Component {
  state = {
    title: undefined,
    text: undefined,
    file: undefined,
    submitting: null
  };

  handleSumbit = () => {
    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true });
    let { title, text, file } = this.state;
    let formData = new FormData();
    formData.append("title", title);
    formData.append("text", text);
    formData.append("fileUpload", file);

    API.post("/whatsnew/", formData)
      .then(r => r.data)
      .then(_ => {
        this.setState({ submitting: null });
        window.location = "/whatsnew";
      })
      .catch(error => this.setState({ submitting: null, error }));
  };
  _handleImageChange(e) {
    e.preventDefault();
    this.setState({ file: e.target.files[0] });
  }

  renderUploadField = () => {
    return (
      <div>
        <input
          className="fileInput"
          type="file"
          onChange={e => this._handleImageChange(e)}
        />
      </div>
    );
  };

  render() {
    return (
      <div className="p-1">
        <h1>New Whats New Form</h1>

        <div className="form-group m-3">
          <label htmlFor="title">Title</label>
          <input
            id="title"
            className="form-control"
            placeholder="Title"
            onChange={({ target: { value } }) =>
              this.setState({ title: value })
            }
          />
        </div>

        <div className="form-group m-3">
          <label htmlFor="m-bdy">Text</label>
          <textarea
            id="m-bdy"
            className="form-control"
            placeholder="Body"
            rows="5"
            onChange={({ target: { value } }) => this.setState({ text: value })}
          />
        </div>
        {this.renderUploadField()}
        <nav class="nav p-3" id="sumbit">
          <a
            className="nav-link btn-danger "
            href="#sumbit"
            onClick={this.handleSumbit}
          >
            {this.state.submitting ? "Submitting" : "Submit"}
          </a>
        </nav>
      </div>
    );
  }
}

export default AddWhatsNewForm;
