/**
 * Created by erikccoder on 18/6/2018.
 */
import React, { Component } from "react";
import _ from "lodash";
import API from "../../api";
import { getLoadingOrFail } from "../../helper";
import Select from "react-select";
import "react-select/dist/react-select.css";

const options = [
  { label: "入屋方法（Entry_Method）", value: "ENTRY_METHOD" },
  { label: "需要注意事項 (Remarks)", value: "NORMAL" },
  {
    label: "工具及用品位置 （Equipments and Products Location)",
    value: "EQUIPMENTS_AND_PRODUCTS_LOCATION"
  },
  {
    label: "客人一般要求重點 (General Priorities)",
    value: "GENERAL_PRIORITIES"
  },
  { label: "客人特別事項 （Special Touch)", value: "SPECIAL_TOUCH" },
  { label: "Custom", value: "CUSTOM" }
];
class ClientEditRemark extends Component {
  state = {
    firstLoad: false,
    error: null,
    selected_remark: {
      type: null,
      remark: null
    },
    customHeading: null,
    isCustom: false
  };

  putRemark = e => {
    e.preventDefault();

    const { selected_remark } = this.state;
    if (!selected_remark.remark || selected_remark.remark.length < 3) {
      return alert("Enter a valid remark");
    }
    const { client_id } = this.props.match.params;
    let body = { remarks: [{ ...selected_remark }] };
    if (this.state.isCustom) {
      body = {
        remarks: [
          {
            id: selected_remark.id,
            remark: selected_remark.remark,
            type: _.get(this.state, "customHeading", "NORMAL")
          }
        ]
      };
    }
    API.put(`/clients/${client_id}`, body)
      .then(_ => (window.location = `/clients/${client_id}/add-remarks`))
      .catch(error => this.setState({ firstLoad: true, error: error }));
    return;
  };

  componentDidMount() {
    let { client_id, remark_id } = this.props.match.params;

    remark_id = _.toNumber(remark_id);

    API.get(`/clients/${client_id}`)
      .then(r => r.data)
      .then(client => {
        let remarkObject = _.find(client.remarks, { id: remark_id });

        if (!_.find(options, { value: remarkObject.type })) {
          const customHeading = remarkObject.type;
          remarkObject.type = "CUSTOM";
          this.setState({
            firstLoad: true,
            selected_remark: remarkObject,
            customHeading,
            isCustom: true
          });
        } else {
          this.setState({ firstLoad: true, selected_remark: remarkObject });
        }
      })
      .catch(error => this.setState({ firstLoad: true, error: error }));
  }

  render() {
    const fail = getLoadingOrFail(this.state);
    if (fail) {
      return fail;
    }
    return (
      <div className="m-2">
        <h1>Edit Client Remark</h1>

        <Select
          options={options}
          simpleValue
          value={this.state.selected_remark.type}
          onChange={type => {
            const { selected_remark } = this.state;
            selected_remark.type = type;
            if (type === "CUSTOM") {
              this.setState({ selected_remark, isCustom: true });
            } else {
              this.setState({ selected_remark, isCustom: false });
            }
          }}
        />
        {this.state.isCustom ? (
          <textarea
            value={this.state.customHeading}
            id="exampleInputEmail1"
            placeholder="Enter Remark"
            rows={2}
            onChange={({ target: { value } }) =>
              this.setState({ customHeading: value })
            }
          />
        ) : null}
        <div class="form-group">
          <textarea
            className="form-control"
            id="exampleInputEmail1"
            placeholder="Enter Remark"
            rows={8}
            defaultValue={this.state.selected_remark.remark}
            onChange={({ target: { value } }) => {
              const { selected_remark } = this.state;
              selected_remark.remark = value;
              this.setState({ selected_remark });
            }}
          />
        </div>
        <a className="btn btn-primary mb-5" href="#" onClick={this.putRemark}>
          Update Remark
        </a>

        {/*<pre>{JSON.stringify(this.state, null, '\t')}</pre>*/}
      </div>
    );
  }
}

export default ClientEditRemark;
