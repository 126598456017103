import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import LabelGroup from "../../components/LabelGroup";

import { Button } from "semantic-ui-react";

import API from "../../api";
import { getLoadingOrFail } from "../../helper";
import moment from "moment/moment";
import Select from "react-select";
import "react-select/dist/react-select.css";

import TimePicker from "react-bootstrap-time-picker";

class ButlerSchedule extends React.Component {
  state = {
    firstLoad: false,
    error: null,
    schedule: []
  };

  componentWillMount() {
    const { butler_id } = this.props.match.params;

    API.get(`/butlers/${butler_id}/schedule`)
      .then(r => r.data)
      .then(schedule => {
        this.setState({ firstLoad: true, schedule });
      })
      .catch(error => this.setState({ firstLoad: true, error: error }));
  }

  hanelSubmit = e => {
    e.preventDefault();

    const { week_day, start_time, end_time } = this.state;

    if (!week_day || !start_time || !end_time) {
      return alert("Please select valid day of week and times");
    }

    const { butler_id } = this.props.match.params;

    API.post(`/butlers/${butler_id}/schedule`, {
      week_day,
      start_time: moment()
        .startOf("day")
        .add(start_time, "seconds")
        .format("HH:mm:ss"),
      end_time: moment()
        .startOf("day")
        .add(end_time, "seconds")
        .format("HH:mm:ss")
    })
      .then(schedule => {
        window.location.reload();
      })
      .catch(error => this.setState({ firstLoad: true, error: error }));

    return;
  };

  handleDelete = (id, e) => {
    e.preventDefault();

    const { butler_id } = this.props.match.params;
    API.delete(`/butlers/${butler_id}/schedule/${id}`, {
      headers: {
        "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE"
      }
    })
      .then(r => window.location.reload())
      .catch(error => this.setState({ firstLoad: true, error: error }));
  };

  render() {
    const { schedule } = this.state;

    const fail = getLoadingOrFail(this.state);
    const week = [
      "SUNDAY",
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY"
    ];

    const scheduleByDay = _.groupBy(schedule, "week_day");

    if (fail) {
      return fail;
    }

    return (
      <div className="p-3 container">
        <div className="row">
          <div className="col-4">
            {week.map(d => {
              const timeslots = _.get(scheduleByDay, d, []);
              const sortedTimeslots = _.sortBy(timeslots, [
                "start_time",
                "end_time"
              ]);
              //.sort((a,b) => moment(a.start_time, "HH:mm:ss").diff(moment(b.start_time, "HH:mm:ss")));

              return (
                <div className="row">
                  <div key={d} className="m-2 p-2 border-bottom">
                    {timeslots.length ? (
                      <h5 className="text-success">{d}</h5>
                    ) : (
                      <h5 className="text-danger">{d}</h5>
                    )}

                    {timeslots.length ? (
                      sortedTimeslots.map(t => (
                        <div className="d-flex align-items-center py-2">
                          <h5 className="m-0">
                            {t.start_time + " - " + t.end_time}
                          </h5>
                          <a
                            className="mx-2 btn btn-danger"
                            href="#"
                            onClick={e => this.handleDelete(t.id, e)}
                          >
                            Delete
                          </a>
                        </div>
                      ))
                    ) : (
                      <h6>Unavailable</h6>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="col-8">
            <h5 className="my-2">Add Schedule</h5>
            <Select
              options={week.map(w => ({ label: w, value: w }))}
              simpleValue
              clearable
              name="selected-state"
              value={this.state.week_day}
              className="w-50"
              onChange={week_day => this.setState({ week_day })}
            />

            <div className="row mt-3">
              <div className="col">
                <h6>From</h6>
                <TimePicker
                  format={24}
                  start="00:00"
                  end="23:00"
                  step={30}
                  value={this.state.start_time}
                  onChange={start_time => this.setState({ start_time })}
                />
              </div>
              <div className="col">
                <h6>To</h6>
                <TimePicker
                  format={24}
                  start="00:00"
                  end="23:00"
                  step={30}
                  value={this.state.end_time}
                  onChange={end_time => this.setState({ end_time })}
                />
              </div>
            </div>

            <a
              href="#"
              className="my-3 btn btn-primary"
              onClick={this.hanelSubmit}
            >
              Submit
            </a>
          </div>
        </div>

        {/*<pre>{JSON.stringify(this.state.schedule, null, '\t')}</pre>*/}
      </div>
    );
  }
}

export default ButlerSchedule;
