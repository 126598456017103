/**
 * Created by erikccoder on 23/5/2018.
 */

import React from "react";
import _ from "lodash";
import API from "../../api";
import { getLoadingOrFail } from "../../helper";
import DatePicker from "react-datepicker";
import TimePicker from "react-bootstrap-time-picker";

import { Dropdown } from "semantic-ui-react";

class InvoiceList extends React.Component {
  state = {
    clients: [],
    selected_client: null,
    selected_topic: null,
    time_in_sec: 8 * 3600
  };

  handleClientDropdownChange = (e, { value }) => {
    if (value < 1) {
      return;
    }

    this.setState({ selected_client: value, selected_topic: null });
  };

  handleMemberDropdownChange = (e, { value }) => {
    this.setState({ selected_topic: value, selected_client: null });
  };

  handleSumbit = () => {
    if (this.state.submitting) {
      return;
    }

    const {
      selected_client,
      selected_topic,
      title,
      message,
      send_date,
      time_in_sec,
      is_send_now
    } = this.state;

    if (!title || !message) {
      return alert("Enter valid title and message");
    }
    // if (!selected_client && !selected_topic) {
    //   return alert("Select valid client or member group");
    // }
    // if (!is_send_now) {
    //   if (!send_date || !time_in_sec) {
    //     return alert("Select valid send date and time");
    //   }
    // }
    //uncooment line 52-59, 64, 70-75and remove line 66
    const data = {
      title,
      message
      // send_at: is_send_now ? "now" : send_date.add(time_in_sec, "seconds")
      // send_at: "now",
      // topic:"ALL"
    };

    // if (selected_client) {
    //   data.user_id = selected_client;
    // }
    // if (selected_topic) {
    //   data.topic = selected_topic;
    // }

    API.post("/push-notifications/broadcast", data)
      .then(r => r.data)
      .then(_ => {
        this.setState({ submitting: null });
        window.location = "/notifications?page=1";
      })
      .catch(error => this.setState({ submitting: null, error }));

    this.setState({ submitting: true });
  };

  componentWillMount() {
    API.get("/push-notifications-clients")
      .then(r => r.data)
      .then(clients => this.setState({ clients, firstLoad: true }))
      .catch(error => this.setState({ error, firstLoad: true }));
  }

  renderDatePicker() {
    return (
      <div className="d-flex m-3">
        <div>
          <label>Date</label>
          <DatePicker
            className="form-control"
            selected={this.state.send_date}
            onChange={send_date => this.setState({ send_date })}
          />
        </div>
        <div className="mx-3">
          <label>Time</label>
          <TimePicker
            start="09:00"
            end="23:00"
            step={30}
            onChange={time_in_sec => this.setState({ time_in_sec })}
          />
        </div>
      </div>
    );
  }

  render() {
    const fail = getLoadingOrFail(this.state);
    if (fail) {
      return fail;
    }

    const client_options = this.state.clients.map(c => ({
      key: _.get(c, "user_id"),
      value: c.fcmToken ? _.get(c, "user_id") : -1,
      text:
        (c.fcmToken ? "" : "DISABLED - ") +
        _.get(c, "full_name", "N/A") +
        " - " +
        _.get(c, "membership.type", "N/A")
    }));

    const members_options = [
      // "CLIENTS","BUTLERS",
      "ALL",
      "GOLD_MEMBERS",
      "SILVER_MEMBERS",
      "CLUB_MEMBERS"
    ].map(m => ({
      key: m,
      value: m,
      text: m.includes("ALL") ? m : "ALL " + m.replace("_", " ")
    }));

    return (
      <div className="p-1">
        <h1>New Notification Form</h1>

        <div className="form-group m-3">
          <label htmlFor="title">Message Title</label>
          <input
            id="title"
            className="form-control"
            placeholder="Message Title"
            onChange={({ target: { value } }) =>
              this.setState({ title: value })
            }
          />
        </div>

        <div className="form-group m-3">
          <label htmlFor="m-bdy">Message Body</label>
          <textarea
            id="m-bdy"
            className="form-control"
            placeholder="Message Body"
            rows="5"
            onChange={({ target: { value } }) =>
              this.setState({ message: value })
            }
          />
        </div>

        {/* <div className="row my-2 mx-1">
          <div className="form-group col-6">
            <label>Client</label>
            <Dropdown
              deburr
              fluid
              options={client_options}
              value={this.state.selected_client}
              placeholder="Select a client"
              search
              selection
              onChange={this.handleClientDropdownChange}
            />
          </div>

          <div className="form-group col-6">
            <label>Members</label>
            <Dropdown
              deburr
              fluid
              options={members_options}
              value={this.state.selected_topic}
              search
              selection
              onChange={this.handleMemberDropdownChange}
            />
          </div>
        </div> */}

        {/* <div className="form-check form-check-inline mx-3">
          <input
            className="form-check-input"
            type="radio"
            name="inMemberType"
            id="inOrderTypeRadioYes"
            onChange={() => this.setState({ is_send_now: true })}
          />
          <label className="form-check-label" htmlFor="inOrderTypeRadioYes">
            Send Now
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="inMemberType"
            id="inOrderTypeRadioNo"
            onChange={() => this.setState({ is_send_now: false })}
          />
          <label className="form-check-label" htmlFor="inOrderTypeRadioNo">
            Pick a date and time
          </label>
        </div>

        {this.state.is_send_now === false ? this.renderDatePicker() : null} */}

        <nav class="nav p-3" id="sumbit">
          <a
            className="nav-link btn-danger "
            href="#sumbit"
            onClick={this.handleSumbit}
          >
            {this.state.submitting ? "Submitting" : "Submit"}
          </a>
        </nav>

        {/*<pre>{JSON.stringify( this.state.clients, null, '\t')}</pre>*/}
      </div>
    );
  }
}

export default InvoiceList;
