import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import LabelGroup from "../../components/LabelGroup";
import DateRangePicer from "../../components/DateRangePicer";

import { Button } from "semantic-ui-react";

import API from "../../api";
import { getLoadingOrFail } from "../../helper";
import moment from "moment/moment";

class ButlerLeaves extends React.Component {
  state = {
    firstLoad: false,
    error: null,
    leaves: []
  };

  componentWillMount() {
    const { butler_id } = this.props.match.params;

    API.get(`/butlers/${butler_id}/leaves`)
      .then(r => r.data)
      .then(leaves => {
        this.setState({ firstLoad: true, leaves });
      })
      .catch(error => this.setState({ firstLoad: true, error: error }));
  }

  handleDate = ({ startDate, endDate }) => {
    this.setState({
      start_date: startDate.format("YYYY-MM-DD"),
      end_date: endDate.format("YYYY-MM-DD")
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    const { butler_id } = this.props.match.params;

    const { end_date, start_date } = this.state;
    if (!end_date || !start_date) {
      return alert("Please select valid date range");
    }

    API.post(`/butlers/${butler_id}/leaves`, { end_date, start_date })
      .then(r => window.location.reload())
      .catch(error => this.setState({ error }));
  };

  handleDelete = (id, e) => {
    e.preventDefault();
    const { butler_id } = this.props.match.params;

    API.delete(`/butlers/${butler_id}/leaves/${id}`)
      .then(r => window.location.reload())
      .catch(error => this.setState({ error }));
  };

  render() {
    const { leaves } = this.state;

    const fail = getLoadingOrFail(this.state);

    if (fail) {
      return fail;
    }

    return (
      <div className="m-3">
        <h2 className="m-2">Butler Leaves</h2>

        <div className="row">
          <div className="col-6 py-4">
            <h5>Current Records</h5>
            {this.state.leaves
              .map(l => ({
                ...l,
                start_time: moment(l.start_date).format("DD/MM/YYYY")
              }))
              .map(l => ({
                ...l,
                end_time: moment(l.end_date).format("DD/MM/YYYY")
              }))
              .map(l => (
                <div className="d-flex align-items-center py-2">
                  <h5 className="m-0">{l.start_time + " - " + l.end_time}</h5>
                  <a
                    className="mx-3 btn btn-danger"
                    href="#"
                    onClick={e => this.handleDelete(l.id, e)}
                  >
                    Delete
                  </a>
                </div>
              ))}
          </div>
          <div className="col-6 py-4">
            <DateRangePicer onChange={this.handleDate} />
            <a
              className="btn btn-primary my-4"
              href="#"
              onClick={this.handleSubmit}
            >
              Add New Record
            </a>
          </div>
        </div>

        {/*<pre>{JSON.stringify(this.state.leaves, null, '\t')}</pre>*/}
      </div>
    );
  }
}

export default ButlerLeaves;
