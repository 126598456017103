/**
 * Created by erikccoder on 26/5/2018.
 */

import React from 'react'
import _ from 'lodash'
import API from '../../api'
import SimpleTable from '../../components/SimpleTable';
import {getLoadingOrFail} from '../../helper';

class AdminNotifications extends React.Component {
	state = {
		firstLoad: false,
		error: null
	}
	componentWillMount() {
		API.get("/admin-notifications")
			.then(r => r.data)
			.then( ({latest_updated_order_tasks}) => this.setState({latest_updated_order_tasks, firstLoad: true}))
			.catch(error => this.setState({error, firstLoad: true}))
		;
	}

	render(){

		const fail = getLoadingOrFail(this.state);
		if(fail){return fail}

		const latest_updated_order_tasks = _.get(this.state, "latest_updated_order_tasks", []);

		return(
			<React.Fragment>
				<h1 className="mx-2">AdminNotifications</h1>
				<ol>
				{
					latest_updated_order_tasks.map(
						(task, i) =>
						(
							<li key={i}>
								<a  href={`/calendar/${task.order_id}`}>Order #{task.order_id} need to check</a>
							</li>
						)
					)
				}
				</ol>
				{
					!latest_updated_order_tasks.length && (<h6 className="mx-3">Nothing need to update</h6>)
				}
			</React.Fragment>

		)
	}
}

export default AdminNotifications;