/**
 * Created by erikccoder on 24/5/2018.
 */

import React from 'react'
import ClientForm from './ClientForm'
import { Button, Form, Label, Input, TextArea } from "semantic-ui-react";
import API from "../../api";

class NewClientForm extends React.Component
{
	state = {
		saving: false,
	}

	componentDidMount(){
	}

	saveBtnOnClick(e){
		e.preventDefault()
		const data = this.form.getFormDataByApiCreateFormat();

		this.setState({saving: true})

		API.post('/clients',data)
			.then((response)=>{
				console.log('succ',response)
          		window.location.href = "/clients";
				this.setState({saving: false})
			}).catch((error)=>{
				console.log('err',error)
				this.setState({saving: false})
			})
		console.log('save btn',data, JSON.stringify(data))
	}

	render(){
		return (
			<form className="custom_form">
				<div className="container-fluid k-table py-3">
					<div className="bg-white border-bottom p-3 row">
						<Button
							size="tiny"
							href="/clients"
							color="orange"
							className="bg-red"
						>
							GO BACK
						</Button>
					</div>
					<div className="s_cardbox">
						<ClientForm 
							ref={(form)=>{this.form=form}}
						/>
						<div className="s_x_divider">&nbsp;</div>
						<div className="s_row s_flex">
							<Button
							  onClick={this.saveBtnOnClick.bind(this)}
							  size="tiny"
							  color="orange"
							  className="bg-red"
							>
							{
								this.state.saving
								? "Saving"
								: "Save"
							}
							</Button>
							<Button
							  href="/clients"
							  size="tiny"
							  color="black"
							  className="bg-md-grey"
							  style={{color:'black'}}
							>
							  Cancel
							</Button>
						</div>
					</div>
				</div>
			</form>
		)
	}

}

export default NewClientForm
