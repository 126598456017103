const initialState = {
	list: [],
	details: {}
}

export default (state = initialState, action = {}) =>
{
	
	switch (action.type)
	{
		case "SET_CLIENTS_LIST":
			return {
				...state,
				list: action.clients_list,
			}
		case "SET_CLIENTS_DETAILS":

			console.log("SET_CLIENTS_DETAILS", action);
			
			return {
				...state,
				details: {
					...state.details,
					[action.client_id]: action.client_details
				}
			}
		default:
			return state
	}
}
