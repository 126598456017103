/**
 * Created by erikccoder on 21/5/2018.
 */
import React from "react";
import API from "../../api";
import { getLoadingOrFail } from "../../helper";

class WhatsNewDetails extends React.Component {
  state = {
    whatsnew_details: null,
    firstLoad: false,
    error: null
  };

  componentWillMount() {
    console.log(this.props.match.params);
    const { id } = this.props.match.params;

    API.get(`/whatsnew/${id}`)
      .then(res => {
        console.log(res.data);
        this.setState({ whatsnew_details: res.data[0], firstLoad: true });
      })
      .catch(error => this.setState({ error, firstLoad: true }));
  }
  delete = () => {
    const { id } = this.props.match.params;
    API.delete(`/whatsnew/${id}`)
      .then(res => {
        if (res.data) {
          window.location = "/whatsnew";
        }
      })
      .catch(error => this.setState({ error, firstLoad: true }));
  };
  render() {
    const fail = getLoadingOrFail(this.state);
    if (fail) {
      return fail;
    }
    if (!this.state.firstLoad) return <div>Loading....</div>;
    console.log(this.state.whatsnew_details.image);
    return (
      <div>
        <h1>Whatsnew Details</h1>

        <pre>{JSON.stringify(this.state.whatsnew_details, null, "\t")}</pre>
        <img src={this.state.whatsnew_details.image} style={{ height: 200 }} />
        <a href="#" className="btn btn-danger btn-block" onClick={this.delete}>
          Delete this whatsnew
        </a>
      </div>
    );
  }
}

export default WhatsNewDetails;
