import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import LabelGroup from "../../components/LabelGroup";

import { Button } from "semantic-ui-react";

import API from "../../api";
import { getLoadingOrFail } from "../../helper";

class ButlerDistricts extends React.Component {
  state = {
    firstLoad: false,
    error: null,
    workDistricts: [],
    districts: [],
    mapping: {}
  };

  componentWillMount() {
    const { butler_id } = this.props.match.params;

    API.get(`/butlers/${butler_id}/districts`)
      .then(r => r.data.districts)
      .then(workDistricts => {
        this.setState({ firstLoad: true, workDistricts });
      })
      .catch(error => this.setState({ firstLoad: true, error: error }));

    API.get("/districts")
      .then(r => r.data)
      .then(({ districts, mapping }) => {
        this.setState({ districts, mapping });
        console.log({ districts, mapping });
      });
  }

  handleChange = ({ target: { value } }) => {
    let { workDistricts } = this.state;

    if (workDistricts.includes(value)) {
      workDistricts = _.pull(workDistricts, value);
    } else {
      workDistricts.push(value);
    }

    this.setState({ workDistricts });
  };

  handleSubmit = e => {
    e.preventDefault();

    const { butler_id } = this.props.match.params;

    this.setState({ submiting: true });

    API.post(`/butlers/${butler_id}/districts`, {
      districts: this.state.workDistricts
    })
      .then(r => {
        window.location = `/butlers/${butler_id}/`;
      })
      .catch(error => this.setState({ error: error }));

    return;
  };

  render() {
    const { districts, mapping, workDistricts } = this.state;

    const fail = getLoadingOrFail(this.state);

    const districts_list = [
      "---Hong Kong Island",
      "Wan Chai",
      "Southern",
      "Eastern",
      "Central & Western",
      "---Kowloon",
      "Yau Tsim Mong",
      "Wong Tai Sin",
      "Sham Shui Po",
      "Kwun Tong",
      "Kowloon City",
      // Yuen Long is preserved
      // "Yuen Long",
      "Tuen Mun",
      "Tsuen Wan",
      "Tai Po",
      "Sha Tin",
      "Sai Kung",
      "North",
      "Kwai Tsing",
      "Islands"
    ];
    const new_districts = _.filter(districts, { is_new: true });

    if (fail) {
      return fail;
    }

    return (
      <div className="p-3">
        <h5>Districts</h5>

        <div className="form-check">
          {districts_list.map(district => {
            if (district.charAt(0) == "-") {
              return (
                <div key={district} className="mx-1">
                  <label className="h4" htmlFor={district}>
                    {district}
                  </label>
                </div>
              );
            } else {
              return (
                <div key={district} className="mx-1">
                  <input
                    type="checkbox"
                    className="mx-1"
                    id={district}
                    value={district}
                    checked={workDistricts.indexOf(district) > -1}
                    onChange={this.handleChange}
                  />
                  <label className="h6" htmlFor={district}>
                    {`${district} -> ${mapping[district]}`}
                  </label>
                </div>
              );
            }
          })}
          <div key={"new"} className="mx-1">
            <label className="h4" htmlFor={"new"}>
              {"----- New Districts --------"}
            </label>
          </div>
          {new_districts.map(({ district }) => (
            <div key={district} className="mx-1">
              <input
                type="checkbox"
                className="mx-1"
                id={district}
                value={district}
                checked={workDistricts.indexOf(district) > -1}
                onChange={this.handleChange}
              />
              <label className="h6" htmlFor={district}>
                {`${district} (new)`}
              </label>
            </div>
          ))}
        </div>

        <a className="btn btn-primary" href="#" onClick={this.handleSubmit}>
          {this.state.submiting ? "Submitting" : "Submit to Server"}
        </a>
        {/*{districts.map(d => <h5>{d}</h5>)}*/}
      </div>
    );
  }
}

export default ButlerDistricts;
