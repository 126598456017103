import React from 'react'
import{
	Segment,
	Label,
} from 'semantic-ui-react'

const LabelGroupGrey = (props) => (
	<div className={props.className}>
		<Label className="bg-none pl-0" >{props.label}</Label>
		<Segment secondary size="small" className="mt-0">
			{props.text}
		</Segment>
	</div>
);

export default LabelGroupGrey;
