import axios from "axios";
import _ from "lodash";
import env from "dotenv";
// const baseURL = `http://butler.ap-southeast-1.elasticbeanstalk.com:3000/invoices/1/details?year=2018&month÷`;

// const url = Env.get("REACT_APP_API_HOST", "");
// const port = Env.get("REACT_APP_API_PORT", "3333");
// const url = process.env.REACT_APP_API_HOST || "http://localhost";
const url = 'https://cms-core.butlerasia.com';
const port = process.env.REACT_APP_API_PORT || 3333;

// const baseURL = url + ":" + port + "/api";
const baseURL = url+'/api';
console.log(baseURL);
const instance = axios.create({
  // baseURL,
  // baseURL: `http://butler-test.com:3333/api`,
  // baseURL: `http://butler.ap-southeast-1.elasticbeanstalk.com:3333/api`,
  // baseURL: `http://52.221.35.108:3333/api/`
  // baseURL: `http://192.168.0.99:8080/api/`,
  baseURL: baseURL,
  headers: {
    request_correct_datetime: true
  }
});

// instance.interceptors.request.use( (configs) => {
// 	return config;
// });

/*
instance.interceptors.response.use(
  response => response,
  ({ config, request, response }) => {
    const { name, status } = _.get(response, "data.error", {});
    if (status == 401 && name == "ExpiredJwtToken") {
      console.log("AUTO refresh token");
      if (window.confirm(`${status}:${name}. Goto login page?`)) {
        localStorage.removeItem("user_token");
        localStorage.removeItem("user_refreshToken");
        window.location = "/login";
      }
      // return (
      // 	instance.put("/login", {
      // 		refreshToken: localStorage.getItem("user_refreshToken")
      // 	})
      // )
    } else {
      console.log("need to login", request);
    }

    return Promise.reject({ config, request, response });
  }
);
*/

instance.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    const originalRequest = error.config;

    if (!error.response) {
      // Something happened in setting up the request that triggered an error
      console.log("Error", error.message);
      return Promise.reject(error);
    }
    // error.response exists
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const refreshToken = window.localStorage.getItem("user_refreshToken");
      // localStorage.setItem("user_token", action.token);
      // localStorage.setItem("user_refreshToken", action.refreshToken);

      return axios
        .put(`${baseURL}/login`, { refreshToken })
        .then(({ data }) => {
          window.localStorage.setItem("user_token", data.token);
          window.localStorage.setItem("user_refreshToken", data.refreshToken);
          const authorization = `Bearer ${data.token}`;
          instance.defaults.headers.common["Authorization"] = authorization;
          originalRequest.headers["Authorization"] = authorization;

          return instance(originalRequest);
        })
        .catch(r => (window.location = "/login"));
    }
    return Promise.reject(error);
  }
);

export default instance;
