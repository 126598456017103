import React from 'react';
import { Route, Redirect, Switch, Router, Link } from 'react-router-dom';
import { connect } from 'react-redux'
import _ from 'lodash'

import Home from '../home';
import Login from '../login';

const App = (props) => {

    const url = _.get(props, "match.url", "");
    // const needRedirect =  url.length < 1;

    return (
        <div className="h-100">
            {/*
                needRedirect
                    ? (<Redirect to="/clients" />)
                    :null
            */}
            <Switch>
                <Route exact path="/" render={ _=> window.location="/clients"} />
                <Route path="/login" component={Login} />
                <Route path="/:page" component={Home} />
            </Switch>
        </div>
    )
};

export default App;
