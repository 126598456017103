import React from "react";
import _ from "lodash";
import SimpleTable from "../../components/SimpleTable";
import API from "../../api";
import RatingsToolbar from "./toolbar";

const headers = [
  {
    text: "Name",
    key: "name"
  },
  {
    text: "Average Rating",
    key: "ratings"
  },
  {
    text: "Badges",
    key: "badges"
  }
];

class ButlerRatings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKWord: "",
      firstLoad: false,
      error: null,
      sortDirection: null,
      sortColumn: null,
      butlers: []
    };
  }

  componentWillMount() {
    API.get(`/butler_ratings`)
      .then(r => r.data)
      .then(butlers => {
        this.setState({ butlers });
      })
      .then(firstLoad => this.setState({ firstLoad: true }))
      .catch(error => this.setState({ firstLoad: true, error: error }));
  }

  headerOnClickForSort({ text, key }) {
    const { sortDirection, sortColumn } = this.state;
    if (sortColumn == key) {
      this.setState({
        sortColumn: key,
        sortDirection:
          sortDirection === "ascending" ? "descending" : "ascending"
      });
    } else {
      this.setState({
        sortColumn: key,
        sortDirection: "ascending"
      });
    }
  }

  render() {
    const {
      searchKWord,
      firstLoad,
      error,
      sortDirection,
      sortColumn
    } = this.state;

    if (!firstLoad) {
      return (
        <div className="w-100 h-100 p-5 d-inline-block">
          <h1 className="text-center align-middle">Loading...</h1>
        </div>
      );
    } else if (error) {
      return <pre>{JSON.stringify(error, null, "\t")}</pre>;
    }

    let butlers = this.state.butlers.filter(c => {
      if (
        searchKWord == "" ||
        _.get(c, "name", "")
          .toLowerCase()
          .search(searchKWord.toLowerCase()) > -1
      ) {
        return true;
      }
      return false;
    });

    butlers = _.sortBy(butlers, [sortColumn]);
    // if (sortDirection == "descending") {
    //   butlers = butlers.reverse();
    // }
    butlers = butlers.map(butler => {
      const x = butler.badges.map(badge => {
        return (
          <p>
            {badge.name} : {badge.count}
          </p>
        );
      });
      butler.badges = x;
      return butler;
    });
    return (
      <div>
        <br />
        <br />
        <RatingsToolbar
          export={this.export}
          setSearchWords={searchKWord => this.setState({ searchKWord })}
        />
        <SimpleTable
          className="mx-2"
          theader={headers}
          tbody={butlers}
          baseURL="/butler_ratings/:key"
          key_link_to="id"
          //   direction={sortDirection}
          //   column={sortColumn}
          //   headerOnClickForSort={this.headerOnClickForSort.bind(this)}
        />
      </div>
    );
  }
}

export default ButlerRatings;
