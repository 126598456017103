/**
 * Created by erikccoder on 17/5/2018.
 */
import React from "react";
import _ from "lodash";
import moment from "moment";
import { Button, Loader } from "semantic-ui-react";

import API from "../../api";
import { getLoadingOrFail } from "../../helper";
import LabelGroup from "../../components/LabelGroup";

import { OrderTaskMapping } from "../../WordMapping";

class SchedulerDetails extends React.Component {
  state = {
    order: null,
    firstLoad: false,
    error: null,
    checklist: null,
    editingText: "",
    editingIndex: "",
    addNewChecklist: false,
    newChecklistText: "",
    hoursResponse: null
  };

  componentWillMount() {
    const order_id = _.get(this.props, "match.params.order_id", null);

    if (order_id == null) {
      return alert("no order id found");
    }

    API.get(`/orders/${order_id}`)
      .then(r => r.data)
      .then(order => {
        console.log({ order });
        this.setState({ order });
        return order;
      })
      .then(order => {
        API.get(`/orders/hours-left/${order.client.id}`)
          .then(r => r.data)
          .then(hoursResponse => {
            console.log({ hoursResponse });
            const is_member =
              _.get(hoursResponse, "membership") !== "NON_MEMBERSHIP";
            this.setState({
              order: { ...order, is_member },
              hoursResponse,
              firstLoad: true
            });
          })
          .catch(error => this.setState({ error }));
      })
      .catch(error => this.setState({ firstLoad: true, error: error }));
    API.get(`/butler/order/checklist/${order_id}`)
      .then(r => r.data)
      .then(checklist => this.setState({ checklist }))
      .catch(error => this.setState({ error }));
  }
  something = (e, f, g) => {
    console.log(e);
    console.log(f);
    console.log(g);
  };

  saveAddNewFeedback = e => {
    let checklist = this.state.checklist;
    checklist.push(this.state.newChecklistText);
    const order_id = _.get(this.props, "match.params.order_id", null);
    API.post(`/client/order/checklist/${order_id}`, {
      feedbacks: checklist
    })
      .then(r => r.data)
      .then(() => {
        this.setState({ addNewChecklist: false, newChecklistText: "" });
      })
      .then(() => {
        API.get(`/butler/order/checklist/${order_id}`)
          .then(r => r.data)
          .then(checklist => this.setState({ checklist }))
          .catch(error => this.setState({ error: error }));
      })
      .catch(error => this.setState({ error: error }));
  };

  saveEditFeedback = e => {
    const order_id = _.get(this.props, "match.params.order_id", null);
    API.put(`/order/checklist/${e}`, {
      feedback: this.state.editingText,
      order_id
    })
      .then(r => r.data)
      .then(checklist =>
        this.setState({ checklist, editingText: "", editingIndex: "" })
      )
      .catch(error => this.setState({ error: error }));
  };

  deleteFeedback = e => {
    const order_id = _.get(this.props, "match.params.order_id", null);
    API.delete(`/client/order/checklist/${order_id}/${e}`)
      .then(r => r.data)
      .then(checklist =>
        this.setState({ checklist, editingText: "", editingIndex: "" })
      )
      .catch(error => this.setState({ error: error }));
  };

  renderChecklist() {
    return (
      <div>
        <LabelGroup label="Client Checklist" />
        {this.state.checklist &&
          this.state.checklist.map((checklist, index) => {
            if (index === this.state.editingIndex) {
              return (
                <div>
                  <input
                    className={{ style: "1px solid #ced4da" }}
                    id="checklist_edit"
                    ref="checklist"
                    onChange={e =>
                      this.setState({ editingText: e.target.value })
                    }
                    defaultValue={this.state.editingText}
                  />
                  <a
                    // href={`/calendar/${id}/add-remark`}
                    className="ml-3 mt-3 mb-3 btn btn-primary"
                    onClick={() => this.saveEditFeedback(checklist.id)}
                  >
                    Save
                  </a>
                </div>
              );
            } else {
              return (
                <li>
                  {checklist.feedback}
                  <a
                    // href={`/calendar/${id}/add-remark`}
                    className="ml-3 mt-3 mb-3 btn btn-primary"
                    onClick={() =>
                      this.setState({
                        editingIndex: index,
                        editingText: checklist.feedback
                      })
                    }
                  >
                    Edit
                  </a>
                  <a
                    className="ml-3 mt-3 mb-3 btn btn-danger"
                    onClick={() => this.deleteFeedback(checklist.id)}
                  >
                    Delete
                  </a>
                </li>
              );
            }
          })}
      </div>
    );
  }

  renderAddNewChecklist = () => {
    if (this.state.addNewChecklist) {
      return (
        <div>
          <input
            className={{ style: "1px solid #ced4da" }}
            id="checklist_add"
            ref="checklist_add"
            onChange={e => this.setState({ newChecklistText: e.target.value })}
            defaultValue={this.state.newChecklistText}
          />
          <a
            className="ml-3 mt-3 mb-3 btn btn-primary"
            onClick={() => this.saveAddNewFeedback()}
          >
            Save
          </a>
        </div>
      );
    } else {
      return (
        <div>
          <a
            className="ml-3 mt-3 mb-3 btn btn-primary"
            onClick={() => this.setState({ addNewChecklist: true })}
          >
            Add new checklist
          </a>
        </div>
      );
    }
  };
  renderTasks() {
    let tasks = _.get(this.state, "order.tasks", []);

    tasks = tasks.map(t =>
      _.omit(t, ["id", "order_id", "created_at", "updated_at"])
    );

    const cleaningTasks = tasks
      .filter(t => t.type === "CLEANING")
      .sort((a, b) => a.priority - b.priority);

    const otherTasks = tasks.filter(t => t.type !== "CLEANING");

    return (
      <div>
        {tasks.map((task, i) => (
          <div className="border-bottom py-3">
            {_.map(task, (value, key) =>
              value ? (
                <p key={`${i}-${key}`}>
                  {OrderTaskMapping(key)}:
                  <strong>
                    {_.isObject(value) ? (
                      <ul>
                        {_.map(value, (_v, _k) => (
                          <li>
                            {OrderTaskMapping(_k)}: {OrderTaskMapping(_v)}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      OrderTaskMapping(value)
                    )}
                  </strong>
                </p>
              ) : null
            )}
          </div>
        ))}
      </div>
    );

    /*
    return (
        <div>
          {
            cleaningTasks.length && (<LabelGroup label="Cleaning" />)
          }

          {cleaningTasks.map(function(task) {
            return (
                <LabelGroup
                    label={'Priority ' + task.priority}
                    text={task.remark}
                />
            );
          })}

          {
            otherTasks.map(function(task) {
              return <LabelGroup label={task.type} text={task.remark} />;
            })
          }
        </div>
    )
    */
  }

  handleOrderStatus(status, hidden_cancel = false) {
    console.log(this.state);
    const confirmed = window.confirm(
      `Are you sure want to set the order's status to ${status}, hidden_cancel: ${hidden_cancel}?`
    );
    if (confirmed) {
      const order_id = _.get(this.props, "match.params.order_id", null);

      if (order_id == null) {
        return alert("no order id found");
      }

      let api = "";
      API.post(`/admin/approve/orders/${order_id}`, {
        status,
        hidden_cancel
      })
        .then(res => {
          console.log(res);
          alert("Order updated!");
          window.location.reload();
        })
        .catch(error => this.setState({ error: error }));
    }
  }

  changeOrderStatusForButler(status) {
    console.log(this.state);
    const { butler_id, id: order_id } = this.state.order;

    const confirmed = window.confirm(
      `Are you sure want to set the order's status to ${status}?`
    );
    if (confirmed) {
      switch (status) {
        case "DOING":
          API.post(`butlers/${butler_id}/check-in/${order_id}/`)
            .then(res => {
              alert("Order started");
              window.location.reload();
            })
            .catch(error => this.setState({ error: error }));
          break;
        case "FINISHED":
          API.post(`butlers/${butler_id}/check-out/${order_id}/`)
            .then(res => {
              alert("Order finished!");
              window.location.reload();
            })
            .catch(error => this.setState({ error: error }));
          break;
      }
    }
  }
  goBack() {
    const now = moment();
    const search_params = `?start=${now.format("YYYY-MM-DD")}&end=${now
      .add(30, "days")
      .format("YYYY-MM-DD")}`;
    window.location = `/calendar${search_params}`;
  }
  renderCorrectButton = () => {
    if (!this.state.firstLoad) return;
    if (this.state.order.status === "APPROVED") {
      return (
        <a
          href="#"
          className="btn btn-primary btn-block"
          onClick={_ => this.changeOrderStatusForButler("DOING")}
        >
          START the order
        </a>
      );
    } else if (this.state.order.status === "DOING") {
      return (
        <a
          href="#"
          className="btn btn-primary btn-block"
          onClick={_ => this.changeOrderStatusForButler("FINISHED")}
        >
          STOP the order
        </a>
      );
    } else if (this.state.order.status === "FINISHED") {
      return (
        <a href="#" className="btn btn-primary btn-block" aria-disabled={true}>
          The order has been finished
        </a>
      );
    } else {
      return (
        <a
          href="#"
          className="btn btn-primary btn-block"
          aria-disabled={true}
        />
      );
    }
  };

  renderMonthlyBookingHours = () => {
    if (!this.state.hoursResponse) {
      return <Loader>Loading</Loader>;
    }
    return (
      <div>
        <div>
          <label style={{ display: "inline-block" }}>
            {" "}
            Monthly Plan Allowed Hours{" "}
          </label>
          : {_.get(this.state, "hoursResponse.hoursAllowed", "N/A")}
        </div>
        <div>
          <label style={{ display: "inline-block" }}>
            {" "}
            Hours Booked This Cycle{" "}
          </label>
          : {_.get(this.state, "hoursResponse.hoursBookedThisMonth", "N/A")}
        </div>
        <div>
          <label style={{ display: "inline-block" }}>
            {" "}
            Hold Hours For Current Cycle (should be 0){" "}
          </label>
          : {_.get(this.state, "hoursResponse.holdHoursThisMonth", "N/A")}
        </div>
        <div>
          <label style={{ display: "inline-block" }}>
            {" "}
            Future Hold Hours For The Next Cycle{" "}
          </label>
          : {_.get(this.state, "hoursResponse.allFutureHoldHours", "N/A")}
        </div>
        <div>
          <label style={{ display: "inline-block" }}>
            {" "}
            Start of month of subscription{" "}
          </label>
          : {_.get(this.state, "hoursResponse.startOfMonthForClient", "N/A")}
        </div>
      </div>
    );
  };

  render() {
    const { order, firstLoad, error } = this.state;

    const fail = getLoadingOrFail(this.state);
    if (fail) {
      return fail;
    }

    const id = _.get(order, "id", "N/A");
    const is_member = _.get(order, "is_member", false);
    const is_urgent = _.get(order, "is_urgent", false);
    const is_complimentary = _.get(order, "is_complimentary_order", false);

    const clientName = _.get(order, "client.user.full_name", "N/A");
    const clientEmail = _.get(order, "client.user.email", "N/A");
    const clientContactNo = _.get(order, "client.user.contact_no", "N/A");
    const clientAddress = _.get(order, "client.address", "N/A");
    const clientDistrict = _.get(order, "client.district", "N/A");
    const clientEntryMethod = _.get(order, "client.entry_method", "N/A");

    const endTimeTS = _.get(order, "end_timestamp", "N/A");
    const startTimeTS = _.get(order, "start_timestamp", "N/A");
    const startDate = moment(startTimeTS).format("DD/MM/YYYY");
    const startTime = moment(startTimeTS).format("hh:mm a");
console.log(startTimeTS);
console.log(moment.locale());
    const endTime = moment(endTimeTS).format("hh:mm a");

    const from_cms = _.get(order, "from_cms");

    const session = moment(endTimeTS).diff(moment(startTimeTS), "hours", true);

    const butler_full_name = _.get(order, "butler.user.full_name", "N/A");

    const is_flexible = _.get(order, "is_flexiable", false);
    const is_override = _.get(order, "override_hour", false);
    const is_addRestTime = _.get(order, "extra_rest_time", false);

    let tasks = _.get(order, "tasks", []);
    // const attachments = _.get(order, "attachments", []);

    // const aurl = attachments.map(function(attachment) {
    //   return _.get(attachment.url, "0.path", null);
    // });

    const uploads = _.get(order, "orderUploads", []);
    const aurl = uploads.map(item => _.get(item, "image", null));

    const cleaningTasks = tasks
      .filter(t => t.type === "CLEANING")
      .sort((a, b) => a.priority - b.priority);

    const otherTasks = tasks.filter(t => t.type !== "CLEANING");

    // const remarks = tasks.map(function(task) {
    //     return task.remark;
    // });

    // //const membership = _.get(order, "", "N/A");
    // const session = moment(endTimeTS).diff(
    //     moment(startTimeTS),
    //     'hours'
    // );
    // const butlerName = _.get(order, 'butler_id', 'N/A');

    const back_urls = this.props.match.url.split("/");
    back_urls.pop();

    return (
      <div className="container-fluid k-table py-3">
        <div className="bg-white border-bottom p-3 row">
          <Button
            size="tiny"
            // href={back_urls.join("/")}
            color="orange"
            onClick={this.goBack}
            className="bg-red"
          >
            GO BACK
          </Button>
        </div>

        <div className="row">
          <div className="col">
            <form>
              <div>
                <div className="form-group">
                  <label htmlFor="inMemberType">Member Type</label>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inMemberType"
                      id="inMemberTypeRadioYes"
                      value="true"
                      checked={is_member}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inMemberTypeRadioYes"
                    >
                      Existing member
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inMemberType"
                      id="inMemberTypeRadioNo"
                      value="false"
                      checked={!is_member}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inMemberTypeRadioNo"
                    >
                      Non member
                    </label>
                  </div>
                </div>

                <div className="form-group sr-only">
                  <label htmlFor="inUrgent">Urgent</label>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inUrgent"
                      id="inUrgentRadioYes"
                      value="true"
                      checked={is_urgent ? "checked" : null}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inUrgentRadioYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inUrgent"
                      id="inUrgentRadioNo"
                      value="false"
                      checked={!is_urgent ? "checked" : null}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inUrgentRadioNo"
                    >
                      No
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group">
                    <label htmlFor="inUrgent">Complimentary Order</label>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inComplimentaryOrder"
                        id="inComplimentaryOrderYes"
                        value="true"
                        checked={is_complimentary ? "checked" : null}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inComplimentaryOrderYes"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inComplimentaryOrder"
                        id="inComplimentaryOrderNo"
                        value="false"
                        checked={!is_complimentary ? "checked" : null}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inComplimentaryOrderNo"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <LabelGroup label="Client" text={clientName} />
                <LabelGroup label="Email" text={clientEmail} />
                <LabelGroup label="Contact number" text={clientContactNo} />
                <LabelGroup label="Address" text={clientAddress} />
                <LabelGroup label="District" text={clientDistrict} />
                <LabelGroup
                  label="Discode Code"
                  text={
                    this.state.order.discount
                      ? this.state.order.discount.discount.code
                      : "No discount"
                  }
                />
                <LabelGroup
                  label="入屋方法（Entry Method）"
                  text={clientEntryMethod}
                />
              </div>

              <div>
                <h6>Status: {_.get(order, "status", "N/A")}</h6>
              </div>
              <div>{this.renderMonthlyBookingHours()}</div>

              <div className="border p-4 mb-4">
                <a
                  href="#"
                  className="btn btn-danger btn-block"
                  onClick={_ => this.handleOrderStatus("CANCELED")}
                >
                  CANCEL the order (Don't give back the complimentary hours)
                </a>
                <a
                  href="#"
                  className="btn btn-danger btn-block"
                  onClick={_ => this.handleOrderStatus("CANCELED", true)}
                >
                  CANCEL the order (Don't give back the complimentary hours)
                  [Hidden]
                </a>
                <a
                  href="#"
                  className="btn btn-danger btn-block"
                  onClick={_ => this.handleOrderStatus("REMOVED")}
                >
                  REMOVE the order (Give back the complimentary hours)
                </a>
                <a
                  href="#"
                  className="btn btn-primary btn-block"
                  onClick={_ => this.handleOrderStatus("APPROVED")}
                >
                  APPROVE the order
                </a>
                {this.renderCorrectButton()}
              </div>
            </form>
          </div>
          <div className="col">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="inFlexible"
                checked={is_flexible}
              />
              <label className="custom-control-label" htmlFor="inFlexible">
                Flexible Time
              </label>
            </div>

            <div className="my-2">
              <LabelGroup
                className="d-inline-block mr-4"
                label="Date"
                text={startDate}
              />
              <LabelGroup
                className="d-inline-block"
                label="Session"
                text={session + " hours"}
              />
            </div>

            <div className="my-2">
              <LabelGroup
                className="d-inline-block mr-4"
                label="Time"
                text={startTime + " - " + endTime}
              />
              <LabelGroup
                className="d-inline-block"
                label="Butler"
                text={butler_full_name}
              />
            </div>

            <div className="my-2">
              <LabelGroup
                className="d-inline-block mr-4"
                label="Created by CMS"
                text={{ [false]: "False", [true]: "True" }[from_cms] ?? "N/A"}
              />
            </div>

            <div className="custom-control custom-checkbox sr-only hidden">
              <input
                type="checkbox"
                className="custom-control-input"
                id="inOverride"
                checked={is_override}
              />
              <label className="custom-control-label" htmlFor="inOverride">
                Override butler working hours
              </label>
            </div>

            <div className="form-group sr-only hidden">
              <label htmlFor="inAdditionalRestTime">Addtional rest time</label>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inAdditionalRestTime"
                  id="inAdditionalRestTimeRadioYes"
                  value="true"
                  checked={is_addRestTime}
                />
                <label
                  className="form-check-label"
                  htmlFor="inMemberTypeRadioYes"
                >
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inAdditionalRestTime"
                  id="inAdditionalRestTimeRadioNo"
                  value="false"
                  checked={!is_addRestTime}
                />
                <label
                  className="form-check-label"
                  htmlFor="inMemberTypeRadioNo"
                >
                  No
                </label>
              </div>
            </div>

            {this.renderTasks()}
            {this.renderChecklist()}
            {this.renderAddNewChecklist()}
            <a
              href={`/calendar/${id}/add-remark`}
              className="mt-3 mb-5 btn btn-primary btn-lg btn-block"
            >
              Add Remark For Butler
            </a>

            <div>
              <LabelGroup
                label="參考相片（Reference Photo）"
                titleOnly="true"
              />
              {aurl.map(function(url) {
                return (
                  <img alt="demo" src={url} style={{ maxWidth: "300px" }} />
                );
              })}
              {aurl.length ? null : <strong>No photo uploaded</strong>}
            </div>

            <a
              href={`/calendar/${id}/client-feedback`}
              className="mt-3 btn btn-success btn-lg btn-block"
            >
              View Client Feedback
            </a>
            <a
              href={`/calendar/${id}/upload`}
              className="my-2 btn btn-success btn-lg btn-block"
            >
              Upload photos for this order
            </a>
          </div>
        </div>

        {/*<pre>{JSON.stringify(order, null, '\t')}</pre>*/}
      </div>
    );
  }
}

export default SchedulerDetails;
