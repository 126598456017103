const initialState = {
  list: [],
  details: {}
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "SET_BUTLER_LIST":
      return {
        ...state,
        list: action.butler_list
      };
    case "SET_BUTLER_DETAILS":
      return {
        ...state,
        details: {
          ...state.details,
          [action.butler_id]: action.butler_details
        }
      };
    // case "SET_BUTLER_RATINGS":
    // 	return {
    // 		...state,
    // 		list: action.butler_ratings,
    // 	}
    default:
      return state;
  }
};
