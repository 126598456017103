import API from '../api'

const initialState = {
	token: localStorage.getItem("user_token"),
	refreshToken: localStorage.getItem("user_refreshToken")
}

API.defaults.headers.common['Authorization'] = `Bearer ${initialState.token}`;

// console.log("try to get new token");

if(initialState.refreshToken)
{
	API.put('/login', {refreshToken: initialState.refreshToken})
		.then( r => r.data)
		.then( token => {
			// console.log("new token", token)
			localStorage.setItem("user_token", token.token);
			localStorage.setItem("user_refreshToken", token.refreshToken);
		})
		.catch( _ => {

			localStorage.removeItem("user_token");
			localStorage.removeItem("user_refreshToken");
			window.location='/login';
		});
}

export default (state = initialState, action = {}) =>
{
	switch (action.type)
	{
		case "SET_USER":
			localStorage.setItem("user_token", action.token);
			localStorage.setItem("user_refreshToken", action.refreshToken);

			API.defaults.headers.common['Authorization'] = `Bearer ${action.token}`;

			return {
				...state,
				token: action.token,
				refreshToken: action.refreshToken
			}
		case "SET_UER_TOKEN":
			localStorage.setItem("user_token", action.token);

			API.defaults.headers.common['Authorization'] = `Bearer ${action.token}`;

			return {
				...state,
				token: action.token,
			}

		case "SET_UER_REFRESH_TOKEN":
			localStorage.setItem("user_refreshToken", action.refreshToken);
			return {
				...state,
				refreshToken: action.refreshToken,
			}

		case "REMOVE_USER_TOKENS":
			localStorage.removeItem("user_token");
			localStorage.removeItem("user_refreshToken");
			return {
				...state,
				token: null,
				refreshToken: null
			}

		default:
			return state
	}
}
