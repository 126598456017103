/**
 * Created by erikccoder on 24/5/2018.
 */

import React from "react";
import _ from "lodash";
import API from "../../api";
import { getLoadingOrFail } from "../../helper";
import moment from "moment";
import YearMonthPicker from "../../components/YearMonthPicker";

import { Dropdown } from "semantic-ui-react";
const types = [
  {
    key: "TRANSPORT",
    value: "TRANSPORT",
    text: "TRANSPORT"
  },
  {
    key: "EXTRA_FEES",
    value: "EXTRA_FEES",
    text: "EXTRA_FEES"
  },
  {
    key: "BONUS",
    value: "BONUS",
    text: "BONUS"
  }
];
class ButlerAllFeeNew extends React.Component {
  state = {
    error: null,
    butlers: [],
    butler_id: null,
    type: null,
    year: moment().year(),
    month: moment().month()
  };

  handleDropdownChange = (e, { value }) => {
    this.setState({ butler_id: value });
  };
  handleTypeChange = (e, { value }) => {
    this.setState({ type: value });
  };

  componentWillMount() {
    API.get("/butlers")
      .then(r => r.data)
      .then(butlers => this.setState({ butlers, firstLoad: true }))
      .catch(error => this.setState({ error, firstLoad: true }));
  }

  handleSumbit = () => {
    if (this.state.submitting) {
      return;
    }

    const { butler_id, amount, type, year, month } = this.state;

    if (!butler_id || !_.isNumber(amount) || !type) {
      return alert(
        "Please select valid butler,  input valid amount of deposit and the type of the cost"
      );
    }
    // console.log(this.state)
    const date = year + "-" + month + "-" + "02";
    API.post("/admin/butler/allfees", { butler_id, amount, type, month: date })
      .then(r => r.data)
      .then(d => {
        const search_params = `?start=${moment()
          .add(-15, "days")
          .format("YYYY-MM-DD")}&end=${moment()
          .add(1, "days")
          .format("YYYY-MM-DD")}`;
        window.location = `/butlers/allfees/${year}/${month}`;
      })
      .catch(error => this.setState({ error }));

    // this.setState({submitting: true})
  };
  render() {
    const fail = getLoadingOrFail(this.state);
    if (fail) {
      return fail;
    }

    const options = this.state.butlers.map(b => ({
      key: b.id,
      value: b.id,
      text: b.full_name
    }));
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ].map((m, i) => ({
      key: m,
      value: i + 1,
      text: m
    }));

    return (
      <div>
        <h1 className="mx-2">Add a new butler cost</h1>

        <div className="form-group m-3 pr-5 mr-5">
          <label>Butler</label>
          <Dropdown
            deburr
            fluid
            options={options}
            value={this.state.butler_id}
            search
            selection
            onChange={this.handleDropdownChange}
          />
        </div>
        <div className="form-group m-3 pr-5 mr-5">
          <label>Type</label>
          <Dropdown
            deburr
            fluid
            options={types}
            value={this.state.type}
            search
            selection
            onChange={this.handleTypeChange}
          />
        </div>

        <div className="form-group m-3 pr-5 mr-5">
          <label htmlFor="title">Amount</label>
          <input
            type="number"
            id="title"
            className="form-control"
            placeholder="Amount"
            onChange={({ target: { value } }) =>
              this.setState({ amount: _.toNumber(value) })
            }
          />
        </div>
        <div className="my-5">
          <div className="mx-2 d-flex">
            <Dropdown
              deburr
              fluid
              options={months}
              value={this.state.month}
              search
              selection
              onChange={(e, { value }) => {
                this.setState({ month: value });
              }}
              style={{ minWidth: 120 }}
            />
          </div>
          <div className="form-group mx-2">
            <input
              type="number"
              id="year"
              className="form-control ml-1"
              placeholder="Year"
              defaultValue={this.state.year}
              onChange={({ target: { value } }) =>
                this.setState({ year: value })
              }
            />
          </div>
        </div>
        <a href="#" className="m-3 btn btn-danger" onClick={this.handleSumbit}>
          {this.state.submitting ? "Submitting" : "Add"}
        </a>
        {/*<pre>{JSON.stringify(_.omit(this.state, "butlers"), null, '\t')}</pre>*/}
        {/*<pre>{JSON.stringify(this.state.butlers, null, '\t')}</pre>*/}
      </div>
    );
  }
}

export default ButlerAllFeeNew;
