import React from "react";

import SimpleTable from "../../components/SimpleTable";
import API from "../../api";
import YearMonthPicker from "../../components/YearMonthPicker";
import moment from "moment";
import { Link } from "react-router-dom";
import { Tab } from "semantic-ui-react";
import ButlerList from "./ButlerList";
import ButlerSalaryList from "./ButlerSalaryList";
const headers = [
  {
    text: "Name",
    key: "butler.user.full_name"
  },
  {
    text: "Amount",
    key: "amount"
  },
  {
    text: "Type",
    key: "type"
  }
];

class ButlerAllFeeList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKWord: "",
      sortDirection: null,
      sortColumn: null,
      data: [],
      loading: true
    };

    // const { year, month } = this.props.match.params;
    const now = moment();
    const year = now.year(),
      month = now.month() + 1;
    // console.log(this.props.match.params)
    const date = year + "-" + month + "-" + "02";
    API.get(`admin/butler/allfees/list/${date}`)
      .then(response => {
        console.log(response.data);
        this.setState({ data: response.data, loading: false });
      })
      .catch(error => {
        console.log("API ERROR:", error);
        this.setState({ loading: false });
      });
  }
  handleSearch = ({ year, month }) => {
    const date = year + "-" + month + "-" + "02";
    API.get(`admin/butler/allfees/list/${date}`)
      .then(response => {
        console.log(response.data);
        this.setState({ data: response.data, loading: false });
      })
      .catch(error => {
        console.log("API ERROR:", error);
        this.setState({ loading: false });
      });
  };

  headerOnClickForSort({ text, key }) {
    const { sortDirection, sortColumn } = this.state;
    if (sortColumn == key) {
      this.setState({
        sortColumn: key,
        sortDirection:
          sortDirection === "ascending" ? "descending" : "ascending"
      });
    } else {
      this.setState({
        sortColumn: key,
        sortDirection: "ascending"
      });
    }
  }

  render() {
    if (this.state.loading) return <div>Loading....</div>;
    // const { year, month } = this.props.match.params;
    const now = moment();
    const year = now.year(),
      month = now.month() + 1;

    return (
      <div>
        <div className="pb-5">
          <div className="my-5">
            <YearMonthPicker
              year={year}
              month={month}
              onChange={this.handleSearch}
            />
          </div>
          <a href="/butler/allfee/new" className="mx-3 btn btn-danger">
            ADD A NEW FEE
          </a>
          <SimpleTable
            className="mx-2"
            theader={headers}
            tbody={this.state.data}
            key_link_to="id"
            baseURL="/allfees/butlers/edit/:key"
          />
        </div>
      </div>
    );
  }
}
export default ButlerAllFeeList;
