import API from "../../api";
import { getLoadingOrFail } from "../../helper";
import React from "react";
import _ from "lodash-contrib";
import Moment from "moment";
import { extendMoment } from "moment-range";

import LabelGroup from "../../components/LabelGroup";

import Select from "react-select";
import "react-select/dist/react-select.css";

const moment = extendMoment(Moment);

const timestamps_to_range = (gap_minutes = 0) => ({
  start_timestamp,
  end_timestamp
}) =>
  moment.range(
    start_timestamp,
    moment(end_timestamp).add(gap_minutes, "minutes")
  );

const to_hours = minutes => minutes / 60;

const interval_to_range = ({ start: start_timestamp, end: end_timestamp }) =>
  timestamps_to_range()({ start_timestamp, end_timestamp });

const formatMembership = membership => {
  switch (membership.type) {
    case "CLUB":
      return "( Club )";
    case "SILVER":
      return "( Silver )";
    case "GLOD":
      return "( Gold )";
    case "NON_MEMBERSHIP":
      return "";
    case "GREEN_MEMBERSHIP":
      return "( Green )";
    default:
      return "";
  }
};

class SchedulerMultiNewOrder extends React.Component {
  state = {
    order: null,
    firstLoad: false,
    error: null,
    clients: [],
    client_credits: [],
    selected_client: null,
    selected_client_id: null,
    hold_order: false,
    suborders: [{}]
  };

  componentWillMount() {
    API.get(`/clients`)
      .then(r => r.data)
      .then(clients => this.setState({ clients, firstLoad: true }))
      .catch(error => this.setState({ firstLoad: true, error: error }));
    API.get("/client-credits")
      .then(r => r.data)
      .then(client_credits => this.setState({ client_credits }))
      .catch(error => this.setState({ error: error }));
  }

  min_order_hours(type) {
    switch (type) {
      case "pet_sitting":
      case "grocery":
        return 1;
      default:
        return 2;
    }
  }

  onClientSelect = ({ target: { value } }) => {
    const selected_client_id = _.toNumber(value);
    if (selected_client_id <= 0) {
      this.setState({ selected_client_id: false, selected_client: false });
      return;
    }

    this.setState({ selected_client_id });

    API.get(`/clients/${value}`)
      .then(r => r.data)
      .then(selected_client =>
        this.setState({ selected_client, needSchedules: true })
      )
      .catch(error => this.setState({ error }));
  };

  onDateSelect = ({ index, value }) => {
    const { suborders } = this.state;
    const updated_suborders = [...suborders];
    updated_suborders[index] = {
      ...suborders[index],
      date: value,
      needSchedules: true
    };
    this.setState({ suborders: updated_suborders });
  };

  onMinutesSelect = ({ index, value }) => {
    const { suborders } = this.state;
    const updated_suborders = [...suborders];
    const update = { minutes: value, needSchedules: value > 0 };
    updated_suborders[index] = { ...suborders[index], ...update };
    this.setState({ suborders: updated_suborders });
  };

  onIntervalSelect = ({ target: { value }, index }) => {
    const { suborders } = this.state;
    const updated_suborders = [...suborders];
    const availableIntervals = _.get(
      suborders[index],
      "selected_schedules.availableIntervals",
      []
    );

    const interval = _.get(availableIntervals, value);
    if (!interval) {
      return;
    }

    console.log("interval", interval);
    updated_suborders[index] = _.extend(suborders[index], {
      start_timestamp: interval.start,
      end_timestamp: interval.end
    });
    this.setState({ suborders: updated_suborders });
  };

  onButlerSelect = ({ target: { value }, index }) => {
    const schedule_id = _.toNumber(value);
    const { suborders } = this.state;
    const suborder = suborders[index];
    const selected_schedules = _.find(
      _.get(suborder, "scheduler.fitButlerSchedules"),
      { schedule_id }
    );
    const selected_butler_id = _.get(selected_schedules, "schedule_butler_id");
    const gap_minutes = _.get(selected_schedules, "gap_minutes", 0);

    const butler_selected_ranges = _.map(
      this.state.suborders.filter(
        order => order.selected_butler_id === selected_butler_id
      ),
      timestamps_to_range(gap_minutes)
    );
    const availableIntervals = _.get(
      selected_schedules,
      "availableIntervals"
    ).filter(
      interval =>
        !_.some(butler_selected_ranges, selected_range =>
          selected_range.overlaps(interval_to_range(interval))
        )
    );

    if (availableIntervals.length === 0) {
      console.error({
        message: "availableIntervals.length === 0",
        schedule_id,
        suborders,
        index,
        suborder,
        selected_schedules,
        selected_butler_id,
        gap_minutes,
        butler_selected_ranges,
        availableIntervals
      });
      return;
    }

    const {
      start: start_timestamp,
      end: end_timestamp
    } = availableIntervals[0];

    const updated_suborders = [...suborders];
    updated_suborders[index] = {
      ...suborders[index],
      start_timestamp,
      end_timestamp,
      selected_schedules,
      selected_butler_id
    };
    this.setState({ suborders: updated_suborders });
  };

  getCleaningTasks = suborder => {
    const tasks = [];
    let priority = 1;

    const otherRemark = _.get(suborder, "cleaningTasks.otherRemark");
    if (otherRemark) {
      tasks.push({
        type: "CLEANING",
        remark: otherRemark,
        priority: priority
      });
    }

    const firstRemark = _.get(suborder, "cleaningTasks.firstRemark");
    if (firstRemark) {
      tasks.push({
        type: "CLEANING",
        remark: firstRemark,
        priority: priority++
      });
    }
    const secondRemark = _.get(suborder, "cleaningTasks.secondRemark");
    if (secondRemark) {
      tasks.push({
        type: "CLEANING",
        remark: secondRemark,
        priority: priority++
      });
    }
    const thirdRemark = _.get(suborder, "cleaningTasks.thirdRemark");
    if (thirdRemark) {
      tasks.push({
        type: "CLEANING",
        remark: thirdRemark,
        priority: priority++
      });
    }
    const fourRemark = _.get(suborder, "cleaningTasks.fourRemark");
    if (fourRemark) {
      tasks.push({
        type: "CLEANING",
        remark: fourRemark,
        priority: priority++
      });
    }
    const fifthRemark = _.get(suborder, "cleaningTasks.fifthRemark");
    if (fifthRemark) {
      tasks.push({
        type: "CLEANING",
        remark: fifthRemark,
        priority: priority++
      });
    }

    return tasks;
  };

  handleSubmit = e => {
    e.preventDefault();
    const client_id = _.get(this.state, "selected_client_id");
    console.log(this.state.suborders);
    try {
      const data = this.state.suborders.map(
        ({
          selected_butler_id: butler_id,
          start_timestamp,
          end_timestamp,
          is_flexiable,
          pet_sitting,
          need_cleaning,
          need_food,
          need_closet,
          need_grocery,
          cookingTask,
          closetTasks,
          groceryTask,
          hold_order: is_hold,
          ...suborder
        }) => {
          if (!(start_timestamp && end_timestamp)) {
            throw new Error(`Slot not available for ${butler_id}`);
          }
          const tasks = [];

          const cleaningTasks = this.getCleaningTasks(suborder);

          console.log(
            `cleaningTasks: ${cleaningTasks &&
              JSON.stringify(cleaningTasks)} && need_cleaning ${need_cleaning}`
          );
          if (cleaningTasks && need_cleaning) {
            tasks.push.apply(tasks, cleaningTasks);
          }

          console.log(
            `cookingTask: ${cookingTask &&
              JSON.stringify(cookingTask)} && need_food ${need_food}`
          );
          if (cookingTask && need_food) {
            tasks.push(_.extend(cookingTask, { type: "COOKING" }));
          }

          console.log(
            `closetTasks: ${JSON.stringify(
              closetTasks
            )} && need_closet ${need_closet}`
          );
          if (closetTasks && need_closet) {
            tasks.push.apply(
              tasks,
              closetTasks.map(task => _.extend(task, { type: "LAUNDRY" }))
            );
          }

          console.log(
            `groceryTask: ${groceryTask &&
              JSON.stringify(
                groceryTask
              )} && groceryTask.remark: ${groceryTask &&
              groceryTask.remark} && need_grocery ${need_grocery}`
          );
          if (groceryTask && groceryTask.remark && need_grocery) {
            tasks.push({ type: "GROCERY", remark: groceryTask.remark });
          }

          console.log(`pet_sitting: ${pet_sitting}`);
          if (pet_sitting) {
            tasks.push({ type: "PET_SITTING" });
          }

          // check for metadata
          const all_metadata_exists =
            client_id && butler_id && start_timestamp && end_timestamp;
          if (!all_metadata_exists) {
            throw new Error(
              "something wrong with: client_id, butler_id, start_timestamp or end_timestamp"
            );
          }

          console.log(tasks);
          if (tasks.length === 0) {
            throw new Error("No tasks, please input all necessary data");
          }

          if (this.state.hold_order !== is_hold) {
            throw new Error(
              "[Logic Error]: this.state.hold_order !== suborder.is_hold"
            );
          }

          return {
            client_id,
            butler_id,
            start_timestamp,
            end_timestamp,
            tasks,
            is_flexiable,
            is_hold
          };
        }
      );

      const now = moment();
      const start = moment(now).format("YYYY-MM-DD");
      const end = moment(now)
        .add(30, "days")
        .format("YYYY-MM-DD");
      const search_params = { start, end };

      API.post("/orders/multiple", { data })
        .then(response => {
          window.location = `/calendar?${_.toQuery(search_params)}`;
        })
        .catch(error => {
          this.setState({ error });
          alert(error.response.data);
        });
    } catch (e) {
      window.alert(e.message);
      return false;
    }
  };

  fetchSchedule(index) {
    const { suborders, selected_client_id, pet_sitting } = this.state;
    const { date, minutes, needSchedules, fav_only } = suborders[index];
    const client_id = selected_client_id;

    const canFetchSchedules = ![
      date,
      minutes,
      selected_client_id,
      pet_sitting
    ].includes(undefined);

    if (canFetchSchedules && needSchedules && date !== "") {
      API.get(`/scheduler`, {
        params: { date, minutes, client_id, pet_sitting, fav_only }
      })
        .then(r => r.data)
        .then(scheduler => {
          // FIXME: this should actually be singular
          const selected_schedules = _.get(scheduler, "fitButlerSchedules.0");
          const selected_butler_id = _.get(
            selected_schedules,
            "schedule_butler_id"
          );
          const gap_minutes = _.get(selected_schedules, "gap_minutes", 0);

          const { suborders } = this.state;

          const butler_selected_ranges = suborders
            .filter(order => order.selected_butler_id === selected_butler_id)
            .map(timestamps_to_range(gap_minutes));

          const availableIntervals = _.reject(
            _.get(selected_schedules, "availableIntervals"),
            interval =>
              _.some(butler_selected_ranges, selected_range =>
                selected_range.overlaps(interval_to_range(interval))
              )
          );

          const updated_suborders = [...suborders];
          const { start: start_timestamp, end: end_timestamp } =
            availableIntervals[0] || {};

          updated_suborders[index] = {
            ...suborders[index],
            needSchedules: false,
            fitSchedules: [],
            scheduler,
            selected_schedules,
            selected_butler_id,
            start_timestamp,
            end_timestamp
          };

          this.setState({ suborders: updated_suborders });
        })
        .catch(error => this.setState({ error }));
    }
  }

  renderScheduleResults(index) {
    const suborder = this.state.suborders[index];
    const { date, minutes, selected_butler_id } = suborder;
    const fitButlerSchedules = _.get(suborder, "scheduler.fitButlerSchedules");

    if (!fitButlerSchedules || !date || !minutes) {
      return;
    }

    const scheduler = _.get(suborder, "scheduler");
    const availableIntervals = _.get(
      suborder,
      "selected_schedules.availableIntervals",
      []
    );
    const gap_minutes = _.get(suborder, "selected_schedules.gap_minutes", 0);

    // disable same date same timeslot
    const butler_selected_ranges = this.state.suborders
      .filter(order => order.selected_butler_id === selected_butler_id)
      .map(timestamps_to_range(gap_minutes));
    // do not include the schedule itself
    butler_selected_ranges.splice(index, 1);

    const no_slots_available = "No slots available";

    const availableIntervals_default = suborder.start_timestamp
      ? _.findIndex(availableIntervals, { start: suborder.start_timestamp })
      : undefined;

    const fitButlerSchedules_default = suborder.selected_butler_id
      ? _.get(
          _.find(fitButlerSchedules, {
            schedule_butler_id: suborder.select_butler_id
          }),
          "schedule_id"
        )
      : no_slots_available;

    return (
      <div className="row">
        <select
          className="form-control col-3 mx-3"
          value={availableIntervals_default}
          onChange={kwargs => this.onIntervalSelect({ ...kwargs, index })}
        >
          {availableIntervals.length > 0 ? (
            availableIntervals.map((interval, i) => (
              <option
                key={i}
                value={i}
                disabled={_.some(butler_selected_ranges, range =>
                  range.overlaps(interval_to_range(interval))
                )}
              >
                {moment(interval.start).format("HH:mm")} -{" "}
                {moment(interval.end).format("HH:mm")}
              </option>
            ))
          ) : (
            <option key={no_slots_available} value={undefined} disabled={true}>
              {no_slots_available}
            </option>
          )}
        </select>

        <select
          className="form-control col-3"
          value={fitButlerSchedules_default}
          onChange={kwargs => this.onButlerSelect({ ...kwargs, index })}
        >
          {fitButlerSchedules.map((c, index) => (
            <option key={index} value={c.schedule_id}>
              {" "}
              {c.full_name}{" "}
            </option>
          ))}
        </select>
      </div>
    );
  }

  renderClients() {
    let { clients, pet_sitting } = this.state;

    if (_.isUndefined(pet_sitting)) {
      return null;
    }
    clients = clients.map(c => {
      return { ...c, full_name: _.capitalize(c.full_name) };
    });
    clients = _.sortBy(clients, "full_name");
    return (
      <div className="my-3">
        <h5>Client</h5>

        <select className="form-control w-75" onChange={this.onClientSelect}>
          <option value={-1}>Select a client</option>
          {clients.map((c, index) => (
            <option key={index} value={c.id}>
              {" "}
              {c.full_name} {formatMembership(c.membership)}
            </option>
          ))}
        </select>
      </div>
    );
  }

  renderLabelValue(key, value) {
    return (
      <div key={key} className="mt-3">
        <h6>{key}</h6>
        <p>{value}</p>
      </div>
    );
  }

  renderClient() {
    let { selected_client, selected_client_id } = this.state;

    if (!selected_client_id) {
      return null;
    }

    if (!selected_client) {
      return (
        <div className="w-100 h-100 p-5 d-inline-block">
          <h1 className="text-center align-middle">Loading...</h1>
        </div>
      );
    }
    const email = _.get(selected_client, "email", "N/A");
    const membership = _.get(selected_client, "membership.type", "N/A");
    const contact_no = _.get(selected_client, "contact_no", "N/A");
    const address = _.get(selected_client, "address", "N/A");
    const district = _.get(selected_client, "district", "N/A");
    const entry_method = _.get(selected_client, "entry_method", "N/A");
    const remarks = _.get(selected_client, "remarks", []);
    const reference_photos = _.get(selected_client, "reference_photos", []);
    const client_id = _.get(selected_client, "id", "");
    var credit = "0";
    this.state.client_credits.forEach(element => {
      if (element.client.id == client_id) {
        credit = _.get(element, "total", "0");
      }
    });
    return (
      <div>
        {this.renderLabelValue("Email", email)}
        {this.renderLabelValue("Membership", membership)}
        {this.renderLabelValue("Credit", credit)}
        {this.renderLabelValue("Contact No", contact_no)}
        {this.renderLabelValue("Address", address)}
        {this.renderLabelValue("District", district)}
        {this.renderLabelValue("入屋方法（Entry Method）", entry_method)}

        <h5 className="mt-5">Remarks</h5>
        {remarks.map((r, i) =>
          this.renderLabelValue(`Remark ${i} - ${r.type}`, r.remark)
        )}
        <div className="mt-5">
          <h6>參考相片（Reference Photo）</h6>
          {reference_photos.map((r, i) => (
            <img
              style={{ width: "100%" }}
              key={"img-" + i}
              src={_.get(r, "image_url", "N/A")}
            />
          ))}
        </div>
      </div>
    );
  }

  renderInputs(index) {
    const { selected_client, date, minutes } = this.state;

    if (!selected_client) {
      return null;
    }

    const suborder = this.state.suborders[index];
    return (
      <div className="mt-3">
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="checkbox"
            id={`is_flexiable_${index}`}
            checked={suborder.is_flexiable}
            onChange={({ target: { value } }) => {
              const { suborders } = this.state;
              const updated_suborders = [...suborders];
              const suborder = suborders[index];
              const is_flexiable = !_.get(suborder, "is_flexiable", false);
              updated_suborders[index] = _.extend(suborder, { is_flexiable });
              this.setState({ suborders: updated_suborders });
            }}
          />
          <label className="form-check-label" htmlFor={`is_flexiable_${index}`}>
            Flexible Time
          </label>
        </div>

        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="checkbox"
            id={`is_fav_only_${index}`}
            checked={suborder.fav_only}
            onChange={({ target: { checked } }) => {
              const { suborders } = this.state;
              const updated_suborders = [...suborders];
              const suborder = suborders[index];
              updated_suborders[index] = {
                ...suborder,
                fav_only: !_.get(suborder, "fav_only", false),
                needSchedules: true
              };
              this.setState({ suborders: updated_suborders });
            }}
          />
          <label className="form-check-label" htmlFor={`is_fav_only_${index}`}>
            only search with top 3 favourite butlers
          </label>
        </div>

        <div className="mt-3 row">
          <div className="py-3 mr-3 col">
            <h6>日期（Date）</h6>
            <input
              className="form-control"
              type="date"
              min={moment().format("YYYY-MM-DD")}
              defaultValue={suborder.date}
              onChange={({ target: { value } }) =>
                this.onDateSelect({ index, value })
              }
            />
          </div>
          <div className="py-3 col">
            <h6>工作時間（Duration)</h6>
            <select
              className="form-control"
              value={suborder.minutes}
              onChange={({ target: { value } }) =>
                this.onMinutesSelect({ index, value })
              }
            >
              <option value={-1}>Select minutes</option>
              {_.range(12).map(i => (
                <option key={"m-" + i} value={(i + 1) * 30}>
                  {(i + 1) * 30} minutes
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }

  renderCleaningOptions() {
    return (
      <React.Fragment>
        <option value="" />
        <option value="Kitchen (廚房)">Kitchen (廚房)</option>
        <option value="Floor (地板)">Floor (地板)</option>
        <option value="Bedroom (睡房)">Bed room (睡房)</option>
        <option value="Bathroom (浴室)">Bathroom (浴室)</option>
        <option value="Living Room (客廳)">Living Room (客廳)</option>
        <option value="Other (其他)">Other (其他)</option>
      </React.Fragment>
    );
  }

  renderCleaningTasks(index) {
    const { pet_sitting, selected_client } = this.state;
    const suborder = this.state.suborders[index];
    const { selected_schedules, date, minutes } = suborder;

    if (!selected_schedules || !selected_client || !date || !minutes) {
      return;
    }

    return (
      <div className="border-bottom">
        <label className="h5">
          <input
            type="checkbox"
            className="mr-2"
            checked={_.get(suborder, "need_cleaning", false)}
            onChange={({ target: { checked } }) => {
              if (
                to_hours(suborder.minutes) < this.min_order_hours("cleaning")
              ) {
                alert(
                  `Sub-order duration (${
                    suborder.minutes
                  } minutes) < required (${this.min_order_hours(
                    "cleaning"
                  )} hours)`
                );
                return;
              }
              const { suborders } = this.state;
              const updated_suborders = [...suborders];
              updated_suborders[index] = _.extend(suborder, {
                need_cleaning: checked
              });
              this.setState({ suborders: updated_suborders });
            }}
          />
          Housekeeping （清潔及整理）
        </label>

        {suborder.need_cleaning ? (
          <div>
            <div className="row my-3">
              <div className="col-5">
                <h6>1st priority</h6>
                <select
                  className="form-control"
                  value={_.get(suborder, "cleaningTasks.firstRemark", "")}
                  onChange={({ target: { value } }) => {
                    const cleaningTasks = _.extend(
                      _.get(suborder, "cleaningTasks", {}),
                      { firstRemark: value }
                    );
                    const { suborders } = this.state;
                    const updated_suborders = [...suborders];
                    updated_suborders[index] = _.extend(suborder, {
                      cleaningTasks
                    });
                    this.setState({ suborders: updated_suborders });
                  }}
                >
                  {this.renderCleaningOptions()}
                </select>
              </div>
              <div className="col-5">
                <h6>2nd priority</h6>
                <select
                  className="form-control"
                  value={_.get(suborder, "cleaningTasks.secondRemark", "")}
                  onChange={({ target: { value } }) => {
                    const cleaningTasks = _.extend(
                      _.get(suborder, "cleaningTasks", {}),
                      { secondRemark: value }
                    );

                    const { suborders } = this.state;
                    const updated_suborders = [...suborders];
                    updated_suborders[index] = _.extend(suborder, {
                      cleaningTasks
                    });

                    this.setState({ suborders: updated_suborders });
                  }}
                >
                  {this.renderCleaningOptions()}
                </select>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-5">
                <h6>3rd priority</h6>
                <select
                  className="form-control"
                  value={_.get(suborder, "cleaningTasks.thirdRemark", "")}
                  onChange={({ target: { value } }) => {
                    const cleaningTasks = _.extend(
                      _.get(suborder, "cleaningTasks", {}),
                      { thirdRemark: value }
                    );

                    const { suborders } = this.state;
                    const updated_suborders = [...suborders];
                    updated_suborders[index] = _.extend(suborder, {
                      cleaningTasks
                    });

                    this.setState({ suborders: updated_suborders });
                  }}
                >
                  {this.renderCleaningOptions()}
                </select>
              </div>
              <div className="col-5">
                <h6>4th priority</h6>
                <select
                  className="form-control"
                  value={_.get(suborder, "cleaningTasks.fourRemark", "")}
                  onChange={({ target: { value } }) => {
                    const cleaningTasks = _.extend(
                      _.get(suborder, "cleaningTasks", {}),
                      { fourRemark: value }
                    );

                    const { suborders } = this.state;
                    const updated_suborders = [...suborders];
                    updated_suborders[index] = _.extend(suborder, {
                      cleaningTasks
                    });

                    this.setState({ suborders: updated_suborders });
                  }}
                >
                  {this.renderCleaningOptions()}
                </select>
              </div>
              <div className="col-5 my-3">
                <h6>5th priority</h6>
                <select
                  className="form-control"
                  value={_.get(suborder, "cleaningTasks.fifthRemark", "")}
                  onChange={({ target: { value } }) => {
                    const cleaningTasks = _.extend(
                      _.get(suborder, "cleaningTasks", {}),
                      { fifthRemark: value }
                    );

                    const { suborders } = this.state;
                    const updated_suborders = [...suborders];
                    updated_suborders[index] = _.extend(suborder, {
                      cleaningTasks
                    });

                    this.setState({ suborders: updated_suborders });
                  }}
                >
                  {this.renderCleaningOptions()}
                </select>
              </div>
            </div>
            <div className="my-3">
              <h6>其他（Special instructions:）</h6>
              <input
                className="form-control"
                type="text"
                value={_.get(suborder, "cleaningTasks.otherRemark", "")}
                onChange={({ target: { value } }) => {
                  const cleaningTasks = _.extend(
                    _.get(suborder, "cleaningTasks", {}),
                    { otherRemark: value }
                  );

                  const { suborders } = this.state;
                  const updated_suborders = [...suborders];
                  updated_suborders[index] = _.extend(suborder, {
                    cleaningTasks
                  });

                  this.setState({ suborders: updated_suborders });
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  closetManagementTasksChecked = ({ checked, value, index, suborder }) => {
    let closetTasks = _.get(suborder, "closetTasks", []);
    checked
      ? closetTasks.push({ remark: value })
      : (closetTasks = _.pullAllBy(closetTasks, [{ remark: value }], "remark"));
    const { suborders } = this.state;
    const updated_suborders = [...suborders];
    updated_suborders[index] = _.extend(suborder, { closetTasks });
    this.setState({ suborders: updated_suborders });
  };

  renderClosetManagementTasks(index) {
    const { selected_client, pet_sitting } = this.state;

    const suborder = this.state.suborders[index];
    const { date, minutes, selected_schedules } = suborder;

    if (!selected_schedules || !selected_client || !date || !minutes) {
      return;
    }

    return (
      <div className="my-3 border-bottom">
        <label className="h5">
          <input
            type="checkbox"
            className="mr-2"
            checked={suborder.need_closet || false}
            onChange={({ target: { checked } }) => {
              if (to_hours(suborder.minutes) < this.min_order_hours("closet")) {
                alert(
                  `Sub-order duration (${
                    suborder.minutes
                  } minutes) < required (${this.min_order_hours(
                    "closet"
                  )} hours)`
                );
                return;
              }
              const { suborders } = this.state;
              const updated_suborders = [...suborders];
              updated_suborders[index] = _.extend(suborder, {
                need_closet: checked
              });
              this.setState({ suborders: updated_suborders });
            }}
          />
          Closet Management (洗衣／衣服整理)
        </label>

        <div className="sr-only">
          <input
            className="form-control"
            type="text"
            onChange={({ target: { value } }) => {
              const closetTask = _.extend(_.get(this.state, "closetTask", {}), {
                remark: value
              });

              const { suborders } = this.state;
              const updated_suborders = [...suborders];
              updated_suborders[index] = _.extend(suborder, { closetTask });

              this.setState({ suborders: updated_suborders });
            }}
          />
        </div>

        {suborder.need_closet ? (
          <div>
            <div className="form-group">
              <label>
                <input
                  type="checkbox"
                  className="mr-2"
                  value="Wash (洗)"
                  checked={
                    (suborder.closetTasks &&
                      suborder.closetTasks.some(
                        item => item.remark === "Wash (洗)"
                      )) ||
                    false
                  }
                  onChange={({ target: { checked, value } }) =>
                    this.closetManagementTasksChecked({
                      checked,
                      value,
                      index,
                      suborder
                    })
                  }
                />
                Wash (洗)
              </label>

              <label>
                <input
                  type="checkbox"
                  className="mr-2"
                  value="Dry (乾衣機)"
                  checked={
                    (suborder.closetTasks &&
                      suborder.closetTasks.some(
                        item => item.remark === "Dry (乾衣機)"
                      )) ||
                    false
                  }
                  onChange={({ target: { checked, value } }) =>
                    this.closetManagementTasksChecked({
                      checked,
                      value,
                      index,
                      suborder
                    })
                  }
                />
                Dry (乾衣機)
              </label>

              <label>
                <input
                  type="checkbox"
                  className="mr-2"
                  value="Iron (燙衣)"
                  checked={
                    (suborder.closetTasks &&
                      suborder.closetTasks.some(
                        item => item.remark === "Iron (燙衣)"
                      )) ||
                    false
                  }
                  onChange={({ target: { checked, value } }) =>
                    this.closetManagementTasksChecked({
                      checked,
                      value,
                      index,
                      suborder
                    })
                  }
                />
                Iron (燙衣)
              </label>
            </div>
            <div className="my-3">
              <h6>其他（Special instructions:）</h6>
              <input
                className="form-control"
                type="text"
                value={
                  (suborder.closetTasks &&
                    suborder.closetTasks.some(item => item.priority) &&
                    suborder.closetTasks
                      .filter(item => item.priority === 1)[0]
                      .remark.split(": ")[1]) ||
                  ""
                }
                onChange={({ target: { value } }) => {
                  let closetTasks = _.get(suborder, "closetTasks", []);
                  closetTasks = _.pullAllBy(
                    closetTasks,
                    [{ priority: 1 }],
                    "priority"
                  );
                  if (value) {
                    closetTasks.push({
                      remark: "Special Instructions: " + value,
                      priority: 1
                    });
                  }
                  const { suborders } = this.state;
                  const updated_suborders = [...suborders];
                  updated_suborders[index] = _.extend(suborder, {
                    closetTasks
                  });
                  this.setState({ suborders: updated_suborders });
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  renderCookingTasks(index) {
    const suborder = this.state.suborders[index];
    return (
      <div className="my-3 py-3 border-bottom">
        <label className="h5">
          <input
            type="checkbox"
            className="mr-2"
            checked={suborder.need_food || false}
            onChange={({ target: { checked } }) => {
              if (to_hours(suborder.minutes) < this.min_order_hours("food")) {
                alert(
                  `Sub-order duration (${
                    suborder.minutes
                  } minutes) < required (${this.min_order_hours("food")} hours)`
                );
                return;
              }
              const { suborders } = this.state;
              const updated_suborders = [...suborders];
              updated_suborders[index] = _.extend(suborder, {
                need_food: checked
              });
              this.setState({ suborders: updated_suborders });
            }}
          />
          Food Preparation (準備食材)
        </label>

        {suborder.need_food ? (
          <div>
            <h6>Will you provide your own ingredients?</h6>

            <div className="row">
              <label className="col-3">
                <input
                  className="mr-2"
                  value={true}
                  type="radio"
                  name="ingredients"
                  checked={_.get(
                    suborder,
                    "cookingTask.meta.ingredients",
                    false
                  )}
                  onChange={({ target: { value } }) => {
                    const meta = _.extend(
                      _.get(suborder, "cookingTask.meta", {}),
                      { ingredients: true }
                    );
                    const cookingTask = _.extend(
                      _.get(suborder, "cookingTask", {}),
                      { meta }
                    );

                    const { suborders } = this.state;
                    const updated_suborders = [...suborders];
                    updated_suborders[index] = _.extend(suborder, {
                      cookingTask
                    });
                    this.setState({ suborders: updated_suborders });
                  }}
                />
                Yes
              </label>
              <label className="col-3">
                <input
                  className="mr-2"
                  value={false}
                  type="radio"
                  name="ingredients"
                  checked={_.get(
                    suborder,
                    "cookingTask.meta.ingredients",
                    true
                  )}
                  onChange={({ target: { value } }) => {
                    const meta = _.extend(
                      _.get(suborder, "cookingTask.meta", {}),
                      { ingredients: false }
                    );
                    const cookingTask = _.extend(
                      _.get(suborder, "cookingTask", {}),
                      { meta }
                    );
                    const { suborders } = this.state;
                    const updated_suborders = [...suborders];
                    updated_suborders[index] = _.extend(suborder, {
                      cookingTask
                    });
                    this.setState({ suborders: updated_suborders });
                  }}
                />
                No
              </label>
            </div>

            <div className="row my-3">
              <div className="col">
                <h6>Portion</h6>
                <select
                  className="form-control"
                  value={
                    suborder.cookingTask &&
                    suborder.cookingTask.meta &&
                    suborder.cookingTask.meta.portion
                  }
                  onChange={({ target: { value } }) => {
                    const meta = _.extend(
                      _.get(suborder, "cookingTask.meta", {}),
                      { portion: _.toNumber(value) }
                    );
                    const cookingTask = _.extend(
                      _.get(suborder, "cookingTask", {}),
                      { meta }
                    );
                    const { suborders } = this.state;
                    const updated_suborders = [...suborders];
                    updated_suborders[index] = _.extend(suborder, {
                      cookingTask
                    });
                    this.setState({ suborders: updated_suborders });
                  }}
                >
                  <option> </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                </select>
              </div>
              <div className="col">
                <h6>Target Budget</h6>
                <input
                  type="Number"
                  className="form-control"
                  step="10"
                  min="0"
                  value={_.get(suborder, "cookingTask.meta.budget", 0)}
                  onChange={({ target: { value } }) => {
                    const meta = _.extend(
                      _.get(suborder, "cookingTask.meta", {}),
                      { budget: _.toNumber(value) }
                    );
                    const cookingTask = _.extend(
                      _.get(suborder, "cookingTask", {}),
                      { meta }
                    );
                    const { suborders } = this.state;
                    const updated_suborders = [...suborders];
                    updated_suborders[index] = _.extend(suborder, {
                      cookingTask
                    });
                    this.setState({ suborders: updated_suborders });
                  }}
                />
              </div>
            </div>

            <h6>Dietary preferences</h6>
            <input
              className="form-control"
              type="text"
              value={_.get(suborder, "cookingTask.meta.preferences", "")}
              onChange={({ target: { value } }) => {
                const meta = _.extend(_.get(suborder, "cookingTask.meta", {}), {
                  preferences: value
                });
                const cookingTask = _.extend(
                  _.get(suborder, "cookingTask", {}),
                  { meta }
                );
                const { suborders } = this.state;
                const updated_suborders = [...suborders];
                updated_suborders[index] = _.extend(suborder, { cookingTask });
                this.setState({ suborders: updated_suborders });
              }}
            />
          </div>
        ) : null}
      </div>
    );
  }

  renderTaskForm(index) {
    const { pet_sitting, selected_client } = this.state;
    const suborder = this.state.suborders[index];

    const {
      selected_schedules,

      date,
      minutes
    } = suborder;

    if (!selected_schedules || !selected_client || !date || !minutes) {
      return;
    }

    return (
      <div>
        {pet_sitting || (
          <div className="my-3 py-3">
            {this.renderCleaningTasks(index)}
            {this.renderClosetManagementTasks(index)}
            {this.renderCookingTasks(index)}

            <div className="my-3 border-bottom">
              <label className="h5">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={suborder.need_grocery || false}
                  onChange={({ target: { checked } }) => {
                    if (
                      to_hours(suborder.minutes) <
                      this.min_order_hours("grocery")
                    ) {
                      alert(
                        `Sub-order duration (${
                          suborder.minutes
                        } minutes) < required (${this.min_order_hours(
                          "grocery"
                        )} hours)`
                      );
                      return;
                    }
                    const { suborders } = this.state;
                    const updated_suborders = [...suborders];
                    updated_suborders[index] = _.extend(suborder, {
                      need_grocery: checked
                    });
                    this.setState({ suborders: updated_suborders });
                  }}
                />
                Grocery (雜貨)
              </label>

              {suborder.need_grocery ? (
                <textarea
                  rows="4"
                  className="form-control"
                  type="text"
                  value={_.get(suborder, "groceryTask.remark", "")}
                  onChange={({ target: { value } }) => {
                    const groceryTask = _.extend(
                      _.get(suborder, "groceryTask", {}),
                      { remark: value }
                    );
                    const { suborders } = this.state;
                    const updated_suborders = [...suborders];
                    updated_suborders[index] = _.extend(suborder, {
                      groceryTask
                    });
                    this.setState({ suborders: updated_suborders });
                  }}
                />
              ) : null}
            </div>
          </div>
        )}
      </div>
    );
  }

  renderRightPanel() {
    return (
      <div>
        {this.state.selected_client && (
          <div
            className="px-3 mt-3"
            onClick={() => {
              const { suborders } = this.state;
              const updated_suborders = _.concat(suborders, {
                pet_sitting: this.state.pet_sitting,
                hold_order: this.state.hold_order,
                needSchedules: this.state.needSchedules
              });
              this.setState({ suborders: updated_suborders });
            }}
          >
            {" "}
            ✍🏻 Add New Order
          </div>
        )}
        {this.state.suborders.map((_, index) => {
          this.fetchSchedule(index);
          return (
            <div className="container" key={index}>
              <div className="row">
                <div className="col-10">
                  {this.renderInputs(index)}

                  {this.renderScheduleResults(index)}

                  {this.renderTaskForm(index)}
                </div>
                <div className="col-2 d-flex justify-content-center align-items-center">
                  {this.state.selected_client && (
                    <div
                      onClick={() => {
                        const { suborders } = this.state;
                        const updated_suborders = [...suborders];
                        updated_suborders.splice(index, 1);
                        this.setState({ suborders: updated_suborders });
                      }}
                    >
                       ❌ Delete Order
                    </div>
                  )}
                </div>
              </div>
              {this.state.selected_client && (
                <hr style={{ border: "1px solid red" }} />
              )}
            </div>
          );
        })}

        {this.state.suborders[0].selected_butler_id && (
          <a
            className=" my-5 btn btn-success btn-block"
            href="#"
            onClick={this.handleSubmit}
          >
            Submit
          </a>
        )}
      </div>
    );
  }

  handleFail = () => {
    const fail = this.state.error;
    this.setState({ error: null });
    console.log(fail);
    window.alert(fail);
  };

  render() {
    const { order, firstLoad, error } = this.state;

    if (this.state.error) {
      this.handleFail();
    } else if (this.state.firstLoad) {
      // <div className="w-100 h-100 p-5 d-inline-block">
      //   <h1 className="text-center align-middle">Loading...</h1>
      // </div>;
    }
    // console.log("from main render", this.state.suborders);
    return (
      <div className="container-fluid k-table py-3">
        <div className="form-group">
          <h5 htmlFor="inMemberType">NEW Multiple Order Type</h5>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="inMemberType"
              id="inOrderTypeRadioNo"
              onChange={() =>
                this.setState({
                  pet_sitting: false,
                  hold_order: false,
                  needSchedules: true,
                  suborders: [
                    {
                      pet_sitting: false,
                      hold_order: false,
                      needSchedules: true
                    }
                  ]
                })
              }
            />
            <label className="form-check-label" htmlFor="inOrderTypeRadioNo">
              Normal
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="inMemberType"
              id="holdOrderTypeRadioNo"
              onChange={() =>
                this.setState({
                  pet_sitting: false,
                  hold_order: true,
                  needSchedules: true,
                  suborders: [
                    {
                      pet_sitting: false,
                      hold_order: true,
                      needSchedules: true
                    }
                  ]
                })
              }
              /* NOTE: currently HOLD orders are not allowed for Multi-Order creation */
              // disabled={true}
            />
            <label className="form-check-label" htmlFor="holdOrderTypeRadioNo">
              Hold Order
            </label>
          </div>
        </div>

        <div className="row">
          <div className="col-5 border">
            {this.renderClients()}
            {this.renderClient()}
          </div>
          <div className="col-7 border">{this.renderRightPanel()}</div>
        </div>
      </div>
    );
  }
}

export default SchedulerMultiNewOrder;
