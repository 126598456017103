/**
 * Created by erikccoder on 29/5/2018.
 */

import React from "react";
import _ from "lodash";
import API from "../../api";
import { getLoadingOrFail } from "../../helper";
import Select from "react-select";
import "react-select/dist/react-select.css";

class ButlerBasicEdit extends React.Component {
  state = {
    firstLoad: false,
    error: null,
    gap_time: 30
  };
  componentWillMount() {
    const { butler_id } = this.props.match.params;

    API.get(`/butlers/${butler_id}`)
      .then(r => r.data)
      .then(butler => {
        const gap_time = _.get(butler, "gap_time");
        if (gap_time) {
          this.setState({ firstLoad: true, butler, gap_time });
        } else {
          this.setState({ firstLoad: true, butler });
        }
      })
      .catch(error => this.setState({ firstLoad: true, error: error }));
  }

  handleSubmit = e => {
    e.preventDefault();

    const { butler } = this.state;
    const comfort_with = _.get(butler, "comfort_with");
    const body = {
      comfort_with: []
    };
    _.map(this.refs, (el, key) => {
      if (key.search("is_ok_") > -1) {
        const value = el.checked;
        const comfort = key.replace("is_ok_", "").toUpperCase();
        const obj = _.find(comfort_with, { comfort });

        if (obj) {
          if (!value) {
            obj.status = "REMOVED";
            body.comfort_with.push({ ...obj });
          }
        } else if (value) {
          body.comfort_with.push({ comfort });
        }
      } else if (key == "lunch_break") {
        const current_value = el.checked;
        const value = _.get(butler, "lunch_break");
        if (current_value != value) {
          body[key] = current_value;
        }
      } else {
        const current_value = el.value;
        const value = _.get(butler, key);

        if (current_value && current_value != "" && current_value != value) {
          body[key] = current_value;
        }
      }
    });

    body["gap_time"] = this.state.gap_time;
    console.log(body);
    const { butler_id } = this.props.match.params;

    API.put(`/butlers/${butler_id}`, body)
      .then(r => (window.location = `/butlers/${butler_id}`))
      .catch(error => this.setState({ error: error }));
  };

  //TODO: Add tranportation fee
  render() {
    const fail = getLoadingOrFail(this.state);

    if (fail) {
      return fail;
    }

    const { butler } = this.state;
    const comfort_with = _.get(butler, "comfort_with");

    const gap_time = _.get(this.state, "gap_time");

    return (
      <div className="m-3">
        <h5>Butler Basic Info</h5>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label htmlFor="full_name">Full Name</label>
            <input
              className="form-control"
              id="full_name"
              ref="full_name"
              defaultValue={_.get(butler, "full_name")}
            />
          </div>
          <div class="form-group col-md-6">
            <label htmlFor="email">Email</label>
            <input
              className="form-control"
              id="email"
              ref="email"
              defaultValue={_.get(butler, "email")}
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label htmlFor="hkid">HK ID</label>
            <input
              className="form-control"
              id="hkid"
              ref="hkid"
              defaultValue={_.get(butler, "hkid")}
            />
          </div>
          <div class="form-group col-md-6">
            <label htmlFor="password">Change Password</label>
            <input
              className="form-control"
              id="password"
              ref="password"
              defaultValue=""
              placeholder="Input here to change password"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label htmlFor="hour_rate">Hour Rate</label>
            <input
              type="number"
              className="form-control"
              id="hour_rate"
              ref="hour_rate"
              defaultValue={_.get(butler, "hour_rate")}
            />
          </div>

          <div class="form-group col-md-6">
            <label htmlFor="contact_no">Contact No</label>
            <input
              className="form-control"
              id="contact_no"
              ref="contact_no"
              defaultValue={_.get(butler, "contact_no")}
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label htmlFor="address">Address</label>
            <input
              className="form-control"
              id="address"
              ref="address"
              defaultValue={_.get(butler, "address")}
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="misc">Any notes?</label>
            <textarea
              rows="4"
              type="text"
              className="form-control"
              id="misc"
              ref="misc"
              defaultValue={_.get(butler, "misc")}
            />
          </div>
          <div class="form-group col-md-6">
            <label htmlFor="bank_account">Bank Account</label>
            <input
              className="form-control"
              id="bank_account"
              ref="bank_account"
              defaultValue={_.get(butler, "bank_account")}
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label htmlFor="gap_time">Gap Time</label>
            <Select
              value={gap_time}
              options={[30, 60, 90, 120].map(v => ({
                label: `${v} mins`,
                value: v
              }))}
              onChange={({ value }) => this.setState({ gap_time: value })}
            />
          </div>

          <div class="form-group col-md-6">
            <label htmlFor="max_working_hours">Max Working Hours</label>
            <input
              className="form-control"
              id="max_working_hours"
              ref="max_working_hours"
              defaultValue={_.get(butler, "max_working_hours")}
            />
          </div>

          <div class="form-group col-md-6">
            <label htmlFor="guaranteed_hours">Guaranteed Hours</label>
            <input
              type="number"
              className="form-control"
              id="guaranteed_hours"
              ref="guaranteed_hours"
              defaultValue={_.get(butler, "guaranteed_hours")}
            />
          </div>
          <div class="form-group col-md-6">
            <label htmlFor="has_lunch_break">Lunch Break</label>
            <input
              type="checkbox"
              className="form-control custom_checkbox"
              ref="lunch_break"
              defaultChecked={_.get(butler, "lunch_break")}
            />
          </div>
        </div>

        <h6>Comfort with:</h6>
        <div className="form-row">
          <div class="form-group col-md-3">
            <label>
              <input
                type="checkbox"
                className="mr-2"
                ref="is_ok_dogs"
                defaultChecked={_.find(comfort_with, { comfort: "DOGS" })}
              />
              Dogs
            </label>
          </div>
          <div class="form-group col-md-3">
            <label>
              <input
                type="checkbox"
                className="mr-2"
                ref="is_ok_cats"
                defaultChecked={_.find(comfort_with, { comfort: "CATS" })}
              />
              Cats
            </label>
          </div>

          <div class="form-group col-md-3">
            <label>
              <input
                type="checkbox"
                className="mr-2"
                ref="is_ok_others"
                defaultChecked={_.find(comfort_with, { comfort: "OTHERS" })}
              />
              Others
            </label>
          </div>
        </div>
        <div className="form-row">
          <div class="form-group col-md-3">
            <label>
              <input
                type="checkbox"
                className="mr-2"
                ref="is_ok_cooking"
                defaultChecked={_.find(comfort_with, { comfort: "COOKING" })}
              />
              Cooking
            </label>
          </div>
        </div>

        <a
          className="btn btn-primary btn-block"
          href="#"
          onClick={this.handleSubmit}
        >
          Submit
        </a>

        {/*<pre>{JSON.stringify(this.state.butler, null, '\t')}</pre>*/}
      </div>
    );
  }
}

export default ButlerBasicEdit;
