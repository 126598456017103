import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { Table } from "semantic-ui-react";
import { Link } from "react-router-dom";

class SimpleTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    // const theader = _.get(this.props, "theader", []);
    // const tbody = _.get(this.props, "tbody", []);

    // const theader = [];
    // const tbody = [];

    const {
      theader,
      tbody,
      className,
      baseURL,
      key_link_to,

      direction,
      column,
      headerOnClickForSort
    } = this.props;

    return (
      <Table
        celled
        padded
        sortable
        selectable
        className={className}
        verticalAlign="bottom"
      >
        <Table.Header>
          <Table.Row>
            {theader.map(({ text, key }, i) => (
              <Table.HeaderCell
                className="bg-md-grey"
                key={i}
                singleLine
                sorted={column === key ? direction : null}
                onClick={
                  headerOnClickForSort
                    ? headerOnClickForSort.bind(this, { text, key })
                    : () => {}
                }
              >
                {text}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {tbody.map((c, y) => (
            <Table.Row
              verticalAlign="bottom"
              key={`row-${y}`}
              negative={c.isRed}
            >
              {theader.map(({ key }, i) => (
                <Table.Cell
                  key={y + "_" + i}
                  selectable={baseURL && key_link_to}
                >
                  {baseURL && key_link_to ? (
                    <Link to={baseURL.replace(/\:key/g, _.get(c, key_link_to))}>
                      {_.get(c, key, "N/A")}
                    </Link>
                  ) : (
                    _.get(c, key, "N/A") //.split("\n").map( t => (<p>{t}</p>))
                  )}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}

SimpleTable.defaultProps = {
  theader: [],
  tbody: [],
  className: ""
};

SimpleTable.propTypes = {
  theader: PropTypes.array.isRequired,
  tbody: PropTypes.array.isRequired
};

export default SimpleTable;
