/**
 * Created by erikccoder on 20/5/2018.
 * Modified by sariellau on 20/5/2018.
 */

import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import LabelGroup from "../../components/LabelGroup";

import { Button, Dropdown } from "semantic-ui-react";

import API from "../../api";
import { getLoadingOrFail } from "../../helper";

import "./custom.css";

import DatePicker from "react-datepicker";
import TimePicker from "react-bootstrap-time-picker";
import moment from "moment";
const format = "h:mm a";
const now = moment()
  .hour(0)
  .minute(0);

class NewButlerForm extends React.Component {
  state = {
    firstLoad: false,
    error: null,
    petsitter: "No",
    joinDate: moment(),
    referred_butler_list: [
      {
        full_name: "",
        id: 0
      }
    ],
    selected_districts: [],
    selected_referred_butler: [],
    selectedReferredButler: [],
    selectedGapTime: 30,
    selectedGuarenteedHours: 6,
    selectedMaxWorkingHours: 6,
    lunch_break: false,
    selectedDogs: 0,
    selectedCats: 0,
    selectedOthers: 0,
    selectedCooking: 0,
    sun_schedule: [{ from: "09:00", to: "23:00" }],
    mon_schedule: [{ from: "09:00", to: "23:00" }],
    tue_schedule: [{ from: "09:00", to: "23:00" }],
    wed_schedule: [{ from: "09:00", to: "23:00" }],
    thu_schedule: [{ from: "09:00", to: "23:00" }],
    fri_schedule: [{ from: "09:00", to: "23:00" }],
    sat_schedule: [{ from: "09:00", to: "23:00" }],
    referralRateRefs: []
  };

  handlePetSitterChange = e =>
    this.setState({ petsitter: e.currentTarget.value });

  handleJoinDateChange = date => {
    this.setState({
      joinDate: date
    });
  };
  handlePointer = () => {
    let add_butler = document.querySelector("#add_butler");
    let attach_ref_photo = document.querySelector("#attach_ref_photo");
    add_butler.style.cursor = "pointer";
    attach_ref_photo.style.cursor = "pointer";
  };

  handleAddReferredButler = () => {
    let add_butler_dropdown = document.querySelector("#add_butler_dropdown");
    add_butler_dropdown.className = "s_show";
  };

  handleAddReferredButlerChange = (e, data) => {
    var selected_id = [];
    var selected_obj = [];
    data.value.map(id => {
      selected_id.push({
        id: id,
        full_name: this.state.referred_butler_list.filter(e => e.key === id)[0]
          .text
      });
      selected_obj.push(id);
    });
    this.setState({
      selected_referred_butler: selected_id,
      selectedReferredButler: selected_obj
    });
    console.log(selected_id);
    console.log(selected_obj);
  };

  handleDistrictSelect = (e, data) => {
    this.setState({
      selected_districts: data.value
    });
    console.log(data.value);
  };

  handleGapTimeSelect = (e, data) => {
    this.setState({
      selectedGapTime: data.value
    });
  };

  handleGuarenteedHoursSelect = (e, data) => {
    this.setState({
      selectedGuarenteedHours: data.value
    });
  };

  handleMaxWorkingHoursSelect = (e, data) => {
    this.setState({
      selectedMaxWorkingHours: data.value
    });
  };

  handleDogsSelect = (e, data) => {
    this.setState({
      selectedDogs: data.value
    });
  };
  handlelunchBreakSelect = (e, data) => {
    this.setState({
      lunch_break: data.value
    });
  };
  handleCatsSelect = (e, data) => {
    this.setState({
      selectedCats: data.value
    });
  };
  handleOthersSelect = (e, data) => {
    this.setState({
      selectedOthers: data.value
    });
  };
  handleCookingSelect = (e, data) => {
    this.setState({
      selectedCooking: data.value
    });
  };

  getTimeFormart = timeInt => {
    var hour = Math.floor(timeInt / 3600);
    var timeStr = moment({
      hour: hour,
      minute: (timeInt - hour * 3600) / 60
    }).format("HH:mm");
    return timeStr;
  };

  onSunTimeFromChange(index, time) {
    var sun_schedule = this.state.sun_schedule.slice();
    var timeStr = this.getTimeFormart(time);
    sun_schedule[index].from = timeStr;
    this.setState({ sun_schedule: sun_schedule });
  }

  onSunTimeToChange(index, time) {
    var sun_schedule = this.state.sun_schedule.slice();
    var timeStr = this.getTimeFormart(time);
    sun_schedule[index].to = timeStr;
    this.setState({ sun_schedule: sun_schedule });
  }

  onMonTimeFromChange(index, time) {
    var mon_schedule = this.state.mon_schedule.slice();
    var timeStr = this.getTimeFormart(time);
    mon_schedule[index].from = timeStr;
    this.setState({ mon_schedule: mon_schedule });
  }

  onMonTimeToChange(index, time) {
    var mon_schedule = this.state.mon_schedule.slice();
    var timeStr = this.getTimeFormart(time);
    mon_schedule[index].to = timeStr;
    this.setState({ mon_schedule: mon_schedule });
  }

  onTueTimeFromChange(index, time) {
    var tue_schedule = this.state.tue_schedule.slice();
    var timeStr = this.getTimeFormart(time);
    tue_schedule[index].from = timeStr;
    this.setState({ tue_schedule: tue_schedule });
  }

  onTueTimeToChange(index, time) {
    var tue_schedule = this.state.tue_schedule.slice();
    var timeStr = this.getTimeFormart(time);
    tue_schedule[index].to = timeStr;
    this.setState({ tue_schedule: tue_schedule });
  }

  onWedTimeFromChange(index, time) {
    var wed_schedule = this.state.wed_schedule.slice();
    var timeStr = this.getTimeFormart(time);
    wed_schedule[index].from = timeStr;
    this.setState({ wed_schedule: wed_schedule });
  }

  onWedTimeToChange(index, time) {
    var wed_schedule = this.state.wed_schedule.slice();
    var timeStr = this.getTimeFormart(time);
    wed_schedule[index].to = timeStr;
    this.setState({ wed_schedule: wed_schedule });
  }

  onThuTimeFromChange(index, time) {
    var thu_schedule = this.state.thu_schedule.slice();
    var timeStr = this.getTimeFormart(time);
    thu_schedule[index].from = timeStr;
    this.setState({ thu_schedule: thu_schedule });
  }

  onThuTimeToChange(index, time) {
    var thu_schedule = this.state.thu_schedule.slice();
    var timeStr = this.getTimeFormart(time);
    thu_schedule[index].to = timeStr;
    this.setState({ thu_schedule: thu_schedule });
  }

  onFriTimeFromChange(index, time) {
    var fri_schedule = this.state.fri_schedule.slice();
    var timeStr = this.getTimeFormart(time);
    fri_schedule[index].from = timeStr;
    this.setState({ fri_schedule: fri_schedule });
  }

  onFriTimeToChange(index, time) {
    var fri_schedule = this.state.fri_schedule.slice();
    var timeStr = this.getTimeFormart(time);
    fri_schedule[index].to = timeStr;
    this.setState({ fri_schedule: fri_schedule });
  }

  onSatTimeFromChange(index, time) {
    var sat_schedule = this.state.sat_schedule.slice();
    var timeStr = this.getTimeFormart(time);
    sat_schedule[index].from = timeStr;
    this.setState({ sat_schedule: sat_schedule });
  }

  onSatTimeToChange(index, time) {
    var sat_schedule = this.state.sat_schedule.slice();
    var timeStr = this.getTimeFormart(time);
    sat_schedule[index].to = timeStr;
    this.setState({ sat_schedule: sat_schedule });
  }
  handleSunREMOVED(index) {
    var sun_schedule = this.state.sun_schedule.slice();
    sun_schedule.splice(index, 1);
    this.setState({ sun_schedule: sun_schedule });
  }
  handleMonREMOVED(index) {
    var mon_schedule = this.state.mon_schedule.slice();
    mon_schedule.splice(index, 1);
    this.setState({ mon_schedule: mon_schedule });
  }
  handleTueREMOVED(index) {
    var tue_schedule = this.state.tue_schedule.slice();
    tue_schedule.splice(index, 1);
    this.setState({ tue_schedule: tue_schedule });
  }
  handleWedREMOVED(index) {
    var wed_schedule = this.state.wed_schedule.slice();
    wed_schedule.splice(index, 1);
    this.setState({ wed_schedule: wed_schedule });
  }
  handleThuREMOVED(index) {
    var thu_schedule = this.state.thu_schedule.slice();
    thu_schedule.splice(index, 1);
    this.setState({ thu_schedule: thu_schedule });
  }
  handleFriREMOVED(index) {
    var fri_schedule = this.state.fri_schedule.slice();
    fri_schedule.splice(index, 1);
    this.setState({ fri_schedule: fri_schedule });
  }
  handleSatREMOVED(index) {
    var sat_schedule = this.state.sat_schedule.slice();
    sat_schedule.splice(index, 1);
    this.setState({ sat_schedule: sat_schedule });
  }

  handleSunChange = () => {
    let disable = document.querySelector("#sun_disable");
    let enable = document.querySelector("#sun_enable");
    if (this.refs.sun && this.refs.sun.checked) {
      disable.className = "s_hidden";
      enable.className = "s_show";
    } else {
      disable.className = "s_show";
      enable.className = "s_hidden";
    }
  };

  handleMonChange = () => {
    let disable = document.querySelector("#mon_disable");
    let enable = document.querySelector("#mon_enable");
    if (this.refs.mon && this.refs.mon.checked) {
      disable.className = "s_hidden";
      enable.className = "s_show";
    } else {
      disable.className = "s_show";
      enable.className = "s_hidden";
    }
  };

  handleTueChange = () => {
    let disable = document.querySelector("#tue_disable");
    let enable = document.querySelector("#tue_enable");
    if (this.refs.tue && this.refs.tue.checked) {
      disable.className = "s_hidden";
      enable.className = "s_show";
    } else {
      disable.className = "s_show";
      enable.className = "s_hidden";
    }
  };

  handleWedChange = () => {
    let disable = document.querySelector("#wed_disable");
    let enable = document.querySelector("#wed_enable");
    if (this.refs.wed && this.refs.wed.checked) {
      disable.className = "s_hidden";
      enable.className = "s_show";
    } else {
      disable.className = "s_show";
      enable.className = "s_hidden";
    }
  };

  handleThuChange = () => {
    let disable = document.querySelector("#thu_disable");
    let enable = document.querySelector("#thu_enable");
    if (this.refs.thu && this.refs.thu.checked) {
      disable.className = "s_hidden";
      enable.className = "s_show";
    } else {
      disable.className = "s_show";
      enable.className = "s_hidden";
    }
  };

  handleFriChange = () => {
    let disable = document.querySelector("#fri_disable");
    let enable = document.querySelector("#fri_enable");
    if (this.refs.fri && this.refs.fri.checked) {
      disable.className = "s_hidden";
      enable.className = "s_show";
    } else {
      disable.className = "s_show";
      enable.className = "s_hidden";
    }
  };

  handleSatChange = () => {
    let disable = document.querySelector("#sat_disable");
    let enable = document.querySelector("#sat_enable");
    if (this.refs.sat && this.refs.sat.checked) {
      disable.className = "s_hidden";
      enable.className = "s_show";
    } else {
      disable.className = "s_show";
      enable.className = "s_hidden";
    }
  };

  handleSunAddMore = () => {
    var sun_schedule = this.state.sun_schedule.slice();
    sun_schedule.push({ from: "09:00", to: "23:00" });
    this.setState({
      sun_schedule: sun_schedule
    });
  };

  handleMonAddMore = () => {
    var mon_schedule = this.state.mon_schedule.slice();
    mon_schedule.push({ from: "09:00", to: "23:00" });
    this.setState({
      mon_schedule: mon_schedule
    });
  };

  handleTueAddMore = () => {
    var tue_schedule = this.state.tue_schedule.slice();
    tue_schedule.push({ from: "09:00", to: "23:00" });
    this.setState({
      tue_schedule: tue_schedule
    });
  };

  handleWedAddMore = () => {
    var wed_schedule = this.state.wed_schedule.slice();
    wed_schedule.push({ from: "09:00", to: "23:00" });
    this.setState({
      wed_schedule: wed_schedule
    });
  };

  handleThuAddMore = () => {
    var thu_schedule = this.state.thu_schedule.slice();
    thu_schedule.push({ from: "09:00", to: "23:00" });
    this.setState({
      thu_schedule: thu_schedule
    });
  };

  handleFriAddMore = () => {
    var fri_schedule = this.state.fri_schedule.slice();
    fri_schedule.push({ from: "09:00", to: "23:00" });
    this.setState({
      fri_schedule: fri_schedule
    });
  };

  handleSatAddMore = () => {
    var sat_schedule = this.state.sat_schedule.slice();
    sat_schedule.push({ from: "09:00", to: "23:00" });
    this.setState({
      sat_schedule: sat_schedule
    });
  };

  handleSaveButtonClick = () => {
    const { butler_id } = this.props.match.params;
    const butler = _.get(this.props, `butlers.details.${butler_id}`);
    var comfort_with = [];
    if (butler_id > 0 && butler) {
      var dogs = false;
      var cats = false;
      var others = false;
      var cooking = false;
      var petsitter = false;
      butler.comfort_with.map(cObj => {
        if (cObj.comfort === "DOGS") {
          if (this.state.selectedDogs == 1) {
            //comfort_with.push(cObj);
          } else {
            comfort_with.push({ id: cObj.id, status: "REMOVED" });
          }
          dogs = true;
        }
        if (cObj.comfort === "CATS") {
          if (this.state.selectedCats == 1) {
            //comfort_with.push(cObj);
          } else {
            comfort_with.push({ id: cObj.id, status: "REMOVED" });
          }
          cats = true;
        }
        if (cObj.comfort === "OTHERS") {
          if (this.state.selectedOthers == 1) {
            //comfort_with.push(cObj);
          } else {
            comfort_with.push({ id: cObj.id, status: "REMOVED" });
          }
          others = true;
        }
        if (cObj.comfort === "COOKING") {
          if (this.state.selectedCooking == 1) {
            //comfort_with.push(cObj);
          } else {
            comfort_with.push({ id: cObj.id, status: "REMOVED" });
          }
          cooking = true;
        }
        if (cObj.comfort === "PET_SITTING") {
          if (this.state.petsitter === "Yes") {
            //comfort_with.push(cObj);
          } else {
            comfort_with.push({ id: cObj.id, status: "REMOVED" });
          }
          petsitter = true;
        }
      });
      if (!dogs) {
        if (this.state.selectedDogs == 1) {
          comfort_with.push({ comfort: "DOGS", remark: "" });
        }
      }
      if (!cats) {
        if (this.state.selectedCats == 1) {
          comfort_with.push({ comfort: "CATS", remark: "" });
        }
      }
      if (!others) {
        if (this.state.selectedOthers == 1) {
          comfort_with.push({ comfort: "OTHERS", remark: "" });
        }
      }
      if (!cooking) {
        if (this.state.selectedCooking == 1) {
          comfort_with.push({ comfort: "COOKING", remark: "" });
        }
      }
      if (!petsitter) {
        if (this.state.petsitter === "Yes") {
          comfort_with.push({ comfort: "PET_SITTING", remark: "" });
        }
      }
    } else {
      if (this.state.selectedDogs == 1) {
        comfort_with.push({ comfort: "DOGS", remark: "" });
      }
      if (this.state.selectedCats == 1) {
        comfort_with.push({ comfort: "CATS", remark: "" });
      }
      if (this.state.selectedOthers == 1) {
        comfort_with.push({ comfort: "OTHERS", remark: "" });
      }
      if (this.state.selectedCooking == 1) {
        comfort_with.push({ comfort: "COOKING", remark: "" });
      }
      if (this.state.petsitter === "Yes") {
        comfort_with.push({ comfort: "PET_SITTING", remark: "" });
      }
    }

    console.log(comfort_with);

    var referred_butlers = [];
    if (butler_id > 0 && butler) {
      var selected_list = this.state.selected_referred_butler.slice();
      butler.referred_butlers.map(bObj => {
        referred_butlers.push({ id: bObj.id, status: "REMOVED" });
      });
      selected_list.map((bObj, index) => {
        referred_butlers.push({
          referred_butler: bObj.id,
          referral_rate: this.state.referralRateRefs[index].value
        });
      });
    } else {
      this.state.selected_referred_butler.map((bObj, index) => {
        referred_butlers.push({
          referred_butler: bObj.id,
          referral_rate: this.state.referralRateRefs[index].value
        });
      });
    }

    console.log(referred_butlers);

    var schedule = [];
    if (this.refs.sun && this.refs.sun.checked) {
      var sun_schedule = this.state.sun_schedule.slice();
      sun_schedule.map(sObj => {
        schedule.push({
          week_day: "SUNDAY",
          start_time: sObj.from,
          end_time: sObj.to
        });
      });
    }
    if (this.refs.mon && this.refs.mon.checked) {
      var mon_schedule = this.state.mon_schedule.slice();
      mon_schedule.map(sObj => {
        schedule.push({
          week_day: "MONDAY",
          start_time: sObj.from,
          end_time: sObj.to
        });
      });
    }
    if (this.refs.tue && this.refs.tue.checked) {
      var tue_schedule = this.state.tue_schedule.slice();
      tue_schedule.map(sObj => {
        schedule.push({
          week_day: "TUESDAY",
          start_time: sObj.from,
          end_time: sObj.to
        });
      });
    }
    if (this.refs.wed && this.refs.wed.checked) {
      var wed_schedule = this.state.wed_schedule.slice();
      wed_schedule.map(sObj => {
        schedule.push({
          week_day: "WEDNESDAY",
          start_time: sObj.from,
          end_time: sObj.to
        });
      });
    }
    if (this.refs.thu && this.refs.thu.checked) {
      var thu_schedule = this.state.thu_schedule.slice();
      thu_schedule.map(sObj => {
        schedule.push({
          week_day: "THURSDAY",
          start_time: sObj.from,
          end_time: sObj.to
        });
      });
    }
    if (this.refs.fri && this.refs.fri.checked) {
      var fri_schedule = this.state.fri_schedule.slice();
      fri_schedule.map(sObj => {
        schedule.push({
          week_day: "FRIDAY",
          start_time: sObj.from,
          end_time: sObj.to
        });
      });
    }
    if (this.refs.sat && this.refs.sat.checked) {
      var sat_schedule = this.state.sat_schedule.slice();
      sat_schedule.map(sObj => {
        schedule.push({
          week_day: "SATURDAY",
          start_time: sObj.from,
          end_time: sObj.to
        });
      });
    }
    //
    //comfort_with: comfort_with,
    //referred_butlers: referred_butlers
    var params = {};
    if (butler_id > 0) {
      params = {
        full_name: this.refs.full_name.value,
        password: "5510",
        email: this.refs.email.value,
        contact_no: this.refs.contact_no.value,
        address: this.refs.address.value,
        hkid: this.refs.hkid.value,
        bank_account: this.refs.bank_account.value,
        hour_rate: this.refs.hour_rate.value,
        guaranteed_hours: this.state.selectedGuarenteedHours,
        gap_time: this.state.selectedGapTime,
        max_working_hours: this.state.selectedMaxWorkingHours,
        comfort_with: comfort_with,
        referred_butlers: referred_butlers,
        misc: this.refs.misc.value
      };
    } else {
      params = {
        full_name: this.refs.full_name.value,
        password: "5510",
        email: this.refs.email.value,
        contact_no: this.refs.contact_no.value,
        address: this.refs.address.value,
        hkid: this.refs.hkid.value,
        bank_account: this.refs.bank_account.value,
        hour_rate: this.refs.hour_rate.value,
        guaranteed_hours: this.state.selectedGuarenteedHours,
        gap_time: this.state.selectedGapTime,
        max_working_hours: this.state.selectedMaxWorkingHours,
        schedule: schedule,
        comfort_with: comfort_with,
        referred_butlers: referred_butlers,
        misc: this.refs.misc.value
      };
    }
    if (this.state.lunch_break == true) {
      params.lunch_break = true;
    } else {
      params.lunch_break = false;
    }
    console.log(JSON.stringify(params));
    console.log(JSON.stringify({ districts: this.state.selected_districts }));

    if (butler_id > 0) {
      API.put("/butlers/" + butler_id, JSON.stringify(params), {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*"
        }
      });
    } else if (this.state.selected_districts.length == 0) {
      window.alert("Please select Districts");
    } else {
      API.post("/butlers", JSON.stringify(params), {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*"
        }
      })
        .then(res => {
          console.log(res.data);
          console.log(res.data.butler.id);

          API.post(
            "/butlers/" + res.data.butler.id + "/districts",
            JSON.stringify({ districts: this.state.selected_districts }),
            {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
              }
            }
          )
            .then(res => {
              console.log(res.data);
              window.location.href = "/butlers";
            })
            .catch(error => {
              if (error.response) {
                if (error.response.data.message && error.response.data.err) {
                  window.alert(
                    error.response.data.message + error.response.data.err.detail
                  );
                }
                console.log(error.response.data);
              }
              console.log(error.config);
            });
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data.message && error.response.data.err) {
              window.alert(
                error.response.data.message + error.response.data.err.detail
              );
            }
          }
          console.log(error.config);
        });
    }
  };

  componentWillMount() {
    const { butler_id } = this.props.match.params;

    if (butler_id > 0) {
      API.get(`/butlers/${butler_id}`)
        .then(r => r.data)
        .then(butler_details => {
          this.setState({ firstLoad: true });
          this.props.SetButlerDetails({ butler_id, butler_details });
          console.log(butler_details);
          if (butler_details) {
            this.fillinButlerDetails(butler_details);
          }
        })
        .catch(error => this.setState({ firstLoad: true, error: error }));

      API.get(`/butlers/${butler_id}/summary`)
        .then(r => r.data)
        .then(summary => this.setState({ summary }))
        .catch(error => this.setState({ error: error }));
    }
    API.get("/butlers")
      .then(r => r.data)
      .then(clients => {
        console.log(clients);
        var b_list = [];
        clients.map(butler => {
          b_list.push({
            key: butler.id,
            text: butler.full_name,
            value: butler.id
          });
        });
        this.setState({ referred_butler_list: b_list });
        console.log(b_list);
      })
      .then(firstLoad => this.setState({ firstLoad: true }))
      .catch(error => this.setState({ firstLoad: true, error: error }));
  }
  fillinButlerDetails = butler => {
    this.refs.full_name.value = butler.full_name;
    this.refs.email.value = butler.email;
    this.refs.contact_no.value = butler.contact_no;
    this.refs.address.value = butler.address;
    this.refs.bank_account.value = butler.bank_account;
    this.refs.hour_rate.value = butler.hour_rate;
    this.refs.hkid.value = butler.hkid;
    this.refs.misc.value = butler.misc;
    this.setState({
      joinDate: moment(butler.joinDate),
      selectedGuarenteedHours: butler.guaranteed_hours,
      selectedMaxWorkingHours: butler.max_working_hours
    });
    var rates = [];
    butler.referred_butlers.map((rObj, index) => {
      var selected_id = this.state.selected_referred_butler.slice();
      var selected_obj = this.state.selectedReferredButler.slice();
      selected_id.push({
        id: rObj.referred_butler,
        full_name: rObj.referred_butler_name
      });
      selected_obj.push(rObj.referred_butler);
      rates.push(rObj.referral_rate);
      this.setState({
        selected_referred_butler: selected_id,
        selectedReferredButler: selected_obj
      });
    });
    var refs = this.state.referralRateRefs.slice();
    refs.map((input, index) => {
      input.value = rates[index];
    });
    butler.comfort_with.map(cObj => {
      if (cObj.comfort === "DOGS") {
        this.setState({
          selectedDogs: 1
        });
      }
      if (cObj.comfort === "CATS") {
        this.setState({
          selectedCats: 1
        });
      }
      if (cObj.comfort === "OTHERS") {
        this.setState({
          selectedOthers: 1
        });
      }
      if (cObj.comfort === "COOKING") {
        this.setState({
          selectedCooking: 1
        });
      }
      if (cObj.comfort === "PET_SITTING") {
        this.setState({
          petsitter: "Yes"
        });
      }
    });
  };

  render() {
    const { open, size } = this.state;
    const { butler_id } = this.props.match.params;
    const district_list = [
      {
        key: 0,
        text: "---Hong Kong Island",
        value: "---Hong Kong Island",
        disabled: true
      },
      { key: 1, text: "Wan Chai", value: "Wan Chai" },
      { key: 2, text: "Southern", value: "Southern" },
      { key: 3, text: "Eastern", value: "Eastern" },
      { key: 4, text: "Central & Western", value: "Central & Western" },
      { key: 0, text: "---Kowloon", value: "---Kowloon", disabled: true },
      { key: 5, text: "Yau Tsim Mong", value: "Yau Tsim Mong" },
      { key: 6, text: "Wong Tai Sin", value: "Wong Tai Sin" },
      { key: 7, text: "Sham Shui Po", value: "Sham Shui Po" },
      { key: 8, text: "Kwun Tong", value: "Kwun Tong" },
      { key: 9, text: "Kowloon City", value: "Kowloon City" },
      { key: 10, text: "Yuen Long", value: "Yuen Long" },
      { key: 11, text: "Tuen Mun", value: "Tuen Mun" },
      { key: 12, text: "Tsuen Wan", value: "Tsuen Wan" },
      { key: 13, text: "Tai Po", value: "Tai Po" },
      { key: 14, text: "Sha Tin", value: "Sha Tin" },
      { key: 15, text: "Sai Kung", value: "Sai Kung" },
      { key: 16, text: "North", value: "North" },
      { key: 17, text: "Kwai Tsing", value: "Kwai Tsing" },
      { key: 18, text: "Islands", value: "Islands" }
    ];

    return (
      <div className="container-fluid k-table py-3">
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.0.13/css/all.css"
          integrity="sha384-DNOHZ68U8hZfKXOrtjWvjxusGo9WQnrNx2sqG0tfsghAvtVlRW3tvkXWZh58N9jp"
          crossOrigin="anonymous"
        />
        <div className="bg-white border-bottom p-3 row">
          <Button
            as={Link}
            size="tiny"
            to="/butlers"
            color="orange"
            className="bg-red"
          >
            GO BACK
          </Button>
        </div>
        <div className="s_cardbox">
          <div className="s_row">
            <div className="s_profilepic">
              <img src="" />
            </div>
            <input
              className="s_textbox"
              ref="full_name"
              placeholder="Butler Name"
            />
          </div>

          <div className="s_row s_flex">
            <p className="s_itemlabel">Join Date</p>
            <DatePicker
              className="s_datepicker"
              selected={this.state.joinDate}
              onChange={this.handleJoinDateChange}
            />
          </div>

          <div className="s_row s_flex">
            <p className="s_itemlabel">Referred butler</p>
            <div className="s_referred_butler_list" id="referred_butler_list">
              <p>
                {this.state.selected_referred_butler.map((butler, index) => {
                  return (
                    <div className="s_row s_flex">
                      <p className="s_itemlabel">{butler.full_name}</p>
                      <span>
                        Referral Rate:{" "}
                        <input
                          className="s_textbox"
                          ref={ref =>
                            (this.state.referralRateRefs[index] = ref)
                          }
                        />
                      </span>
                    </div>
                  );
                })}
              </p>
            </div>
            <div id="add_butler_dropdown" className="s_hidden">
              <Dropdown
                placeholder="Referred Butler"
                fluid
                multiple
                selection
                options={this.state.referred_butler_list}
                value={this.state.selectedReferredButler}
                onChange={this.handleAddReferredButlerChange}
              />
            </div>
            <p
              id="add_butler"
              className="s_textbutton"
              onMouseEnter={this.handlePointer}
              onClick={this.handleAddReferredButler}
            >
              + Add more butler
            </p>
          </div>

          <div className="s_row s_flex">
            <p className="s_itemlabel">HKID</p>
            <input className="s_textbox" ref="hkid" placeholder="Y123456(7)" />
          </div>

          <div className="s_row s_flex">
            <p className="s_itemlabel">Bank account info</p>
            <input
              className="s_textbox"
              ref="bank_account"
              placeholder="HSBC 555 555555 555"
            />
          </div>

          <div className="s_row s_flex">
            <p className="s_itemlabel">Email</p>
            <input
              className="s_textbox"
              ref="email"
              placeholder="name@email.com"
            />
          </div>

          <div className="s_row s_flex">
            <p className="s_itemlabel">Contact No.</p>
            <input
              className="s_textbox"
              ref="contact_no"
              placeholder="Contact Number"
            />
          </div>

          <div className="s_row s_flex">
            <p className="s_itemlabel">Address</p>
            <input className="s_textbox" ref="address" placeholder="Address" />
          </div>
          <div className="s_row s_flex">
            <p className="s_itemlabel">Any notes?</p>
            <textarea
              rows="4"
              type="text"
              className="form-control"
              ref="misc"
              placeholder="Extra Notes"
            />
          </div>

          <div className={butler_id ? "s_hidden" : "s_show"}>
            <div className="s_row s_flex">
              <p className="s_itemlabel">District</p>
              <Dropdown
                placeholder="District"
                fluid
                multiple
                selection
                options={district_list}
                value={this.state.selected_districts}
                onChange={this.handleDistrictSelect}
              />
            </div>
          </div>

          <div className="s_row s_flex">
            <table className="s_dropdowntable">
              <tbody>
                <tr>
                  <th className="s_itemlabel">Dogs</th>
                  <th className="s_itemlabel">Cats</th>
                  <th className="s_itemlabel">Others</th>
                </tr>
                <tr>
                  <td>
                    <Dropdown
                      placeholder=""
                      fluid
                      selection
                      options={[
                        { text: "Okay", value: 1 },
                        { text: "No", value: 0 }
                      ]}
                      value={this.state.selectedDogs}
                      onChange={this.handleDogsSelect}
                    />
                  </td>
                  <td>
                    <Dropdown
                      placeholder=""
                      fluid
                      selection
                      options={[
                        { text: "Okay", value: 1 },
                        { text: "No", value: 0 }
                      ]}
                      value={this.state.selectedCats}
                      onChange={this.handleCatsSelect}
                    />
                  </td>
                  <td>
                    <Dropdown
                      placeholder=""
                      fluid
                      selection
                      options={[
                        { text: "Okay", value: 1 },
                        { text: "No", value: 0 }
                      ]}
                      value={this.state.selectedOthers}
                      onChange={this.handleOthersSelect}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="s_row s_flex">
            <p className="s_itemlabel">Cooking</p>
            <Dropdown
              placeholder=""
              fluid
              selection
              options={[
                { text: "Okay", value: 1 },
                { text: "No", value: 0 }
              ]}
              value={this.state.selectedCooking}
              onChange={this.handleCookingSelect}
            />
          </div>

          <div className="s_row s_flex">
            <p className="s_itemlabel">Gap time</p>
            <Dropdown
              placeholder=""
              fluid
              selection
              options={[
                { text: "30 minutes", value: 30 },
                { text: "1 hour", value: 60 }
              ]}
              value={this.state.selectedGapTime}
              onChange={this.handleGapTimeSelect}
            />
          </div>

          <div className="s_row s_flex">
            <table className="s_dropdowntable">
              <tbody>
                <tr>
                  <th className="s_itemlabel">Per hour rate</th>
                  <th className="s_itemlabel">Guaranteed hours</th>
                </tr>
                <tr>
                  <td>
                    <input className="s_textbox" ref="hour_rate" />
                  </td>
                  <td>
                    <Dropdown
                      placeholder=""
                      fluid
                      selection
                      options={[
                        { text: "0 hours", value: 0 },
                        { text: "1 hours", value: 1 },
                        { text: "2 hours", value: 2 },
                        { text: "3 hours", value: 3 },
                        { text: "4 hours", value: 4 },
                        { text: "5 hours", value: 5 },
                        { text: "6 hours", value: 6 },
                        { text: "7 hours", value: 7 },
                        { text: "8 hours", value: 8 }
                      ]}
                      value={this.state.selectedGuarenteedHours}
                      onChange={this.handleGuarenteedHoursSelect}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="s_row s_flex">
            <table className="s_dropdowntable">
              <tbody>
                <tr>
                  <th className="s_itemlabel">Max working hours</th>
                </tr>
                <tr>
                  <td>
                    <Dropdown
                      placeholder=""
                      fluid
                      selection
                      options={[
                        { text: "4 hours", value: 4 },
                        { text: "5 hours", value: 5 },
                        { text: "6 hours", value: 6 },
                        { text: "7 hours", value: 7 },
                        { text: "8 hours", value: 8 }
                      ]}
                      value={this.state.selectedMaxWorkingHours}
                      onChange={this.handleMaxWorkingHoursSelect}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="s_row s_flex">
            <table className="s_dropdowntable">
              <tbody>
                <tr>
                  <th className="s_itemlabel">Lunch Break</th>
                </tr>
                <tr>
                  <td>
                    <Dropdown
                      placeholder=""
                      fluid
                      selection
                      options={[
                        { text: "No", value: false },
                        { text: "Yes", value: true }
                      ]}
                      value={this.state.lunch_break}
                      onChange={this.handlelunchBreakSelect}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="s_row s_flex">
            <p
              className="s_textbutton"
              id="attach_ref_photo"
              onMouseEnter={this.handlePointer}
              onClick={this.handleAttachRefPhoto}
            >
              Attach reference photos
            </p>
            <div className="s_photos_list">
              <div className="rectangle-19" />
            </div>
          </div>

          <div className={butler_id ? "s_hidden" : "s_show"}>
            <div className="s_x_divider">&nbsp;</div>

            <table className="s_bottomtable">
              <tbody>
                <tr>
                  <td>
                    <div className="s_row s_flex">
                      <p className="s_itemlabel">Recurring Schedule</p>
                      <div className="s_inner_row">
                        <input
                          className="s_checkbox"
                          type="checkbox"
                          onChange={this.handleSunChange}
                          ref="sun"
                        />
                        <span className="s_checkbox_label">SUN</span>
                      </div>
                      <div className="s_inner_row">
                        <div id="sun_disable" className="s_show">
                          <p className="s_disable_text">Unavailable</p>
                        </div>
                        <div id="sun_enable" className="s_hidden">
                          {this.state.sun_schedule.map((tobj, index) => {
                            return (
                              <div className="s_flex_row">
                                <span>
                                  <TimePicker
                                    key={index}
                                    start="09:00"
                                    end="23:00"
                                    step={30}
                                    onChange={this.onSunTimeFromChange.bind(
                                      this,
                                      index
                                    )}
                                    value={this.state.sun_schedule[index].from}
                                  />
                                </span>
                                <span>To</span>
                                <span>
                                  <TimePicker
                                    key={index}
                                    start="09:00"
                                    end="23:00"
                                    step={30}
                                    onChange={this.onSunTimeToChange.bind(
                                      this,
                                      index
                                    )}
                                    value={this.state.sun_schedule[index].to}
                                  />
                                </span>
                                <span>
                                  <i
                                    className="fas fa-times-circle"
                                    onClick={this.handleSunREMOVED.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </span>
                              </div>
                            );
                          })}

                          <p
                            className="s_textbutton"
                            onMouseEnter={this.handlePointer}
                            onClick={this.handleSunAddMore}
                          >
                            + Add more
                          </p>
                        </div>
                      </div>

                      <div className="s_inner_row">
                        <input
                          className="s_checkbox"
                          type="checkbox"
                          onChange={this.handleMonChange}
                          ref="mon"
                        />
                        <span className="s_checkbox_label">MON</span>
                      </div>
                      <div className="s_inner_row">
                        <div id="mon_disable" className="s_show">
                          <p className="s_disable_text">Unavailable</p>
                        </div>
                        <div id="mon_enable" className="s_hidden">
                          {this.state.mon_schedule.map((tobj, index) => {
                            return (
                              <div className="s_flex_row">
                                <span>
                                  <TimePicker
                                    key={index}
                                    start="09:00"
                                    end="23:00"
                                    step={30}
                                    onChange={this.onMonTimeFromChange.bind(
                                      this,
                                      index
                                    )}
                                    value={this.state.mon_schedule[index].from}
                                  />
                                </span>
                                <span>To</span>
                                <span>
                                  <TimePicker
                                    key={index}
                                    start="09:00"
                                    end="23:00"
                                    step={30}
                                    onChange={this.onMonTimeToChange.bind(
                                      this,
                                      index
                                    )}
                                    value={this.state.mon_schedule[index].to}
                                  />
                                </span>
                                <span>
                                  <i
                                    className="fas fa-times-circle"
                                    onClick={this.handleMonREMOVED.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </span>
                              </div>
                            );
                          })}

                          <p
                            className="s_textbutton"
                            onMouseEnter={this.handlePointer}
                            onClick={this.handleMonAddMore}
                          >
                            + Add more
                          </p>
                        </div>
                      </div>

                      <div className="s_inner_row">
                        <input
                          className="s_checkbox"
                          type="checkbox"
                          onChange={this.handleTueChange}
                          ref="tue"
                        />
                        <span className="s_checkbox_label">TUE</span>
                      </div>
                      <div className="s_inner_row">
                        <div id="tue_disable" className="s_show">
                          <p className="s_disable_text">Unavailable</p>
                        </div>
                        <div id="tue_enable" className="s_hidden">
                          {this.state.tue_schedule.map((tobj, index) => {
                            return (
                              <div className="s_flex_row">
                                <span>
                                  <TimePicker
                                    key={index}
                                    start="09:00"
                                    end="23:00"
                                    step={30}
                                    onChange={this.onTueTimeFromChange.bind(
                                      this,
                                      index
                                    )}
                                    value={this.state.tue_schedule[index].from}
                                  />
                                </span>
                                <span>To</span>
                                <span>
                                  <TimePicker
                                    key={index}
                                    start="09:00"
                                    end="23:00"
                                    step={30}
                                    onChange={this.onTueTimeToChange.bind(
                                      this,
                                      index
                                    )}
                                    value={this.state.tue_schedule[index].to}
                                  />
                                </span>
                                <span>
                                  <i
                                    className="fas fa-times-circle"
                                    onClick={this.handleTueREMOVED.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </span>
                              </div>
                            );
                          })}

                          <p
                            className="s_textbutton"
                            onMouseEnter={this.handlePointer}
                            onClick={this.handleTueAddMore}
                          >
                            + Add more
                          </p>
                        </div>
                      </div>

                      <div className="s_inner_row">
                        <input
                          className="s_checkbox"
                          type="checkbox"
                          onChange={this.handleWedChange}
                          ref="wed"
                        />
                        <span className="s_checkbox_label">WED</span>
                      </div>
                      <div className="s_inner_row">
                        <div id="wed_disable" className="s_show">
                          <p className="s_disable_text">Unavailable</p>
                        </div>
                        <div id="wed_enable" className="s_hidden">
                          {this.state.wed_schedule.map((tobj, index) => {
                            return (
                              <div className="s_flex_row">
                                <span>
                                  <TimePicker
                                    key={index}
                                    start="09:00"
                                    end="23:00"
                                    step={30}
                                    onChange={this.onWedTimeFromChange.bind(
                                      this,
                                      index
                                    )}
                                    value={this.state.wed_schedule[index].from}
                                  />
                                </span>
                                <span>To</span>
                                <span>
                                  <TimePicker
                                    key={index}
                                    start="09:00"
                                    end="23:00"
                                    step={30}
                                    onChange={this.onWedTimeToChange.bind(
                                      this,
                                      index
                                    )}
                                    value={this.state.wed_schedule[index].to}
                                  />
                                </span>
                                <span>
                                  <i
                                    className="fas fa-times-circle"
                                    onClick={this.handleThuREMOVED.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </span>
                              </div>
                            );
                          })}

                          <p
                            className="s_textbutton"
                            onMouseEnter={this.handlePointer}
                            onClick={this.handleWedAddMore}
                          >
                            + Add more
                          </p>
                        </div>
                      </div>

                      <div className="s_inner_row">
                        <input
                          className="s_checkbox"
                          type="checkbox"
                          onChange={this.handleThuChange}
                          ref="thu"
                        />
                        <span className="s_checkbox_label">THU</span>
                      </div>
                      <div className="s_inner_row">
                        <div id="thu_disable" className="s_show">
                          <p className="s_disable_text">Unavailable</p>
                        </div>
                        <div id="thu_enable" className="s_hidden">
                          {this.state.thu_schedule.map((tobj, index) => {
                            return (
                              <div className="s_flex_row">
                                <span>
                                  <TimePicker
                                    key={index}
                                    start="09:00"
                                    end="23:00"
                                    step={30}
                                    onChange={this.onThuTimeFromChange.bind(
                                      this,
                                      index
                                    )}
                                    value={this.state.thu_schedule[index].from}
                                  />
                                </span>
                                <span>To</span>
                                <span>
                                  <TimePicker
                                    key={index}
                                    start="09:00"
                                    end="23:00"
                                    step={30}
                                    onChange={this.onThuTimeToChange.bind(
                                      this,
                                      index
                                    )}
                                    value={this.state.thu_schedule[index].to}
                                  />
                                </span>
                                <span>
                                  <i
                                    className="fas fa-times-circle"
                                    onClick={this.handleThuREMOVED.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </span>
                              </div>
                            );
                          })}

                          <p
                            className="s_textbutton"
                            onMouseEnter={this.handlePointer}
                            onClick={this.handleThuAddMore}
                          >
                            + Add more
                          </p>
                        </div>
                      </div>

                      <div className="s_inner_row">
                        <input
                          className="s_checkbox"
                          type="checkbox"
                          onChange={this.handleFriChange}
                          ref="fri"
                        />
                        <span className="s_checkbox_label">FRI</span>
                      </div>
                      <div className="s_inner_row">
                        <div id="fri_disable" className="s_show">
                          <p className="s_disable_text">Unavailable</p>
                        </div>
                        <div id="fri_enable" className="s_hidden">
                          {this.state.fri_schedule.map((tobj, index) => {
                            return (
                              <div className="s_flex_row">
                                <span>
                                  <TimePicker
                                    key={index}
                                    start="09:00"
                                    end="23:00"
                                    step={30}
                                    onChange={this.onFriTimeFromChange.bind(
                                      this,
                                      index
                                    )}
                                    value={this.state.fri_schedule[index].from}
                                  />
                                </span>
                                <span>To</span>
                                <span>
                                  <TimePicker
                                    key={index}
                                    start="09:00"
                                    end="23:00"
                                    step={30}
                                    onChange={this.onFriTimeToChange.bind(
                                      this,
                                      index
                                    )}
                                    value={this.state.fri_schedule[index].to}
                                  />
                                </span>
                                <span>
                                  <i
                                    className="fas fa-times-circle"
                                    onClick={this.handleFriREMOVED.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </span>
                              </div>
                            );
                          })}

                          <p
                            className="s_textbutton"
                            onMouseEnter={this.handlePointer}
                            onClick={this.handleFriAddMore}
                          >
                            + Add more
                          </p>
                        </div>
                      </div>

                      <div className="s_inner_row">
                        <input
                          className="s_checkbox"
                          type="checkbox"
                          onChange={this.handleSatChange}
                          ref="sat"
                        />
                        <span className="s_checkbox_label">SAT</span>
                      </div>
                      <div className="s_inner_row">
                        <div id="sat_disable" className="s_show">
                          <p className="s_disable_text">Unavailable</p>
                        </div>
                        <div id="sat_enable" className="s_hidden">
                          {this.state.sat_schedule.map((tobj, index) => {
                            return (
                              <div className="s_flex_row">
                                <span>
                                  <TimePicker
                                    key={index}
                                    start="09:00"
                                    end="23:00"
                                    step={30}
                                    onChange={this.onSatTimeFromChange.bind(
                                      this,
                                      index
                                    )}
                                    value={this.state.sat_schedule[index].from}
                                  />
                                </span>
                                <span>To</span>
                                <span>
                                  <TimePicker
                                    key={index}
                                    start="09:00"
                                    end="23:00"
                                    step={30}
                                    onChange={this.onSatTimeToChange.bind(
                                      this,
                                      index
                                    )}
                                    value={this.state.sat_schedule[index].to}
                                  />
                                </span>
                                <span>
                                  <i
                                    className="fas fa-times-circle"
                                    onClick={this.handleSatREMOVED.bind(
                                      this,
                                      index
                                    )}
                                  />
                                </span>
                              </div>
                            );
                          })}

                          <p
                            className="s_textbutton"
                            onMouseEnter={this.handlePointer}
                            onClick={this.handleSatAddMore}
                          >
                            + Add more
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td />
                </tr>
              </tbody>
            </table>
          </div>

          <div className={butler_id ? "s_show" : "s_hidden"}>
            <div className="s_row s_flex">
              <div className="my-5 s_margin_right">
                <a
                  className="btn btn-primary"
                  href={`/butlers/${butler_id}/districts`}
                >
                  {" "}
                  EDIT/VIEW DISTRICT{" "}
                </a>
              </div>
              <div className="my-5 s_margin_right">
                <a
                  className="btn btn-primary"
                  href={`/butlers/${butler_id}/schedule`}
                >
                  {" "}
                  EDIT/VIEW SCHEDULE{" "}
                </a>
              </div>

              <div className="my-5">
                <a
                  className="btn btn-primary"
                  href={`/butlers/${butler_id}/leaves`}
                >
                  {" "}
                  EDIT/VIEW LEAVES{" "}
                </a>
              </div>
            </div>
          </div>

          <div className="s_x_divider">&nbsp;</div>
          <div className="s_row s_flex">
            <Button
              onClick={this.handleSaveButtonClick}
              size="tiny"
              color="orange"
              className="bg-red"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const NewButlerFormConnected = connect(
  ({ butlers }) => ({ butlers }),
  dispatch => ({
    SetButlerDetails: ({ butler_id, butler_details }) => {
      dispatch({ type: "SET_BUTLER_DETAILS", butler_id, butler_details });
    }
  })
)(NewButlerForm);

export default NewButlerFormConnected;
