/**
 * Created by erikccoder on 21/5/2018.
 */

import React from "react";
import moment from "moment";
import _ from "lodash";
import SimpleTable from "../../components/SimpleTable";
import API from "../../api";
import { getLoadingOrFail } from "../../helper";
import { Pagination } from "semantic-ui-react";

class NotificationList extends React.Component {
  state = {
    notifications: [],
    firstLoad: false,
    error: null
  };

  handlePaginationChange = (e, { activePage }) => {
    // console.log(activePage, this.props.location);
    window.location = this.props.location.pathname + "?page=" + activePage;
  };

  componentWillMount() {
    const search = _.chain(this.props.location.search)
      .replace("?", "") // a=b454&c=dhjjh&f=g6hksdfjlksd
      .split("&") // ["a=b454","c=dhjjh","f=g6hksdfjlksd"]
      .map(_.partial(_.split, _, "=", 2)) // [["a","b454"],["c","dhjjh"],["f","g6hksdfjlksd"]]
      .fromPairs() // {"a":"b454","c":"dhjjh","f":"g6hksdfjlksd"}
      .value();

    const { page } = search;

    API.get(`/push-notifications`, { params: { page } })
      .then(r => r.data)
      .then(notifications => this.setState({ notifications, firstLoad: true }))
      .catch(error => this.setState({ error, firstLoad: true }));
  }

  render() {
    const fail = getLoadingOrFail(this.state);
    if (fail) {
      return fail;
    }

    const headers = [
      {
        text: "Date",
        key: "date_wo_tz"
      },
      {
        text: "Status",
        key: "status"
      },
      {
        text: "Title",
        key: "title"
      },
      {
        text: "Message",
        key: "message"
      }
    ];

    return (
      <div>
        <nav class="nav p-3">
          <a class="nav-link btn-danger" href="/notifications/new-form">
            New Push Notification
          </a>
        </nav>

        <h1>Notification List</h1>
        <SimpleTable
          className="mx-2"
          theader={headers}
          tbody={this.state.notifications.data.map(d => ({
            ...d,
            date_wo_tz: moment(d.send_at).format("DD/MM/YYYY HH:mm:ss")
          }))}
        />

        <Pagination
          className="mx-2 mt-5"
          defaultActivePage={this.state.notifications.page}
          totalPages={this.state.notifications.lastPage}
          onPageChange={this.handlePaginationChange}
        />
        {/*<pre>{JSON.stringify(this.state.notifications, null, '\t')}</pre>*/}
      </div>
    );
  }
}

export default NotificationList;
