import React from 'react'
import ClientForm from './ClientForm'
import { Button, Form, Label, Input, TextArea } from "semantic-ui-react";
import API from "../../api";
import _ from 'lodash';
import LabelGroup from '../../components/LabelGroup';
import moment from 'moment'

class ClientSummaryOrder extends React.Component
{ 
  
  componentDidMount(){
  }

  render(){
    const { summary } = this.props;
    if (summary == null) return null;

    const comingOrders = _.get(summary, 'comingOrders', 'N/A').sort(
      (a, b) => a.start_timestamp - b.start_timestamp
    );

    return (
      <div>
        <LabelGroup label="Orders" titleOnly="true" />
        {comingOrders.map(function(v) {
          const tasks = _.get(comingOrders, 'tasks', 'N/A');
          return (
            <div className="">
                <label className="s_itemlabel">{v.status}</label>
                <p className="card-text">
                  {moment(v.end_timestamp).diff(
                    moment(v.start_timestamp),
                    'hours'
                  ) + ' hours sessions'}{' '}
                </p>
                <p className="card-text">
                  {moment(v.start_timestamp).format('DD/MM/YYYY ha')} -{' '}
                  {moment(v.end_timestamp).format('ha')}{' '}
                </p>
                <p className="card-text">{v.butler.user.full_name} </p>

                {v.tasks.map(function(t) {
                  return <p className="card-text">{t.type}</p>;
                })}
              </div>
          );
        })}
      </div>
    );
  }

}

export default ClientSummaryOrder
