/**
 * Created by erikccoder on 17/4/2018.
 */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment'

import { Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux'

import DateRangePicer from '../../components/DateRangePicer'

import {
	Icon,
	Button,
	Dropdown,
	Input,
	Divider,
	Grid
} from 'semantic-ui-react'

import 'react-datepicker/dist/react-datepicker.css';
import DatePicker  from 'react-datepicker';


import API from '../../api'

class ButlerSalaryToolbar extends React.Component{

	state = {
		startDate: null,
		endDate: null
	}

	handleChangeStart = (startDate) => this.handleChange({ startDate })

	handleChangeEnd = (endDate) => this.handleChange({ endDate })

	handleChange = ({ startDate, endDate }) => 
	{
		console.log("ButlerSalaryToolbar::handleChange",startDate, endDate);
		startDate = startDate || this.state.startDate
		endDate = endDate || this.state.endDate

		if (startDate.isAfter(endDate)) {
			endDate = startDate
		}

		this.setState({ startDate, endDate })
	}

	render(){

		// console.log("ButlerSalaryToolbar::render",this.state);

		return(
			<div className="my-5">
				<DateRangePicer />
			</div>
		)

		return (
			<Grid padded verticalAlign="middle" className="bg-white border-bottom sticky-top">
				<Grid.Column width={2}>
					<Button
						size="tiny"
						color="orange"
						className="bg-red"
					>
						ISSUE NEW
					</Button >
				</Grid.Column>
				<Grid.Column width={14} textAlign="right">

					<div className="d-inline-flex mx-1 ui input">
						<DatePicker
							placeholderText="From Date"
							className="mx-1"
							selected={this.state.startDate}
							selectsStart
							startDate={this.state.startDate}
							endDate={this.state.endDate}
							onChange={this.handleChangeStart}
							dateFormat="DD/MM/YYY"
						/>
						<DatePicker
							placeholderText="To Date"
							className="mx-1"
							selected={this.state.endDate}
							selectsEnd
							startDate={this.state.startDate}
							endDate={this.state.endDate}
							onChange={this.handleChangeEnd}
							dateFormat="DD/MM/YYY"
						/>
					</div>

					<Input
						className="mx-1"
						label={<Button inverted className="bg-red" icon="search" name="search" />}
						labelPosition='right'
						onChange={ (e,d) => this.props.setSearchWords(d.value)}
						placeholder='Search Butler'
					/>

					<Button
						size="tiny"
						className="mx-1"
					>
						RESET
					</Button >
					<Button
						size="tiny"
						className="mx-1 bg-red"
						color="orange"
					>
						EXPORT
					</Button>
				</Grid.Column>
			</Grid>
		)
	}
};

ButlerSalaryToolbar.propTypes = {
	setSearchWords: PropTypes.func.isRequired,
}


export default ButlerSalaryToolbar;
