import React from 'react'
import ClientForm from './ClientForm'
import { Button, Form, Label, Input, TextArea } from "semantic-ui-react";
import API from "../../api";
import _ from 'lodash';
import LabelGroup from '../../components/LabelGroup';

class ClientSummary extends React.Component
{ 
  
  componentDidMount(){
  }

  render(){
    const { summary, client_id, client } = this.props;
    if (summary == null || client == null) return null;

    const deposit = _.get(summary, 'deposit', 'N/A');
    const totalRevenue = _.get(summary, 'total_revenue', 'N/A');
    const outstanding = _.get(summary, 'outstanding', 'N/A');
    const totalHours = _.get(summary, 'total_hours.hours', 'N/A');
    const taskCounts = _.get(summary, 'taskSummary', []);

    const butex = _.get(client, 'liked_butlers');

    return (
      <div>
        <div className="py-3">
          <div className="">
            <label className="s_itemlabel">Deposit (cannot go less than -$200)</label>
            <p>{!deposit && 0}</p>
          </div>
          <div>
            <label>Total Revenue</label>
            <p>{!totalRevenue && 0}</p>
          </div>
          <div>
            <label>Outstanding</label>
            <p>{outstanding}</p>
          </div>
          <div>
            <label>Total Hours</label>
            <p>{totalHours}</p>
          </div>
          <div>
            <label>Task Summary</label>
            {taskCounts.length>0?taskCounts.map(function(taskCount) {
              return <p>{taskCount.type + ':' + taskCount.count}</p>;
            }):'N/A'}
          </div>
        </div>
        <div>
          <LabelGroup label="Favourite Butler" titleOnly="true" />
          <ul className="list-group">
            {butex.length>0?butex.map(x => <li className="list-group-item">{x.full_name}</li>):'N/A'}
          </ul>
        </div>
      </div>
    );
  }

}

export default ClientSummary
