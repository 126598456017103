import React from "react";
import API from "../../api";
import { Dropdown } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";

class AddDiscountForm extends React.Component {
  state = {
    code: undefined,
    amount: undefined,
    type: undefined,
    expiry: "",
    submitting: null
  };
  handleChangeExpiryDate = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };
  handleSubmit = () => {
    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true });
    let { code, amount, type } = this.state;
    let formData = new FormData();
    formData.append("code", code);
    formData.append("amount", amount);
    formData.append("type", type);
    if (this.state.expiry != "") {
      formData.append("expiry", this.state.expiry);
    }
    API.post("/discount/", formData)
      .then(r => r.data)
      .then(_ => {
        this.setState({ submitting: null });
        window.location = "/discount";
      })
      .catch(error => this.setState({ submitting: null, error }));
  };
  handleDropdownChange = (e, { value }) => {
    this.setState({ type: value });
  };
  render() {
    return (
      <div className="p-1">
        <h1>New Discount Form</h1>

        <div className="form-group m-3">
          <label htmlFor="code">Code</label>
          <input
            id="code"
            className="form-control"
            placeholder="Code"
            onChange={({ target: { value } }) => this.setState({ code: value })}
          />
        </div>

        <div className="form-group m-3">
          <label htmlFor="m-bdy">Amount</label>
          <input
            id="code"
            className="form-control"
            placeholder="Amount"
            onChange={({ target: { value } }) =>
              this.setState({ amount: value })
            }
          />
        </div>

        <div className="form-group m-3 pr-5 mr-5">
          <label>Type</label>
          <Dropdown
            deburr
            fluid
            options={[
              { key: "ONCE", value: "ONCE", text: "ONCE" },
              { key: "EXPIRY", value: "EXPIRY", text: "EXPIRY" },
              {
                key: "ONCE_WITHIN_EXPIRY",
                value: "ONCE_WITHIN_EXPIRY",
                text: "ONCE_WITHIN_EXPIRY"
              }
            ]}
            value={this.state.type}
            search
            selection
            onChange={this.handleDropdownChange}
          />
        </div>

        {this.state.type == "EXPIRY" ||
        this.state.type == "ONCE_WITHIN_EXPIRY" ? (
          <div className="form-group m-3 pr-5 mr-5">
            <DateInput
              name="expiry"
              placeholder="Expiry"
              value={this.state.expiry}
              iconPosition="left"
              popupPosition="bottom left"
              onChange={this.handleChangeExpiryDate}
            />
          </div>
        ) : null}
        <nav class="nav p-3" id="sumbit">
          <a
            className="nav-link btn-danger "
            href="#sumbit"
            onClick={this.handleSubmit}
          >
            {this.state.submitting ? "Submitting" : "Submit"}
          </a>
        </nav>
      </div>
    );
  }
}

export default AddDiscountForm;
