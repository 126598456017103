/**
 * Created by erikccoder on 24/5/2018.
 */

import React from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker'

import {parseSearch} from '../helper';

class DateRangePicer extends React.Component{

	state={
		startDate: null,
		endDate: null,
	}

	componentWillMount() 
	{
		const search = parseSearch(window.location.search);
		const {start, end}= search;

		this.setState({
			startDate: moment(start),
			endDate: moment(end),
		})
	}

	handleChange = ({ startDate, endDate }) =>
	{
		startDate = startDate || this.state.startDate
		endDate = endDate || this.state.endDate

		if (startDate.isAfter(endDate)) {
			endDate = startDate
		}

		this.setState({ startDate, endDate })

		this.props.onChange && this.props.onChange({ startDate, endDate })

	}

	handleChangeStart = (startDate) => this.handleChange({ startDate })

	handleChangeEnd = (endDate) => this.handleChange({ endDate })

	render()
	{
		return (
		<div className="d-flex align-items-center">
			<h6 className="m-0">From</h6>
			<DatePicker
				className="mx-3"
				selected={this.state.startDate}
				selectsStart
				startDate={this.state.startDate}
				endDate={this.state.endDate}
				onChange={this.handleChangeStart}
				dateFormat="YYYY-MM-DD"
			/>
			<h6 className="m-0">To</h6>
			<DatePicker
				className="mx-3"
				selected={this.state.endDate}
				selectsEnd
				startDate={this.state.startDate}
				endDate={this.state.endDate}
				onChange={this.handleChangeEnd}
				dateFormat="YYYY-MM-DD"
			/>
		</div>
		)
	}
}

export default DateRangePicer