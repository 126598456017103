import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import moment from "moment";
import SimpleTable from "../../components/SimpleTable";
import API from "../../api";

import ButlerToolbar from "./ButlerToolbar";

const headers = [
  {
    text: "Name",
    key: "full_name"
  },
  {
    text: "Join Date",
    key: "join_date"
  },
  {
    text: "Pets",
    key: "pets"
  },
  {
    text: "Cooking",
    key: "cooking"
  },
  {
    text: "Rest Time",
    key: "rest_time"
  },
  {
    text: "Guarateed hours",
    key: "guaranteed_hours"
  },
  {
    text: "Per hour rate",
    key: "hour_rate"
  },
  {
    text: "Total Work Days",
    key: "total_work_days"
  },
  {
    text: "Total Salary",
    key: "total_salary"
  },
  {
    text: "Total Revenue",
    key: "total_revenue"
  }
];

class ButlerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKWord: "",
      firstLoad: false,
      error: null,
      sortDirection: null,
      sortColumn: null
    };

    API.get("/butlers")
      .then(r => r.data)
      .then(clients => this.props.SetButlerList(clients))
      .then(firstLoad => this.setState({ firstLoad: true }))
      .catch(error => this.setState({ firstLoad: true, error: error }));
  }

  headerOnClickForSort({ text, key }) {
    const { sortDirection, sortColumn } = this.state;
    if (sortColumn == key) {
      this.setState({
        sortColumn: key,
        sortDirection:
          sortDirection === "ascending" ? "descending" : "ascending"
      });
    } else {
      this.setState({
        sortColumn: key,
        sortDirection: "ascending"
      });
    }
  }

  render() {
    const {
      searchKWord,
      firstLoad,
      error,
      sortDirection,
      sortColumn
    } = this.state;

    if (!firstLoad) {
      return (
        <div className="w-100 h-100 p-5 d-inline-block">
          <h1 className="text-center align-middle">Loading...</h1>
        </div>
      );
    } else if (error) {
      return <pre>{JSON.stringify(error, null, "\t")}</pre>;
    }

    let butlers = this.props.butlers.list.filter(
      c =>
        searchKWord == "" ||
        _.get(c, "full_name", "")
          .toLowerCase()
          .search(searchKWord.toLowerCase()) > -1
    );

    butlers = _.sortBy(butlers, [sortColumn]);
    if (sortDirection == "descending") {
      butlers = butlers.reverse();
    }

    return (
      <div>
        <ButlerToolbar
          setSearchWords={searchKWord => this.setState({ searchKWord })}
        />
        <SimpleTable
          className="mx-2"
          theader={headers}
          tbody={butlers.map(b => ({
            ...b,
            pets:
              _.findIndex(
                b.comfort_with,
                c => ["DOGS", "CATS", "PET_SITTING"].indexOf(c.comfort) > -1
              ) > -1
                ? "Okay"
                : "No",
            cooking: _.findIndex(b.comfort_with, { comfort: "COOKING" })
              ? "Okay"
              : "No",
            total_work_days: _(b.days_have_work)
              .groupBy("date")
              .map((itm, key) => ({ date: key, size: itm.length }))
              .sumBy("size"),
            total_revenue: _.sumBy(_.get(b, "ordersOfMonth", []), "amount")
          }))}
          baseURL="/butlers/:key"
          key_link_to="id"
          direction={sortDirection}
          column={sortColumn}
          headerOnClickForSort={this.headerOnClickForSort.bind(this)}
        />

        {/*<pre>{JSON.stringify(butlers, null, '\t')}</pre>*/}
      </div>
    );
  }
}

const ButlerListConnected = connect(
  ({ butlers }) => ({ butlers }),
  dispatch => ({
    SetButlerList: butler_list => {
      dispatch({ type: "SET_BUTLER_LIST", butler_list });
    }
  })
)(ButlerList);

export default ButlerListConnected;
