/**
 * Created by erikccoder on 18/6/2018.
 */

/**
 * Created by erikccoder on 18/6/2018.
 */
import React, { Component } from "react";
import _ from "lodash";
import API from "../../api";
import { getLoadingOrFail } from "../../helper";

import { Button } from "semantic-ui-react";

class ClientReferencePhotos extends Component {
  state = {
    firstLoad: false,
    error: null,
    reference_photos: [],
    files: []
  };

  componentWillMount() {
    let { client_id, remark_id } = this.props.match.params;

    remark_id = _.toNumber(remark_id);

    API.get(`/clients/${client_id}`)
      .then(r => r.data)
      .then(client => {
        this.setState({
          firstLoad: true,
          reference_photos: _.get(client, "reference_photos", []),
          user_id: _.get(client, "user.id")
        });
      })
      .catch(error => this.setState({ firstLoad: true, error: error }));
  }

  handleSumbit = () => {
    const { client_id } = this.props.match.params;
    const formData = new FormData();
    this.setState({ uploading: true });
    if (!_.isUndefined(this.state.remark)) {
      formData.append("remark", this.state.remark);
    }
    for (let i = 0; i < this.state.files.length; i++) {
      formData.append("attachments", this.state.files[i]);
    }

    if (this.state.files.length > 0) {
      API.post(`/clients/${client_id}/referencePhotos`, formData)
        .then(res => {
          this.setState({ uploading: false });
          window.location.reload();
        })
        .catch(error => {
          this.setState({ uploading: false });
          this.setState({ error });
        });
    }
  };

  handleDelete = id => {
    if (!id) {
      return alert("Something Wrong! Contact system admin for that");
    }

    const { client_id } = this.props.match.params;

    API.delete(`/clients/${client_id}/referencePhotos/${id}`)
      .then(res => {
        window.location.reload();
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  render() {
    const fail = getLoadingOrFail(this.state);
    if (fail) {
      return fail;
    }

    // console.log("API", API.defaults.headers.common, API.defaults.baseURL);

    const { user_id } = this.state;
    const { client_id } = this.props.match.params;

    return (
      <div className="m-2">
        <div className="bg-white border-bottom p-3 row">
          <Button
            size="tiny"
            href={`/clients/${client_id}`}
            color="orange"
            className="bg-red"
          >
            GO BACK
          </Button>
        </div>

        <h1>Reference Photos</h1>
        <div className="row border-bottom">
          {this.state.reference_photos
            .map(r => {
              let url = _.get(r, "image_url", "");
              if (url.search("http") > -1) {
                return { ...r, url };
              }
              return {
                ...r,
                url
              };
            })
            .map(({ url, remark, id }) => (
              <div className="col-3 border p-3" key={url}>
                <a href={url} target="_blank">
                  <img
                    className="w-100"
                    src={url}
                    style={{ objectFit: "cover", height: 200 }}
                  />
                </a>

                <p className="my-3">{remark}</p>

                <a
                  href="#"
                  className="btn btn-danger my-2"
                  onClick={_ => this.handleDelete(id)}
                >
                  Delete
                </a>
              </div>
            ))}
        </div>

        <h1>Add Reference Photo</h1>

        <div className="form-group col-6">
          <input
            type="file"
            accept="image/*"
            onChange={e => this.setState({ files: e.target.files })}
          />
        </div>

        <div className="form-group col-6">
          <label htmlFor="remark">Remark</label>
          <input
            className="form-control"
            placeholder="Remark"
            id="remark"
            onChange={({ target: { value } }) =>
              this.setState({ remark: value })
            }
          />

          <a
            href="#"
            className="btn btn-danger my-4"
            onClick={this.handleSumbit}
          >
            {this.state.uploading
              ? "Uploading"
              : this.state.files.length > 0
                ? "Submit"
                : "Select file first!"}
          </a>
        </div>

        {/*<pre>{JSON.stringify(this.state, null, '\t')}</pre>*/}
      </div>
    );
  }
}

export default ClientReferencePhotos;
