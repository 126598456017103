import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import {
  Button,
  Image,
  Header,
  Segment,
  Grid,
  Label,
  Table
} from "semantic-ui-react";

import LabelGroupGrey from "../../components/LabelGroupGrey";
import LabelGroup from "../../components/LabelGroup";
import MoreSimpleTable from "../../components/MoreSimpleTable";

import API from "../../api";

const headers = [
  {
    text: "Order date",
    key: "date"
  },
  {
    text: "Day",
    key: "day"
  },
  {
    text: "Time",
    key: "time"
  },
  {
    text: "Hours",
    key: "hours"
  },
  {
    text: "Client",
    key: "client.full_name"
  },
  {
    text: "Cleaning",
    key: "cleaning"
  },
  {
    text: "Cooking",
    key: "cooking"
  },
  {
    text: "Laundry",
    key: "laundry"
  },
  {
    text: "Gorcery",
    key: "gorcery"
  },
  {
    text: "Pet sitting",
    key: "pet_sitting"
  },
  {
    text: "Other",
    key: "N/A"
  },
  {
    text: "Total amount",
    key: "total_amount"
  }
];
const leaveHeaders = [
  {
    text: "Leave Dates",
    key: "leave"
  }
];

class ButlerSalaryDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      butler_name: "nothing"
    };
    const { butler_id, year, month } = props.match.params;

    API.get(`/salary/${butler_id}/${year}/${month}`)
      .then(r => r.data)
      .then(salary_details =>
        this.props.SetSalaryDetails({
          butler_id,
          salary_details
        })
      );
  }

  backHandler = e => {
    const { history } = this.props;

    if (history.length > 2) {
      e.preventDefault();
      history.goBack();
    }
  };
  export = () => {
    const { butler_id, year, month } = this.props.match.params;
    const salary = _.get(this.props, `salaries.details.${butler_id}`);
    const full_name = _.get(salary, "user.full_name");
    API.get(`/admin/butler/salary/export/${year}/${month}/${butler_id}`, {
      responseType: "blob"
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", full_name + ".pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        console.log("error", err);
        alert(
          "Some error occurred. Please try again later or contact App It Team"
        );
      });
  };
  render() {
    const { butler_id, year, month } = this.props.match.params;
    console.log(this.props);
    const salary = _.get(this.props, `salaries.details.${butler_id}`);
    const tempOrders = _.get(salary, "orders", []).map(o => ({
      ...o,
      total_amount: _.get(salary, "hour_rate", -1) * _.get(0, "hours", 0)
    }));
    const orders = _.sortBy(tempOrders, order => {
      return order.start_timestamp;
    });
    const leaves = _.get(salary, "leaves", []);
    const bulterDeposits = _.get(salary, "bulterDeposits", []);
    const details = JSON.stringify(salary);
    const total_salary_bonus =
      salary &&
      _.sumBy(salary.butlerAllFees, o => {
        return o.amount;
      });
    const total_guaranteed_salary =
      _.get(salary, "guaranteed_hours") *
      _.get(salary, "hour_rate") *
      (_.get(salary, "days_need_working", []).length -
        _.get(salary, "leaves", []).length);
    const total_actual_salary =
      _.get(salary, "total_work_hours") * _.get(salary, "hour_rate");
    const transport =
      salary &&
      _.filter(salary.butlerAllFees, fee => {
        return (
          month - 1 == moment(fee.month).month() &&
          year == moment(fee.month).year() &&
          fee.type == "TRANSPORT"
        );
      });
    const transportAmount = transport ? _.sumBy(transport, "amount") : 0;
    const bonus =
      salary &&
      _.filter(salary.butlerAllFees, fee => {
        return (
          month - 1 == moment(fee.month).month() &&
          year == moment(fee.month).year() &&
          fee.type == "BONUS"
        );
      });
    const bonusAmount = bonus ? _.sumBy(bonus, "amount") : 0;
    const extra_fee =
      salary &&
      _.filter(salary.butlerAllFees, fee => {
        return (
          month - 1 == moment(fee.month).month() &&
          year == moment(fee.month).year() &&
          fee.type == "EXTRA_FEES"
        );
      });
    const extraAmount = extra_fee ? _.sumBy(extra_fee, "amount") : 0;
    return (
      <div>
        <div className="bg-white border-bottom p-3 sticky-top">
          <Button
            as={Link}
            to={`/butlers/salary/${year}/${month}`}
            color="orange"
            className="bg-red"
            onClick={this.backHandler}
          >
            GO BACK
          </Button>
          <Button
            color="orange"
            className="bg-red"
            onClick={() => this.export()}
          >
            EXPORT
          </Button>
        </div>

        <div
          className="m-3 p-3 bg-white border"
          style={{ position: "relative", maxWidth: 1020 }}
        >
          <Image
            floated="right"
            width="202"
            src="/images/butlerlogo-black.png"
          />

          <Header as="h2">Salary Slip</Header>

          <Segment secondary size="small" style={{ maxWidth: 385 }}>
            <p>Here is your salary slip. Thank you!</p>
          </Segment>

          <Grid columns={4}>
            <Grid.Column
              as={LabelGroupGrey}
              label="Issue date"
              text={moment([year, month - 1])
                .endOf("month")
                .format("DD/MM/YYYY")}
            />
            <Grid.Column
              as={LabelGroupGrey}
              label="Salary for"
              text={moment([year, month - 1]).format("MMMM")}
            />

            <Grid.Column
              as={LabelGroupGrey}
              label="Joined Date"
              text={moment(_.get(salary, "user.created_at")).format(
                "YYYY-MM-DD"
              )}
            />
          </Grid>

          <div className="my-3" style={{ maxWidth: 336 }}>
            <LabelGroupGrey
              label="Butler name"
              text={_.get(salary, "user.full_name")}
            />
          </div>

          <Grid columns={4}>
            <Grid.Column
              as={LabelGroup}
              label="Email"
              text={_.get(salary, "user.email")}
            />

            <Grid.Column
              as={LabelGroup}
              label="Contact no."
              text={_.get(salary, "user.contact_no")}
            />
          </Grid>

          <div className="my-3">
            <LabelGroup label="Address" text={_.get(salary, "address")} />
          </div>

          {/*<Grid>*/}
          {/*<Grid.Column*/}
          {/*width={3}*/}
          {/*as={LabelGroup}*/}
          {/*label="Work hours"*/}
          {/*text={_.get(salary, "total_work_hours") + " hours"}*/}
          {/*/>*/}
          {/*<Grid.Column*/}
          {/*width={2}*/}
          {/*as={LabelGroup}*/}
          {/*label="Orders"*/}
          {/*text={_.get(salary, "orders.length")}*/}
          {/*/>*/}

          {/*<Grid.Column*/}
          {/*width={3}*/}
          {/*as={LabelGroup}*/}
          {/*label="Per hour rate"*/}
          {/*text={_.get(salary, "hour_rate")}*/}
          {/*/>*/}
          {/*<Grid.Column*/}
          {/*width={3}*/}
          {/*as={LabelGroup}*/}
          {/*label="Guaranteed hours"*/}
          {/*text={*/}
          {/*_.get(salary, "guaranteed_hours", 0) **/}
          {/*(_.get(salary, "days_need_working", []).length -*/}
          {/*_.get(salary, "leaves", []).length) +*/}
          {/*" hours"*/}
          {/*}*/}
          {/*/>*/}
          {/*</Grid>*/}
          {/*<Grid>*/}
          {/*<Grid.Column*/}
          {/*width={3}*/}
          {/*as={LabelGroup}*/}
          {/*label="Total Actual Salary"*/}
          {/*text={*/}
          {/*"$" +*/}
          {/*_.get(salary, "total_work_hours") * _.get(salary, "hour_rate")*/}
          {/*}*/}
          {/*/>*/}
          {/*<Grid.Column*/}
          {/*width={3}*/}
          {/*as={LabelGroup}*/}
          {/*label="Total Guaranteed Salary"*/}
          {/*text={*/}
          {/*"$" +*/}
          {/*_.get(salary, "guaranteed_hours") **/}
          {/*_.get(salary, "hour_rate") **/}
          {/*(_.get(salary, "days_need_working", []).length -*/}
          {/*_.get(salary, "leaves", []).length)*/}
          {/*}*/}
          {/*/>*/}
          {/*</Grid>*/}

          {/*<div className="my-3 sr-only">*/}
          {/*<Button size="small pl-0 bg-none color-red">Compensation</Button>*/}
          {/*<Segment*/}
          {/*compact*/}
          {/*secondary*/}
          {/*className="bg-white pl-0 mt-0 py-0 border-0 shadow-none"*/}
          {/*>*/}
          {/*{_.get(salary, "compensation", "N/A")}*/}
          {/*</Segment>*/}
          {/*</div>*/}

          <div className="my-3 sr-only">
            <Button size="small pl-0 bg-none color-red">Deduction</Button>
            <Segment
              compact
              secondary
              className="bg-white pl-0 mt-0 py-0 border-0 shadow-none"
            >
              {_.get(salary, "deduction", "N/A")}
            </Segment>
          </div>

          <div className="my-3">
            <h6 className="text-danger">Deposit</h6>
            <div className="mb-3">
              <p className="ml-1">
                Total: ${_.sumBy(bulterDeposits, "amount")}
              </p>
            </div>
          </div>
          <div className="my-3">
            <h6 className="text-danger">Bank Account</h6>
            <div className="mb-3">
              <p className="ml-1">
                {_.get(salary, "bankAccount.data.value", "N/A")}
              </p>
            </div>
          </div>
          <div>
            <div className="my-3">
              <h6 className="text-danger">Misc. Fees</h6>
              <div className="mb-3">
                {salary &&
                  salary.butlerAllFees &&
                  salary.butlerAllFees.map(fee => {
                    if (month - 1 === moment(fee.month).month()) {
                      return (
                        <p className="ml-1">
                          {fee.type} {fee.amount}
                        </p>
                      );
                    }
                  })}
              </div>
            </div>
          </div>

          <MoreSimpleTable
            theader={leaveHeaders}
            tbody={leaves.map(l => ({
              ...l,
              leave:
                l.status == "ADDED" &&
                moment(l.start_date).format("DD/MM/YYYY") +
                  " to " +
                  moment(l.end_date).format("DD/MM/YYYY")
            }))}
          />
          <MoreSimpleTable
            theader={headers}
            tbody={orders
              .map(o => ({
                ...o,
                date: moment(o.start_timestamp).format("DD/MM/YYYY"),
                day: moment(o.start_timestamp).format("dddd"),
                hours: moment(o.end_timestamp).diff(o.start_timestamp, "hours"),
                time: moment(o.start_timestamp).format("hh:mm a"),
                cleaning: o.tasks.indexOf("CLEANING") > -1,
                cooking: o.tasks.indexOf("COOKING") > -1,
                laundry: o.tasks.indexOf("LAUNDRY") > -1,
                gorcery: o.tasks.indexOf("GROCERY") > -1,
                pet_sitting: o.tasks.indexOf("PET_SITTING") > -1,
                other: o.tasks.indexOf("OTHER") > -1
              }))
              .map(o => ({
                ...o,
                total_amount: _.get(salary, "hour_rate", -1) * o.hours
              }))
              .sort((a, b) => a.time > b.time)
              .sort((a, b) => a.date > b.date)}
          />

          {/*<div className="d-flex justify-content-end">*/}
          {/*<Button size="small pl-0 bg-none color-red">TOTAL SALARY</Button>*/}
          {/*<Segment secondary className="bg-white py-1 my-2">*/}
          {/*${" "}*/}
          {/*{Math.max(total_actual_salary, total_guaranteed_salary) +*/}
          {/*transportAmount +*/}
          {/*bonusAmount +*/}
          {/*extraAmount}*/}
          {/*</Segment>*/}
          {/*</div>*/}
        </div>
      </div>
    );
  }
}

const ButlerSalaryDetailsConnected = connect(
  ({ salaries }) => ({ salaries }),
  dispatch => ({
    SetSalaryDetails: ({ butler_id, salary_details }) => {
      dispatch({ type: "SET_SALARY_DETAILS", butler_id, salary_details });
    }
  })
)(ButlerSalaryDetails);

export default ButlerSalaryDetailsConnected;
