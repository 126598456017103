import React from "react";
import _ from "lodash";

import SimpleTable from "../../components/SimpleTable";
import API from "../../api";
import { Button, Grid } from "semantic-ui-react";

const headers = [
  {
    text: "Code",
    key: "code"
  },
  {
    text: "Type",
    key: "type"
  },
  {
    text: "Expiry",
    key: "expiry"
  },
  {
    text: "Amount",
    key: "amount"
  },
  {
    text: "Is Valid",
    key: "is_valid"
  }
];

class Discount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKWord: "",
      firstLoad: false,
      error: null,
      sortDirection: null,
      sortColumn: null,
      discount: undefined
    };
  }

  componentWillMount() {
    API.get("/discount").then(res => {
      console.log(res.data);
      if (res.data) {
        this.setState({ discount: res.data, firstLoad: true });
      } else {
        this.setState({ firstLoad: true });
      }
    });
  }

  headerOnClickForSort({ text, key }) {
    const { sortDirection, sortColumn } = this.state;
    if (sortColumn == key) {
      this.setState({
        sortColumn: key,
        sortDirection:
          sortDirection === "ascending" ? "descending" : "ascending"
      });
    } else {
      this.setState({
        sortColumn: key,
        sortDirection: "ascending"
      });
    }
  }

  render() {
    const {
      searchKWord,
      firstLoad,
      error,
      sortDirection,
      sortColumn
    } = this.state;

    if (!firstLoad) {
      return (
        <div className="w-50 h-100 p-5 d-inline-block">
          <h1 className="text-center align-middle">Loading...</h1>
        </div>
      );
    } else if (error) {
      return <pre>{JSON.stringify(error, null, "\t")}</pre>;
    }
    let discount = this.state.whatsnew;
    discount = _.sortBy(discount, [sortColumn]);

    if (sortDirection == "descending") {
      discount = discount.reverse();
    }

    return (
      <div>
        <Grid
          padded
          verticalAlign="middle"
          className="bg-white border-bottom sticky-top"
        >
          <Grid.Column width={2}>
            <Button
              as="a"
              size="tiny"
              color="orange"
              className="bg-red"
              href="/discount/add"
            >
              NEW
            </Button>
          </Grid.Column>
        </Grid>
        <div className="mx-2 my-3">
          <SimpleTable
            theader={headers}
            tbody={this.state.discount}
            baseURL="/discount/:key"
            key_link_to="id"
            direction={sortDirection}
            column={sortColumn}
            headerOnClickForSort={this.headerOnClickForSort.bind(this)}
          />
        </div>
      </div>
    );
  }
}
export default Discount;
