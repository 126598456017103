/**
 * Created by erikccoder on 31/5/2018.
 */
import React from "react";
import moment from "moment";
import _ from "lodash";
import Select from "react-select";
import SimpleTable from "../../components/SimpleTable";
import API from "../../api";
import { getLoadingOrFail, parseSearch } from "../../helper";
import { Pagination } from "semantic-ui-react";
import LabelGroup from "../../components/LabelGroup";

class OrderOutstandingFeeDetails extends React.Component {
  state = {
    firstLoad: false,
    error: null
  };

  componentWillMount() {
    // start, end
    const search = parseSearch(this.props.location.search);
    const { start, end } = search;

    const { butler_outstanding_fee_id } = this.props.match.params;

    API.get(`/orders-outstanding-fee/${butler_outstanding_fee_id}`)
      .then(r => r.data)
      .then(order => this.setState({ order, firstLoad: true }))
      .catch(error => this.setState({ error, firstLoad: true }));
  }

  handleSubmit = e => {
    const agree = window.confirm(
      "Once you approve this, the client deposit will be deduct, are you sure?"
    );

    if (!agree) {
      return;
    }

    const { butler_outstanding_fee_id } = this.props.match.params;

    API.post(`/orders-outstanding-fee/${butler_outstanding_fee_id}`, {
      status: "APPROVED",
      client: this.state.order[0].order.client.user,
      butler: this.state.order[0].order.butler.user
    })
      .then(_ => (window.location = "/butler-outstanding-fee"))
      .catch(error => this.setState({ error }));
  };

  handleReject = e => {
    const { butler_outstanding_fee_id } = this.props.match.params;

    API.post(`/orders-outstanding-fee/${butler_outstanding_fee_id}`, {
      status: "REJECTED",
      client: this.state.order[0].order.client.user,
      butlerName: this.state.order[0].order.butler.user
    })
      .then(_ => (window.location = "/butler-outstanding-fee"))
      .catch(error => this.setState({ error }));
  };

  renderOrderDetails() {
    const start_timestamp = _.get(this.state, "order.0.order.start_timestamp");
    const end_timestamp = _.get(this.state, "order.0.order.end_timestamp");
    const butler_full_name = _.get(
      this.state,
      "order.0.order.butler.user.full_name"
    );
    const clientName = _.get(this.state, "order.0.order.client.user.full_name");
    console.log(this.state);
    return (
      <div className="border p-4 ">
        <h5 className="mx-2">Order Details</h5>
        <div className="my-2">
          <LabelGroup
            className="d-inline-block mr-4"
            label="Date"
            text={moment(start_timestamp).format("DD/MM/YYYY")}
          />
          <LabelGroup
            className="d-inline-block"
            label="Session"
            text={
              moment(end_timestamp).diff(start_timestamp, "hours", true) +
              " hours"
            }
          />
        </div>

        <div className="my-2">
          <LabelGroup
            className="d-inline-block mr-4"
            label="Time"
            text={
              moment(start_timestamp).format("HH:mm") +
              " - " +
              moment(end_timestamp).format("HH:mm")
            }
          />
          <LabelGroup
            className="d-inline-block"
            label="Butler"
            text={butler_full_name}
          />
        </div>
        <div>
          <LabelGroup label="Client" text={clientName} />
        </div>
      </div>
    );
  }
  outstandingFeeDetails() {
    const amount = _.get(this.state, "order.0.amount");
    const remark = _.get(this.state, "order.0.remark");
    const status = _.get(this.state, "order.0.status");
    const attachments = _.get(this.state, "order.0.attachments", []);

    return (
      <div className="m-2 p-3 ">
        <h5 className="mt-2">Outstanding Fee Details</h5>
        <div className="py-2">
          <h6>Status: {status}</h6>
        </div>
        <div>
          <LabelGroup label="Amount" text={amount} />
        </div>
        <div>
          <LabelGroup label="Remark" text={remark} />
        </div>
        <div className="container">
          {attachments.map(a => (
            <div className="row">
              <a href={_.get(a, "image_url")} target="_blank">
                <img
                  style={{ width: "100%" }}
                  className="w-25"
                  src={_.get(a, "image_url")}
                  alt=""
                />
              </a>
            </div>
          ))}
        </div>
      </div>
    );
  }

  render() {
    const fail = getLoadingOrFail(this.state);
    if (fail) {
      return fail;
    }

    return (
      <div>
        <h1 className="mx-2">Outstanding fee details by butlers</h1>

        <div className="d-flex">
          <div className="border">
            {this.outstandingFeeDetails()}
            {this.renderOrderDetails()}
          </div>
          <div className="border">
            <div className="p-3  flex-fill">
              <a
                className="mx-5 btn btn-success"
                href="#"
                onClick={this.handleSubmit}
              >
                Approve
              </a>
            </div>
            {_.get(this.state, "order0.status")}
            <div className="p-3 flex-fill">
              {_.get(this.state, "order.0.status") == "APPROVED" ? null : (
                <a
                  className="mx-5 btn btn-danger"
                  href="#"
                  onClick={this.handleReject}
                >
                  {" "}
                  Reject{" "}
                </a>
              )}
            </div>
          </div>
        </div>
        {/*<pre>{JSON.stringify(this.state.order, null, '\t')}</pre>*/}
      </div>
    );
  }
}

export default OrderOutstandingFeeDetails;
