/**
 * Created by erikccoder on 26/5/2018.
 */

import React from "react";
import _ from "lodash";

import API from "../../api";
import { getLoadingOrFail } from "../../helper";

class SchedulerOrderAdminRemark extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: undefined,
      numUploaded: 0,
      loading: false,
      description: undefined,
      remarks: [] //already uploaded images,
    };
  }

  renderTextField = () => {
    return (
      <div>
        <input
          type="text"
          name="description"
          style={{ height: 100, width: 300, backgroundColor: "white" }}
          onChange={e => {
            this.setState({ description: e.target.value });
          }}
          placeholder="Enter the remark"
        />
        <button
          onClick={e => {
            this.uploadRemark(e);
          }}
        >
          {" "}
          Submit{" "}
        </button>
      </div>
    );
  };

  uploadRemark = e => {
    const order_id = _.get(this.props, "match.params.order_id", null);
    this.setState({ loading: true });
    console.log(this.state.description);
    var formData = new FormData();
    formData.append("type", "REMARKS");
    formData.append("remarks", this.state.description);
    formData.append("orderId", order_id);
    API.post(`/order/remarks`, formData).then(response => {
      this.setState({ loading: false });
      if (response.status == 200) {
        this.setState({ loading: false });
        window.location = `/calendar/${order_id}/add-remark`;
      }
    });
  };

  renderRemarks = () => {
    return (
      <div className="mx-3">
        <h6>Remarks</h6>
        {this.state.remarks.map(a => (
          <div>
            <div>{_.get(a, "remarks", "No remark")}</div>
          </div>
        ))}
      </div>
    );
  };
  componentDidMount() {
    this.setState({ loading: true });
    const order_id = _.get(this.props, "match.params.order_id", null);
    API.get(`/order/remarks/${order_id}/`).then(r => {
      if (r.status == 200)
        this.setState({ remarks: r.data.success, loading: false });
      else this.setState({ loading: false });
    });
  }
  render() {
    if (this.state.loading) return <div>Loading... </div>;
    return (
      <div className="m-3">
        {this.renderRemarks()}
        {this.renderTextField()}
      </div>
    );
  }
}

export default SchedulerOrderAdminRemark;
