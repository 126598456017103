/**
 * Created by erikccoder on 21/5/2018.
 */
/**
 * Created by erikccoder on 21/5/2018.
 */
import html2pdf from "html2pdf.js";
import React from "react";
import _ from "lodash";
import API from "../../api";
import { getLoadingOrFail, parseSearch } from "../../helper";

import moment from "moment";

import {
  Button,
  Image,
  Header,
  Segment,
  Grid,
  Label,
  Table
} from "semantic-ui-react";

import LabelGroupGrey from "../../components/LabelGroupGrey";
import LabelGroup from "../../components/LabelGroup";
import MoreSimpleTable from "../../components/MoreSimpleTable";

const headers = [
  {
    text: "Order date",
    key: "date"
  },
  {
    text: "Day",
    key: "day"
  },
  {
    text: "Time",
    key: "time"
  },
  {
    text: "Hours",
    key: "hours"
  },
  {
    text: "Client",
    key: "client.full_name"
  },
  {
    text: "Cleaning",
    key: "cleaning"
  },
  {
    text: "Cooking",
    key: "cooking"
  },
  {
    text: "Laundry",
    key: "laundry"
  },
  {
    text: "Grocery",
    key: "gorcery"
  },
  {
    text: "Pet sitting",
    key: "pet_sitting"
  },
  {
    text: "Other",
    key: "N/A"
  },
  {
    text: "Total amount",
    key: "total_amount"
  }
];

class InvoiceDetails extends React.Component {
  state = {
    invoice_details: null,
    firstLoad: false,
    error: null
  };

  componentWillMount() {
    const { client_id } = this.props.match.params;
    const search = parseSearch(this.props.location.search);
    const { year, month } = search;

    API.get(`/invoice/${client_id}/${year}/${month}`)
      .then(r => r.data)
      .then(invoice_details =>
        this.setState({ invoice_details, firstLoad: true })
      )
      .catch(error => this.setState({ error, firstLoad: true }));
  }

  getMembershipText() {
    const invoice_details = _.get(this.state, "invoice_details");
    const membership = _.get(invoice_details, "client.membership");
    const type = _.get(membership, "type");
    let start_at = _.get(membership, "start_at");
    let end_at = _.get(membership, "end_at");

    if (start_at) {
      start_at = moment(start_at).format("DD/MM/YYYY");
    }
    if (end_at) {
      end_at = moment(end_at).format("DD/MM/YYYY");
    }
    let typeFormatted;
    if (type == "NON_MEMBERSHIP") typeFormatted = "Non Member";
    else typeFormatted = "Member";
    return typeFormatted + " " + (start_at ? start_at + " to " + end_at : "");
  }
  formatMembership = membership => {
    switch (membership.type) {
      case "CLUB":
        return "( Club )";
      case "SILVER":
        return "( Silver )";
      case "GLOD":
        return "( Gold )";
      case "NON_MEMBERSHIP":
        return "";
      default:
        return "";
    }
  };
  renderHader() {
    const invoice_details = _.get(this.state, "invoice_details");

    return (
      <div>
        <Image floated="right" width="202" src="/images/butlerlogo-black.png" />

        <Header as="h2">Invoice</Header>

        <Segment secondary size="small" style={{ maxWidth: 385 }}>
          <p>Here is your invoice. Thank you for using Butler.</p>
        </Segment>

        <Grid columns={4}>
          <Grid.Column
            as={LabelGroupGrey}
            label="Invoice #"
            text={_.get(invoice_details, "invoice_no")}
          />
          <Grid.Column
            as={LabelGroupGrey}
            label="Issue date"
            text={_.get(invoice_details, "issue_date")}
          />
        </Grid>
      </div>
    );
  }

  renderClient() {
    const invoice_details = _.get(this.state, "invoice_details");

    return (
      <div>
        <div className="my-3" style={{ maxWidth: 336 }}>
          <LabelGroupGrey
            label="Client name"
            text={_.get(invoice_details, "client.full_name")}
          />
        </div>

        <Grid columns={3}>
          <Grid.Column
            as={LabelGroup}
            label="Membership"
            text={this.getMembershipText()}
          />

          <Grid.Column
            as={LabelGroup}
            label="Email"
            text={_.get(invoice_details, "client.email")}
          />

          <Grid.Column
            as={LabelGroup}
            label="Contact no."
            text={_.get(invoice_details, "client.contact_no")}
          />
        </Grid>

        <div className="my-3">
          <LabelGroup
            label="Address"
            text={_.get(invoice_details, "client.address")}
          />
        </div>
      </div>
    );
  }

  renderOrderTable() {
    const headers = [
      {
        text: "Order date",
        key: "date"
      },
      {
        text: "Day",
        key: "day"
      },
      {
        text: "Time",
        key: "time"
      },
      {
        text: "Hours",
        key: "hours"
      },
      {
        text: "Butler",
        key: "butler"
      },
      {
        text: "Cleaning",
        key: "cleaning"
      },
      {
        text: "Cooking",
        key: "cooking"
      },
      {
        text: "Laundry",
        key: "laundry"
      },
      {
        text: "Grocery",
        key: "gorcery"
      },
      {
        text: "Other",
        key: "N/A"
      },
      {
        text: "Service Cost",
        key: "original_cost"
      },
      {
        text: "Membership coverage",
        key: "cost"
      },
      {
        text: "Other Cost",
        key: "other_cost"
      }
    ];

    let orders = _.get(this.state, "invoice_details.orders", []);
    orders = orders.map(o => ({
      ...o,
      date: moment(o.start_timestamp).format("D/M/YYYY"),
      day: moment(o.start_timestamp).format("dddd"),
      time: moment(o.start_timestamp).format("HH:mm"),
      hours: moment(o.end_timestamp).diff(o.start_timestamp, "hours"),

      cleaning: o.tasks.indexOf("CLEANING") > -1,
      cooking: o.tasks.indexOf("COOKING") > -1,
      laundry: o.tasks.indexOf("LAUNDRY") > -1,
      gorcery: o.tasks.indexOf("GROCERY") > -1,
      pet_sitting: o.tasks.indexOf("PET_SITTING") > -1,
      other: o.tasks.indexOf("OTHER") > -1,

      cost: o.is_complimentary_order ? "Covered" : ""
    }));

    //html2pdf__page-break use this to add page breaks to pdf
    return (
      <div className="mt-3 mb-5 html2pdf__page-break">
        <h6 className="text-danger">Order Details</h6>
        <MoreSimpleTable theader={headers} tbody={orders} />
      </div>
    );
  }

  renderPayments() {
    const invoice_details = _.get(this.state, "invoice_details");
    const payments = _.get(invoice_details, "payments", []);
    const headers = [
      {
        text: "Date",
        key: "date"
      },
      {
        text: "Method",
        key: "paid_by"
      },
      {
        text: "Type",
        key: "type"
      },
      {
        text: "Amount",
        key: "amount"
      },
      {
        text: "Remark",
        key: "remark"
      }
    ];
    return (
      <div className="mt-3 mb-5 html2pdf__page-break">
        <br />
        <h6 className="text-danger">Payments Records</h6>
        <MoreSimpleTable
          theader={headers}
          tbody={payments.map(p => ({
            ...p,
            date: moment(p.created_at).format("DD/MM/YYYY")
          }))}
        />
      </div>
    );
  }
  printToPDF = () => {
    var filename = _.get(this.state, "invoice_details.invoice_no");
    var options = {
      margin: [20, 0],
      filename: `${filename}.pdf`,
      image: { type: "png", quality: 1 },
      html2canvas: { scale: 1 },
      jsPDF: { unit: "pt", format: "Letter", orientation: "landscape" }
    };
    var element = document.getElementById("element-to-print");
    html2pdf()
      .from(element)
      .set(options)
      .save();
  };

  render() {
    const fail = getLoadingOrFail(this.state);
    if (fail) {
      return fail;
    }

    const invoice_details = _.get(this.state, "invoice_details");

    return (
      <div>
        {/*<h1>Invoice Details</h1>*/}
        <Button className="m-3 p-3" onClick={this.printToPDF}>
          Print
        </Button>
        <div id="element-to-print" className="m-3 p-3 bg-white border">
          {this.renderHader()}
          {this.renderClient()}
          <div className="html2pdf__page-break">{this.renderPayments()}</div>
          {this.renderOrderTable()}

          <div className="d-flex justify-content-end">
            <Button size="small pl-0 bg-none color-red">Original total</Button>
            <Segment
              secondary
              className="bg-white py-1 my-2"
              style={{ minWidth: 120 }}
            >
              $ {(_.get(invoice_details, "original_total", 0) || 0).toFixed(2)}
            </Segment>
          </div>
          <div className="d-flex justify-content-end">
            <Button size="small pl-0 bg-none color-red">
              {this.formatMembership(
                _.get(invoice_details, "client.membership")
              )}
            </Button>
            <Segment
              secondary
              className="bg-white py-1 my-2"
              style={{ minWidth: 120 }}
            >
              ${" "}
              {(_.get(invoice_details, "client.membership.cost") || 0).toFixed(
                2
              )}
            </Segment>
          </div>

          <div className="d-flex justify-content-end">
            <Button size="small pl-0 bg-none color-red">Total</Button>
            <Segment
              secondary
              className="bg-white py-1 my-2"
              style={{ minWidth: 120 }}
            >
              $ {(_.get(invoice_details, "total") || 0).toFixed(2)}
            </Segment>
          </div>
        </div>

        {/*<pre>{JSON.stringify(invoice_details, null, '\t')}</pre>*/}
      </div>
    );
  }
}

export default InvoiceDetails;
