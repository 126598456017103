import React from "react";
import { Segment, Label } from "semantic-ui-react";

const LabelGroup = props => (
  <div className={props.className}>
    <Label className="bg-none pl-0">{props.label}</Label>
    <div>
      {!props.titleOnly && (
        <Segment
          secondary
          size="large"
          className="bg-none border-0 p-2 shadow-none"
        >
          {props.src && <img alt="string" src={props.src} className="mr-2" />}
          {props.text}
        </Segment>
      )}
    </div>
  </div>
);

export default LabelGroup;
