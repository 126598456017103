/**
 * Created by erikccoder on 29/5/2018.
 */

import React from 'react'
import _ from 'lodash';
import API from '../../api';
import { getLoadingOrFail } from '../../helper';
import Select from 'react-select'
import 'react-select/dist/react-select.css';


class ButlerReferral extends React.Component {
	state = {
		firstLoad: false,
		error: null,
	};
	componentWillMount() {

		const { butler_id } = this.props.match.params;

		Promise.all([
			API.get(`/butlers`).then(r => r.data),
			API.get(`/butlers/${butler_id}`).then(r => r.data)
		]).then( ([butler_list, cur_butler]) => this.setState({ firstLoad: true, cur_butler,  butler_list}))
		.catch(error => this.setState({ firstLoad: true, error: error }));
	}

	handleRemove = (id, e)=>
	{
		e.preventDefault();

		const { butler_id } = this.props.match.params;

		API.put(
			`/butlers/${butler_id}`,
			{
				referred_butlers: [{
					id,
					status: "REMOVED"
				}]
			}
		)
		.then(r => window.location.reload())
		.catch(error => this.setState({ error: error }));
	}

	handleUpdate = (e)=>{
		e.preventDefault();

		const {
			referred_butler,
			referral_rate,
			cur_butler
		} = this.state;

		if(!referred_butler || !referral_rate){
			return alert("Please select referred butler and input valid referral rate");
		}

		const refrred = _.find(cur_butler.referred_butlers, {referred_butler});

		const { butler_id } = this.props.match.params;

		API.put(
			`/butlers/${butler_id}`,
			{
				referred_butlers: [{
					id: refrred.id,
					referred_butler,
					referral_rate,
				}]
			}
		)
		.then(r => window.location.reload())
		.catch(error => this.setState({ error: error }));

	}
	renderUpdateForm()
	{
		const options = this.state.butler_list.map(
			b => ({
				label: _.get(b, 'full_name'),
				value: _.get(b, 'id'),
			}))
			.filter( b => (
				b.value != this.state.cur_butler.id)
				&& _.find(this.state.cur_butler.referred_butlers, {referred_butler: b.value})
			);

		return (
			<div>
				<Select
					options={options}
					simpleValue
					clearable
					value={this.state.referred_butler}
					className="w-100"
					onChange={(referred_butler) => this.setState({referred_butler})}
				/>

				<div className="form-group my-4">
					<label htmlFor="rate">Referral Rate</label>
					<input
						type="number"
						className="form-control" id="rate" placeholder="referral rate"
						onChange={ ({target: {value}}) => this.setState({referral_rate: value})}
					/>
				</div>

				<a
					onClick={this.handleUpdate}
					className="btn btn-primary btn-block" href="#"
				>
					Update
				</a>
			</div>
		)
	}

	handleSubmit = (e)=>{
		e.preventDefault();

		const {
			referred_butler,
			referral_rate,
		} = this.state;

		if(!referred_butler || !referral_rate){
			return alert("Please select referred butler and input valid referral rate");
		}

		const { butler_id } = this.props.match.params;

		API.put(
			`/butlers/${butler_id}`,
			{
				referred_butlers: [{
					referred_butler,
					referral_rate,
				}]
			}
		)
		.then(r => window.location.reload())
		.catch(error => this.setState({ error: error }));

	}

	renderSubmitForm(){

		const options = this.state.butler_list.map(
			b => ({
				label: _.get(b, 'full_name'),
				value: _.get(b, 'id'),
			}))
			.filter( b => (
				b.value != this.state.cur_butler.id)
				&& !_.find(this.state.cur_butler.referred_butlers, {referred_butler: b.value})
			);



		return (
			<div>
				<Select
					options={options}
					simpleValue
					clearable
					value={this.state.referred_butler}
					className="w-100"
					onChange={(referred_butler) => this.setState({referred_butler})}
				/>

				<div className="form-group my-4">
					<label htmlFor="rate">Referral Rate</label>
					<input
						type="number"
						className="form-control" id="rate" placeholder="referral rate"
						onChange={ ({target: {value}}) => this.setState({referral_rate: value})}
					/>
				</div>

				<a
					className="btn btn-primary btn-block" href="#"
				    onClick={this.handleSubmit}
				>
					Submit
				</a>
			</div>
		)
	}

	renderOptions(){
		return(

			<div className="mb-5">
				<div className="form-check form-check-inline">
					<input
						className="form-check-input"
						type="radio"
						name="inMemberType"
						id="inOrderTypeRadioYes"
						onChange={ ()=> this.setState({do_update: true, referred_butler: null, referral_rate: null})}
					/>
					<label className="form-check-label mr-5" htmlFor="inOrderTypeRadioYes">
						Update Referral Rate
					</label>
				</div>
				<div className="form-check form-check-inline">
					<input
						className="form-check-input"
						type="radio"
						name="inMemberType"
						id="inOrderTypeRadioNo"
						defaultChecked
						defaultValue="checked"
						onChange={ ()=> this.setState({do_update: false, referred_butler: null, referral_rate: null})}
					/>
					<label className="form-check-label" htmlFor="inOrderTypeRadioNo">
						Add Referred Butler
					</label>
				</div>
			</div>
		)
	}

	render(){
		const fail = getLoadingOrFail(this.state);

		if (fail) {
			return fail;
		}

		const referred_butlers = _.get(this.state, 'cur_butler.referred_butlers', [])

		return(
			<div className="m-3">
				<h5>Referred Butler</h5>

				<div className="row">
					<div className="col-6">

					{
						referred_butlers.map( r => (
							<div className="border p-3 mr-5 d-flex align-items-center">
								<div className="col">
									<h6>{r.referred_butler_name}</h6>
									<p>$ {r.referral_rate}</p>
								</div>
								<a onClick={ e => this.handleRemove(r.id, e)} className="btn btn-danger" href="#">Remove</a>
							</div>
						) )
					}

					</div>
					<div className="col-6">


						{this.renderOptions()}
						{
							this.state.do_update
							? this.renderUpdateForm()
							: this.renderSubmitForm()
						}
					</div>
				</div>

				{/*<pre>{JSON.stringify(this.state.cur_butler, null, '\t')}</pre>*/}
			</div>
		)
	}
}

export default ButlerReferral;